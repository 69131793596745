import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import { Form, LoadingBar } from '../common';
import { getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const cleanObject = (obj) => {
    for (const key in obj) {
        if (!obj[key]) {
            delete obj[key];
        }
    }
    return obj;
};

const Search = ({ onSearch }) => {
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries`);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [orderNumber, setOrderNumber] = useState('');
    const [lottery, setLottery] = useState('');

    const renderTextField = (label, name, value, changeFunc, type = 'text') => {
        return (
            <div>
                <TextField
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                />
            </div>
        );
    };

    const renderSelectField = (label, name, value, changeFunc, options, type = 'text') => {
        return (
            <div>
                <TextField
                    select
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    };

    const renderDateField = (label, name, value, changeFunc, options, type = 'text') => {
        return (
            <div>
                <DatePicker
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    autoOk
                    clearable
                    clearLabel='Tyhjennä'
                    cancelLabel='Peruuta'
                    format='DD.MM.YYYY'
                    onChange={(value) => changeFunc(value._d.toISOString())}
                />
            </div>
        );
    };

    return (
        <>
            <LoadingBar in={loading} />
            <Container maxWidth='sm'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Hae tilauksia
                    </Typography>
                </Toolbar>
                <Form action={(body) => onSearch(cleanObject({
                    firstname,
                    lastname,
                    lottery_id: lottery,
                    order_id: orderNumber,
                    created_at_min: firstDate,
                    created_at_max: lastDate,
                }))}>
                    <Typography variant='body1'>
                        Jätä kentät, joiden perusteella et halua etsiä, tyhjiksi.
                    </Typography>
                    {renderTextField('Etunimi', 'firstname', firstname, setFirstname)}
                    {renderTextField('Sukunimi', 'lastname', lastname, setLastname)}
                    {renderSelectField('Arpa', 'lottery_id', lottery, setLottery, data ? data.map((item) => {
                        return { label: getLangValue(item.names, 'fi'), value: item.id };
                    }) : [])}
                    {renderTextField('Tilausnumero', 'order_id', orderNumber, setOrderNumber)}
                    {renderDateField('Ensimmäinen luontipäivä', 'created_at_min', firstDate, setFirstDate)}
                    {renderDateField('Viimeinen luontipäivä', 'created_at_max', lastDate, setLastDate)}
                    <Button variant='contained' color='primary' style={{ marginTop: '1em' }} fullWidth type='submit'>Etsi</Button>
                </Form>
            </Container>
        </>
    );
};

export default Search;
