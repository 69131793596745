import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ClaimableIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlayableIcon from '@material-ui/icons/VideogameAsset';

import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const SeriesUploadDialog = ({ open, lottery, onClose, onSave }) => {
    const [format, setFormat] = useState(1);
    const [tickets, setTickets] = useState(null);
    useEffect(() => {
        if (open) {
            setFormat(1);
            setTickets(null);
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Lisää arpasarja</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Formaattivalinta määrittelee kumpaa muotoa tämä arpasarja on:
                    lunastettava <ClaimableIcon/> vai pelattava <PlayableIcon/>.
                    Tätä ei voi muuttaa enää jälkeenpäin.
                </DialogContentText>
                <Form action={() => {
                    const body = new FormData();
                    body.append('tickets', tickets);

                    /* Format must match the value in
                     * server/arpamaailma/const.py:TicketFormat */

                    body.append('codes_type', format);
                    fetch(`${config.backendAddress}/api/v1/lotteries/${lottery}/tickets`, {
                        method: 'POST',
                        body: body
                    })
                        .then((response) => {
                            showMessage('Arpasarja lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='uploadSeries'>
                    <TextField
                        autoFocus
                        select
                        fullWidth
                        required
                        label='Formaatti'
                        value={format}
                        onChange={(event) => setFormat(event.target.value)}
                    >
                        <MenuItem value={1}>
                            <ListItemIcon>
                                <ClaimableIcon/>
                            </ListItemIcon>
                            Arpaformaatti 1
                        </MenuItem>
                        <MenuItem value={2}>
                            <ListItemIcon>
                                <ClaimableIcon/>
                            </ListItemIcon>
                            Unelma-Arpa-formaatti
                        </MenuItem>
                        <Divider/>
                        <MenuItem value={3}>
                            <ListItemIcon>
                                <PlayableIcon/>
                            </ListItemIcon>
                            Pelattava arpa 1
                        </MenuItem>
                        <MenuItem value={4}>
                            <ListItemIcon>
                                <PlayableIcon/>
                            </ListItemIcon>
                            Pelattava arpa 14
                        </MenuItem>
                        <MenuItem value={5}>
                            <ListItemIcon>
                                <PlayableIcon/>
                            </ListItemIcon>
                            Vapaa formaatti
                        </MenuItem>
                    </TextField>
                    <Toolbar disableGutters>
                        <TextField
                            fullWidth
                            required
                            label='Tiedosto'
                            value={tickets ? tickets.name : ''}
                            style={{ marginRight: '1em' }}
                            InputProps={{
                                readOnly: true
                            }}
                        >
                            <MenuItem value={1}>Arpaformaatti 1</MenuItem>
                            <MenuItem value={2}>Unelma-Arpa-formaatti</MenuItem>
                            <MenuItem value={3}>Pelattava arpa 1</MenuItem>
                            <MenuItem value={4}>Pelattava arpa 14</MenuItem>
                            <MenuItem value={5}>Vapaa formaatti</MenuItem>
                        </TextField>
                        <input
                            type='file'
                            accept='.csv'
                            style={{ display: 'none' }}
                            id='series-upload'
                            onChange={(event) => {
                                if (event.target.files.length > 0) {
                                    setTickets(event.target.files[0]);
                                }
                            }}
                        />
                        <label htmlFor='series-upload'>
                            <Button variant='contained' color='primary' component='span'>
                                Valitse
                            </Button>
                        </label>
                    </Toolbar>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='uploadSeries'>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SeriesUploadDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default SeriesUploadDialog;
