import React from 'react';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { LoadingBar } from '../common';
import { useFetch } from '../common/hooks';
import ExtraLottery from './ExtraLottery';
import config from '../config';

const ExtraLotteries = ({ location }) => {
    const values = queryString.parse(location.search);
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/ticket-series`);
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Lisäarvonnat
                    </Typography>
                </Toolbar>
                {data && data.map((series) => (
                    <ExtraLottery key={series.id} series={series} />
                ))}
            </Container>
        </>
    );
};

export default ExtraLotteries;
