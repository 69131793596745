import React, { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { NavLink, Route, Switch } from 'react-router-dom';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ViewListIcon from '@material-ui/icons/ViewList';
import CodeIcon from '@material-ui/icons/Code';
import SettingsIcon from '@material-ui/icons/Settings';
import GlobalReports from './GlobalReports';
import ImportGameCurrency from './ImportGameCurrency';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ImportPrizeCodes from './ImportPrizeCodes';
import BonusSettings from './BonusSettings';
import LotteryWithdraw from './LotteryWithdraw';
import Misc from './Misc';

const toolRoutes = [
    { name: 'Lisää pelibonusta', path: '/pelibonus/', icon: <ImportExportIcon />, component: ImportGameCurrency },
    { name: 'Raportit', path: '/raportit/', icon: <ViewListIcon />, component: GlobalReports },
    { name: 'Tuo sähköisten lahjakorttien koodeja', path: '/lisää-koodeja/', icon: <CodeIcon />, component: ImportPrizeCodes },
    {
        name: 'Poista arvan saldoa',
        path: '/poista-arvan-saldoa/',
        component: LotteryWithdraw
    },
    { name: 'Bonusasetukset', path: '/bonus-asetukset/', icon: <SettingsIcon />, component: BonusSettings },
    { name: 'Sekalaista', path: '/misc/', component: Misc },
];


const ToolDrawerContent = () => (
    <>
        <Toolbar />
        <List>
            {toolRoutes.map((route) => (
                <div key={route.path}>
                    <Divider />
                    <ListItem button component={React.forwardRef((itemProps, ref) => <NavLink to={`/tyokalut${route.path}`} {...itemProps} innerRef={ref} />)} selected={false}>
                        <ListItemIcon>
                            {route.icon}
                        </ListItemIcon>
                        <ListItemText primary={route.name} />
                    </ListItem>
                </div>
            ))}
        </List>
    </>
);


const Tools = ({ classes }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    return(
        <>
            {/* Desktop */}
            <Hidden mdDown>
                <Drawer
                    open
                    classes={{ paper: classes.drawer }}
                    variant='permanent'
                >
                    <ToolDrawerContent />
                </Drawer>
            </Hidden>
            {/* Mobile */}
            <Hidden lgUp>
                <Drawer
                    open={drawerOpen}
                    classes={{ paper: classes.drawer }}
                    variant='temporary'
                    onClose={() => setDrawerOpen(false)}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    <ToolDrawerContent />
                </Drawer>
            </Hidden>
            <main className={classes.main}>
                <Switch>
                    {toolRoutes.map((route) => <Route key={route.path} path={`/tyokalut${route.path}`} component={route.component} />)}
                </Switch>
            </main>
        </>
    );
};

export default Tools;
