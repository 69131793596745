import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Table, LoadingBar } from '../common';
import User from './User';

const Results = ({ data, loading, onReturn, refresh }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [viewUser, setViewUser] = useState(false);
    useEffect(() => {
        if (data && data.items && data.items.length === 1 && !selectedUser) {
            setSelectedUser(data.items[0]);
            setViewUser(true);
        }
    }, [data, selectedUser]);

    if (viewUser) {
        return <User user={selectedUser} onReturn={() => {
            setViewUser(false);
            refresh();
        }} />;
    };
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Käyttäjät
                    </Typography>
                    {data && data.total > 100 && (
                        <Typography
                            variant='body1'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                            Tuloksia löytyi yli 100. Kokeile tarkempia hakuehtoja.
                        </Typography>
                    )}
                    <Button variant='contained' color='primary' onClick={onReturn}>Haku</Button>
                </Toolbar>
                <Table
                    idKey={true}
                    data={data && data.items ? data.items : []}
                    columns={[
                        { label: 'Etunimi', key: 'firstname' },
                        { label: 'Sukunimi', key: 'lastname' },
                        { label: 'Sähköposti', key: 'email' },
                        { label: 'Puhelinnumero', key: 'phone' },
                        { label: 'Luotu', key: 'created_at', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' }
                    ]}
                    rowActions={(item) => {
                        return (
                            <>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ marginRight: '1em' }}
                                    onClick={() => {
                                        setSelectedUser(item);
                                        setViewUser(true);
                                    }}
                                >
                                    Tarkastele
                                </Button>
                            </>
                        );
                    }}
                />
            </Container>
        </>
    );
};

export default Results;
