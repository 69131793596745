import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingBar } from '../common';
import { fetchWrap as fetch, formatCurrency, getLangValue, openBlob, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';
import Grid from '@material-ui/core/Grid';

let interval;

const GlobalReport = ({ startTime, endTime, onReturn }) => {
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/reports/accounts?start_time=${moment(startTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&end_time=${moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss')}`);
    const [{ data: prizeCodeData, loading: prizeCodeDataLoading }] = useFetch(`${config.backendAddress}/api/v1/reports/prize-codes?start_time=${moment(startTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&end_time=${moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss')}`);
    const [users, setUsers] = useState([]);
    const [prizeCodes, setPrizeCodes] = useState();

    const [{ data: latest, loading: tasksLoading, refresh }] = useFetch(`${config.backendAddress}/api/v1/reports/accounts/export/tasks`);

    const handleCSV = (name, url) => {
        fetch(url, { method:'POST' })
            .then((response) => response.json())
            .then((json) => {
                if (json) {
                    //download(name, url);
                }
            });
    };

    useEffect(() => {
        if (data && data.bonus_deposits) {
            setUsers(data.bonus_deposits);
        }
    }, [data]);

    useEffect(() => {
        if (prizeCodeData){
            setPrizeCodes(prizeCodeData);
        }
    },[prizeCodeData]);

    useEffect(() => {
        interval = setInterval(() => refresh(), 10000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadExport = () => {
        fetch(`${config.backendAddress}/api/v1/reports/accounts/export`)
            .then((response) => {
                return response.blob()
                    .then((blob) => {
                        return {
                            blob: blob,
                            content_disposition: response.headers.get('content-disposition')
                        };
                    });
            })
            .then(({ blob, content_disposition }) => {
                const filename = content_disposition.split(';')[1].split('=')[1];

                const newBlob = new Blob([blob], { type: 'text/csv' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                const data = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = filename;
                link.click();
                setTimeout(function(){
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getLatest = () => {
        if (!latest || latest.length == 0) return '';
        return `Lataa viimeisin: ${moment.utc(latest['created_timestamp']).local().format('DD.MM.YYYY HH:mm')}`;
    };

    return (
        <>
            <LoadingBar in={loading}/>
            <Container>
                {data && (
                    <>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h4'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Raportti tilien sisällöistä ajalta ({moment(startTime).format('DD.MM.YYYY')} - {moment(endTime).format('DD.MM.YYYY')})
                            </Typography>
                            <Button variant='contained' color='primary' onClick={onReturn}>
                                Takaisin
                            </Button>
                        </Toolbar>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Bonus talletukset per antaja
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='right'>ID</TableCell>
                                        <TableCell align='right'>Nimi</TableCell>
                                        <TableCell align='right'>Sähköposti</TableCell>
                                        <TableCell align='right'>Bonus talletukset</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user.user_id}>
                                            <TableCell align='right'>{user.user_id}</TableCell>
                                            <TableCell align='right'>{user.name}</TableCell>
                                            <TableCell align='right'>{user.email}</TableCell>
                                            <TableCell align='right'>{formatCurrency(user.total)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h6'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                Paytrail tapahtumat
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        style={{ margin: '0 1em' }}
                                        onClick={() => handleCSV(
                                                `Paytrail-events (${moment(startTime).format('DD.MM.YYYY')} - ${moment(endTime).format('DD.MM.YYYY')}).csv`,
                                                `${config.backendAddress}/api/v1/reports/accounts/export?start_time=${moment(startTime).hour(0).minute(0).seconds(0).toISOString()}&end_time=${moment(endTime).hour(23).minute(59).seconds(59).toISOString()}`
                                        )}
                                    >
                                        Lataa CSV
                                    </Button>
                                    <Link
                                        variant='body2'
                                        href='#'
                                        onClick={downloadExport}
                                    >
                                        {getLatest()}
                                    </Link>

                                </Toolbar>
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='right'>Paytrail talletusten määrä</TableCell>
                                                <TableCell align='right'>Paytrail talletusten yhteissumma</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='right'>{data.paytrail_deposits.number}</TableCell>
                                                <TableCell align='right'>{formatCurrency(data.paytrail_deposits.amount)} €</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h6'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                Rahaa asiakkaiden tileillä
                                    </Typography>
                                </Toolbar>
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='right'>Rahaa kaikkien asiakkaiden tileillä yhteensä</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='right'>{formatCurrency(data.user_accounts)} €</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                )}
                <>
                    <Toolbar disableGutters>
                        <Typography
                            variant='h4'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                                Raportti voittokoodien käytöstä ({moment(startTime).format('DD.MM.YYYY')} - {moment(endTime).format('DD.MM.YYYY')})
                        </Typography>
                    </Toolbar>
                    <Paper style={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='right'>Koodien yhteismäärä</TableCell>
                                    <TableCell align='right'>Käytettyjen koodien määrä</TableCell>
                                    <TableCell align='right'>Koodeja jäljellä</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key='Voittokoodit'>
                                    <TableCell align='right'>{prizeCodes?.total_codes || 0}</TableCell>
                                    <TableCell align='right'>{prizeCodes?.used_codes || 0}</TableCell>
                                    <TableCell align='right'>{prizeCodes?.remaining_codes || 0}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </>
            </Container>
        </>
    );
};

export default GlobalReport;
