import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Form, LoadingBar } from '../common';
import { getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const cleanObject = (obj) => {
    for (const key in obj) {
        if (!obj[key]) {
            delete obj[key];
        }
    }
    return obj;
};

const CREATED_DATE = 0;
const CLAIMED_DATE = 1;
const PLAYED_DATE = 2;
const PURCHASED_DATE = 3;

const paramPrefixByDateType = {
    0: 'created',
    1: 'claimed',
    2: 'played',
    3: 'purchased',
};

const Search = ({ onSearch, location: { search } }) => {
    const values = queryString.parse(search);
    const [source, setSource] = useState('all');
    const [ticketId, setTicketId] = useState('');
    const [prizeId, setPrizeId] = useState('');
    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [prizes, setPrizes] = useState([]);
    const [anyCode, setAnyCode] = useState('');
    const [dateType, setDateType] = useState(CLAIMED_DATE);
    const [{ data: ticketSeriesesData }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/ticket-series`);
    const [{ data: prizeIdData }, setPrizeUrl] = useFetch(null);
    const [{ data: prizeProductData }, setPrizeProductUrl] = useFetch(null);

    useEffect(() => {
        if (ticketId) {
            setPrizeUrl(`${config.backendAddress}/api/v1/ticket-series/${ticketId}/prizes`);
            setPrizeProductUrl(`${config.backendAddress}/api/v1/ticket-series/${ticketId}/products`);
        }
    }, [setPrizeProductUrl, setPrizeUrl, ticketId]);

    useEffect(() => {
        if (prizeIdData && prizeProductData) {
            setPrizes(prizeIdData.map((item) => {
                const product = prizeProductData.find((productItem) => productItem.prize_id === item);
                if (product) {
                    return { label: `${item}. ${getLangValue(product.names, 'fi')}`, value: item };
                } else {
                    return { label: `${item}.`, value: item };
                }
            }));
        }
    }, [prizeIdData, prizeProductData]);

    const renderSelectField = (label, name, value, changeFunc, options, type = 'text', required = false, disabled = false) => {
        return (
            <div>
                <TextField
                    select
                    fullWidth
                    disabled={disabled}
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    required={required}
                    onChange={(event) => changeFunc(event.target.value)}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    };

    const renderDateField = (label, name, value, changeFunc, options, type = 'text') => {
        return (
            <div>
                <DatePicker
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    autoOk
                    clearable
                    clearLabel='Tyhjennä'
                    cancelLabel='Peruuta'
                    format='DD.MM.YYYY'
                    onChange={(moment) => changeFunc(moment)}
                />
            </div>
        );
    };

    const renderTextField = (label, name, value, changeFunc, type = 'text') => {
        return (
            <div>
                <TextField
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                />
            </div>
        );
    };

    const [variety, setVariety] = useState(null);
    const [sourceDisabled, setSourceDisabled] = useState(false);

    useEffect(() => {
        if(ticketId) {
            const ticketSeries = ticketSeriesesData.find((ticketSeries) => ticketSeries.id == ticketId);
            setVariety(ticketSeries.playable ? 'playable': 'claimable');
            if (ticketSeries.playable) {
                setSource('all');
                setDateType(PLAYED_DATE);
            } else {
                setDateType(CLAIMED_DATE);
            }
            setSourceDisabled(ticketSeries.playable);
        }
    }, [ticketId, ticketSeriesesData]);

    return (
        <>
            <LoadingBar in={false} />
            <Container maxWidth='sm'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Hae arpoja
                    </Typography>
                </Toolbar>
                <Form action={(body) => {
                    if(!ticketId) {
                        return;
                    }

                    const startParam = `${paramPrefixByDateType[dateType]}_at_min`;
                    const endParam = `${paramPrefixByDateType[dateType]}_at_max`;

                    const bodyObject = {
                        source,
                        ticketId,
                        prize_id: prizeId,
                        checked: true,
                        any_code: anyCode,
                    };

                    bodyObject[startParam] = firstDate && moment(firstDate.toISOString().split('T')[0] + 'T00:00:00').utc().format('YYYY-MM-DDTHH:mm:ss');
                    bodyObject[endParam] = lastDate && moment(lastDate.toISOString().split('T')[0] + 'T23:59:59').utc().format('YYYY-MM-DDTHH:mm:ss');

                    onSearch(
                        cleanObject(bodyObject),
                        variety,
                    );
                }}>
                    <Typography variant='body1'>
                        Jätä kentät, joiden perusteella et halua etsiä, tyhjiksi. Jos arvan numero on hakuehtona, voittoluokka- ja päivävalinnat ohitetaan.
                    </Typography>
                    {renderSelectField('Lähde', 'source', source, setSource, [
                        { label: 'Kaikki', value: 'all' },
                        { label: 'Admin', value: 'admin' },
                        { label: 'Arpamaailma', value: 'user' },
                        { label: 'SecuryCast', value: 'secury_cast' }
                    ], 'text', true, sourceDisabled)}
                    {renderSelectField('Arpakausi', 'ticket_id', ticketId, setTicketId, ticketSeriesesData ? ticketSeriesesData.map((item) => {
                        return { label: item.alias, value: item.id };
                    }) : [], 'text', true)}
                    {renderSelectField('Voittoluokka', 'prize_id', prizeId, setPrizeId, prizes)}

                    <div style={{ marginTop: '50px' }}></div>

                    {renderSelectField(
                        'Päivätyyppi',
                        'dateType',
                        dateType,
                        setDateType,
                        [
                            { value: CREATED_DATE, label: 'luontipävä' },
                            { value: CLAIMED_DATE, label: 'lunastuspäivä' },
                            { value: PLAYED_DATE, label: 'pelattu päivä' },
                            { value: PURCHASED_DATE, label: 'ostopäivä' },
                        ].filter((item) => {
                            if(variety == 'playable' && [PLAYED_DATE, PURCHASED_DATE].indexOf(item.value) != -1) return true;
                            else if(variety == 'claimable' && [CREATED_DATE, CLAIMED_DATE].indexOf(item.value) != -1) return true;
                            return false;
                        }),
                        'text',
                        false,
                        variety ? false : true,
                    )}

                    {renderDateField('Ensimmäinen päivä', 'claimed_at_min', firstDate, setFirstDate)}
                    {renderDateField('Viimeinen päivä', 'claimed_at_max', lastDate, setLastDate)}

                    <div style={{ marginTop: '50px' }}></div>

                    {renderTextField('Arvan numero', 'any_code', anyCode, setAnyCode, 'text')}
                    <Button variant='contained' color='primary' style={{ marginTop: '1em' }} fullWidth type='submit'>Etsi</Button>
                </Form>
            </Container>
        </>
    );
};

export default withRouter(Search);
