import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { DatePicker } from '@material-ui/pickers';
import config from '../config';
import { Form, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch } from '../common/functions';

let interval;

const ExtraLottery = ({ series }) => {
    const [startDate, setStartDate] = useState(
        series.validity_start ? series.validity_start.split('T')[0] : null);
    const [endDate, setEndDate] = useState(
        series.validity_end ? series.validity_end.split('T')[0] : null);

    const [{ data: latest, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/extra-lottery/partakers/tasks`);

    useEffect(() => {
        interval = setInterval(() => refresh(), 10000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadExtraLotteryPartakers = () => {
        fetch(`${config.backendAddress}/api/v1/ticket-series/${series.id}/extra-lottery/partakers`)
            .then((response) => {
                return response.blob()
                    .then((blob) => {
                        return {
                            blob: blob,
                            content_disposition: response.headers.get('content-disposition')
                        };
                    });
            })
            .then(({ blob, content_disposition }) => {
                const filename = content_disposition ?
                    content_disposition.split(';')[1].split('=')[1]:
                      `${series.alias}_${moment().format()}.csv`;

                const newBlob = new Blob([blob], { type: 'text/csv' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                const data = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = filename;
                link.click();
                setTimeout(function(){
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getLatest = (ticketSeriesId) => {
        if (!latest || latest.length == 0) return '';
        const ticketSeriesLatest = latest.filter((item) => ticketSeriesId === item.ticket_series_id);
        if (ticketSeriesLatest.length === 0) return '';
        return `Lataa viimeisin: ${moment.utc(ticketSeriesLatest[0]['created_timestamp']).local().format('DD.MM.YYYY HH:mm')}`;
    };

    return (
        <div>
            <Toolbar disableGutters>
                <Typography
                    variant='h5'
                    noWrap
                    style={{ flexGrow: 1 }}
                >
                    {series.alias}
                </Typography>
            </Toolbar>
            <Form action={(body) => {
                fetch(`${config.backendAddress}/api/v1/ticket-series/${series.id}/extra-lottery/partakers?partaken_date_at_min=${moment(startDate).format('YYYY-MM-DD')}&partaken_date_at_max=${moment(endDate).format('YYYY-MM-DD')}`, {
                    method: 'POST'
                })
                    .then((response) => {
                        showMessage('Lisävoittajien vienti aloitettu');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }}>
                <DatePicker
                    required
                    margin='dense'
                    name='first_date'
                    label='Ensimmäinen päivä'
                    autoOk
                    format='DD.MM.YYYY'
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                />
                <DatePicker
                    required
                    margin='dense'
                    name='last_date'
                    label='Viimeinen päivä'
                    autoOk
                    format='DD.MM.YYYY'
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    style={{ marginRight: '1em', marginLeft: '1em' }}
                />
                <Button
                    variant='contained'
                    color='primary'
                    style={{ marginTop: '1em' }}
                    type='submit'
                >
                    Vie lisävoittajat
                </Button>
            </Form>
            <Link
                variant='body2'
                href='#'
                onClick={downloadExtraLotteryPartakers}
            >
                {getLatest(series.id)}
            </Link>
            <Divider style={{ margin: '1em 0' }} />
        </div>
    );
};

export default ExtraLottery;
