import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const AddGameCurrencyDialog = ({ open, user, onClose, onSave }) => {
    const [value, setValue] = useState(0);
    const [description, setDescription] = useState('');
    useEffect(() => {
        if (open) {
            setDescription('');
            setValue(0);
        }
    }, [open]);
    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>Anna pelibonusta käyttäjälle (€)</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    fetch(`${config.backendAddress}/api/v1/users/${user.id}/accounts/bonus`, {
                        body: JSON.stringify({
                            variety: 'game_currency',
                            value: value * 100,
                            external_reference: description
                        }),
                        method: 'POST'
                    })
                        .then(() => {
                            showMessage('Pelibonusta lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='addGameCurrency'>
                    <TextField
                        required
                        fullWidth
                        type='number'
                        value={value}
                        margin='normal'
                        onChange={((event) => setValue(event.target.value))}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label='Syy'
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='addGameCurrency'>
                    Lisää pelirahaa
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddGameCurrencyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddGameCurrencyDialog;
