import React, { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Form, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const Redeem = ({ prize, lottery, onClaim, onCancel }) => {
    const [{ data: prizeData, loading: prizeLoading }, setPrizeUrl] = useFetch(null);
    const [{ data: optionsData, loading: optionsLoading }, setOptionsUrl] = useFetch(null);
    useEffect(() => {
        if (prize && prize.product_id) {
            setPrizeUrl(`${config.backendAddress}/api/v1/products/${prize.product_id}`);
            setOptionsUrl(`${config.backendAddress}/api/v1/products/${prize.product_id}/options`);
        }
    }, [prize, setOptionsUrl, setPrizeUrl]);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [option, setOption] = useState('');

    const updateClaim = (body) => {
        if (body.phone === '' && (body.address === '' || body.postal_code === '' || body.city === '')) {
            return;
        }
        body.product_option_id = body.product_option_id !== undefined ? parseInt(body.product_option_id) : body.product_option_id;

        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery}/claims?admin_claim=true`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Arpamaailma-Claim-Token': Cookies.get('arpamaailma-claim-token')
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                claimProduct(body);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const claimProduct = (body) => {
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery}/claims/commit?admin_claim=true`, {
            method: 'PUT',
            headers: {
                'Arpamaailma-Claim-Token': Cookies.get('arpamaailma-claim-token')
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                showMessage('Tuote lunastettu');
                onClaim(json);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const claimExtraLottery = (body) => {
        if(body.postal_code == '') {
            // We delete postal code from body if it is empty
            // string. This is to avoid backend complaining about
            // invalid postal code. This is required since admin can
            // commit extra lottery partake either with address or
            // phone.
            delete body.postal_code;
        }
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery}/extra-lottery/commit?admin_claim=true`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Arpamaailma-Claim-Token': Cookies.get('arpamaailma-claim-token')
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                showMessage('Osallistuttu lisäarvontaan');
                onClaim(json);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const renderTextField = (label, name, value, changeFunc, required = true, type = 'text') => {
        return (
            <div>
                <TextField
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    required={required}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                    style={{ width: 250 }}
                />
            </div>
        );
    };

    const renderSelectField = (label, name, value, changeFunc, options, required = true, type = 'text') => {
        return (
            <div>
                <TextField
                    select
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    required={required}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                    style={{ width: 250 }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    };

    /*if (prize.product_id && !prize.product_claimable) {
        return (
            <>
                <LoadingBar in={prizeLoading || optionsLoading} />
                {!prizeLoading && !optionsLoading && (
                    <Container>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h4'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                {prizeData ? getLangValue(prizeData.names, 'fi') : ''}
                            </Typography>
                        </Toolbar>
                        <Typography variant='body1'>
                            <br />
                            Lähetä voittoarpasi osoitteeseen:
                            <br /><br />
                            Eventio Group Oy
                            <br />
                            Tierankatu 4 B
                            <br />
                            20520 Turku
                            <br />
                            Puh. 0403 110 555
                            <br />
                            info@eventio.fi
                            <br /><br />
                            Suosittelemme lähettämään kirjattuna kirjeenä.
                            <br />
                            Muista täyttää arpaan yhteystietosi.
                            <br /><br />
                        </Typography>
                        <Button variant='contained' color='primary' style={{ marginTop: '1em' }} onClick={onCancel}>Takaisin</Button>
                    </Container>
                )}
            </>
        );
    }*/

    return (
        <>
            <LoadingBar in={prizeLoading || optionsLoading} />
            {!prizeLoading && !optionsLoading && (
                <Container>
                    <Toolbar disableGutters>
                        <Typography
                            variant='h4'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                            {prizeData ? getLangValue(prizeData.names, 'fi') : 'Ei voittoa'}
                        </Typography>
                    </Toolbar>
                    <Form action={prizeData ? updateClaim : claimExtraLottery}>
                        {optionsData && optionsData.length > 0 && renderSelectField('Valinta', 'product_option_id', option, setOption, optionsData.map((item) => {
                            return { label: getLangValue(item.names, 'fi'), value: item.id };
                        }))}
                        {renderTextField('Etunimi', 'firstname', firstname, setFirstname, true)}
                        {renderTextField('Sukunimi', 'lastname', lastname, setLastname, true)}
                        {renderTextField('Sähköposti', 'email', email, setEmail, false, 'email')}
                        {renderTextField('Puhelin', 'phone', phone, setPhone, false, 'tel')}
                        {renderTextField('Osoite', 'address', address, setAddress, prizeData ? true : false)}
                        {renderTextField('Postinumero', 'postal_code', zip, setZip, prizeData ? true : false, 'tel')}
                        {renderTextField('Paikkakunta', 'city', city, setCity, prizeData ? true : false)}
                        <div>
                            <Button variant='contained' color='primary' style={{ marginTop: '1em', width: 250 }} type='submit'>{prizeData ? 'Lunasta' : 'Osallistu lisäarvontaan'}</Button>
                        </div>
                        <div>
                            <Button variant='contained' color='primary' style={{ marginTop: '1em', width: 250 }} onClick={onCancel}>Peruuta</Button>
                        </div>
                    </Form>
                </Container>
            )}
        </>
    );
};

export default Redeem;
