import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const AddShopCurrencyDialog = ({ open, user, ticketSerieses, onClose, onSave }) => {
    const [{ data: products }, setUrl] = useFetch(null);
    const [series, setSeries] = useState('');
    const [description, setDescription] = useState('');
    const [product, setProduct] = useState(0);
    useEffect(() => {
        if (open) {
            setDescription('');
            setProduct('');
            setSeries('');
        }
    }, [open]);
    useEffect(() => {
        if (series) {
            setUrl(`${config.backendAddress}/api/v1/ticket-series/${series.value}/products`);
        }
    }, [series, setUrl]);

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>Anna ostobonusta käyttäjälle</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    fetch(`${config.backendAddress}/api/v1/users/${user.id}/accounts/bonus`, {
                        body: JSON.stringify({
                            variety: 'shop_currency',
                            ticket_series_id: series.value,
                            value: product.value.value,
                            external_reference: description.value
                        }),
                        method: 'POST'
                    })
                        .then(() => {
                            showMessage('Ostobonusta lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='addShopCurrency'>
                    <Select
                        required
                        value={series}
                        onChange={(item) => setSeries(item)}
                        options={stableSort(ticketSerieses, 'validity_end', 'desc').filter((item) => moment().diff(moment(item.validity_end)) < 0).map((option) => {
                            return { label: `${getLangValue(option.lottery_names, 'fi')} ${option.alias}`, value: option.id };
                        })}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei arpasarjoja'}
                        textFieldProps={{
                            label: 'Arpasarja',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <Select
                        required
                        value={product}
                        onChange={(item) => setProduct(item)}
                        options={products ? products.filter((item) => item.value_transferrable).map((option) => {
                            return { label: getLangValue(option.names, 'fi'), suffix: `${formatCurrency(option.value)} €`, value: option };
                        }) : []}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei voittoja. Valitse ensin arpasarja.'}
                        textFieldProps={{
                            label: 'Voitto',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <Select
                        required
                        value={description}
                        onChange={(item) => setDescription(item)}
                        options={['Lahja', 'Winback lahja', 'Tuote ei tullut perille', 'Tuote hajonnut', 'Muu syy'].map((item) => {
                            return { label: item, value: item };
                        })}
                        placeholder='Valitse'
                        textFieldProps={{
                            label: 'Syy',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='addShopCurrency'>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddShopCurrencyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddShopCurrencyDialog;
