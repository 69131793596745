import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingBar } from '../common';
import { fetchWrap as fetch, formatCurrency, getLangValue, openBlob, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';
import Grid from '@material-ui/core/Grid';

let interval;

const BonusReport = ({ startTime, endTime, onReturn }) => {
    const [{ data, loading }] = useFetch(
        `${config.backendAddress}/api/v1/reports/bonus?` +
        `start_time=${moment(startTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&` +
        `end_time=${moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss')}`
    );

    return (
        <>
            <LoadingBar in={loading}/>
            <Container>
                {data && (
                    <>
                    <Toolbar disableGutters>
                        <Typography
                            variant='h4'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                                Raportti bonuksista ajalta ({moment(startTime).format('DD.MM.YYYY')} - {moment(endTime).format('DD.MM.YYYY')})
                        </Typography>
                        <Button variant='contained' color='primary' onClick={onReturn}>
                            Takaisin
                        </Button>
                    </Toolbar>
                    <Paper style={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='right'>Rekisteröintibonus</TableCell>
                                    <TableCell align='right'>Ensitalletusbonus</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key='Voittokoodit'>
                                    <TableCell align='right'>{data.registration_bonus_len || 0}</TableCell>
                                    <TableCell align='right'>{data.deposit_bonus_len || 0}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                    </>
                )}
            </Container>
        </>
    );
};

export default BonusReport;
