import React from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { LoadingBar } from '../common';

const Backup = () => {
    return (
        <>
            <LoadingBar in={false} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Varmuuskopio
                    </Typography>
                </Toolbar>
                <Typography variant='body1'>
                    Tietokantaan ei ole tullut uusia voittajia viime viennistä lähtien.
                </Typography>
                <Button variant='contained' color='primary' style={{ marginTop: '1em' }}>Lataa CSV-tiedosto</Button>
            </Container>
        </>
    );
};

export default Backup;
