// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
// This solution works on Chrome, Safari, Firefox, Opera, IE11 and Edge.

const openBlob = (blob, mimetype, name) => {
    const newBlob = new Blob([blob], { type: mimetype });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = `${name}`;
    link.click();
    setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
};

export default openBlob;
