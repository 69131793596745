import * as Cookies from 'js-cookie';
import { showMessage } from '../../common';
import { userManager } from '../../App';
import i18n from '../../i18n';

const fetchWrap = (url, options) => {
    const init = { ...options };
    if (!init.headers) {
        init.headers = {};
    }
    // Allow browser to determine content-type header of payload is instance of FormData
    if (!init.headers['Content-Type'] && !(init.body instanceof FormData)) {
        init.headers['Content-Type'] = 'application/json';
    }
    const csrfToken = Cookies.get('_csrf');
    if(csrfToken) {
        init.headers.Csrftoken = csrfToken;
    }
    const token = Cookies.get('id_token');
    if (token) {
        init.headers.Authorization = `Bearer ${token}`;
    }
    init.credentials = 'include';
    return fetch(url, init)
        .then((response) => {
            if (!response.ok) {
                // User is not logged in or session has expired
                if (response.status === 401) {
                    userManager.signinRedirect();
                }
                throw response;
            }
            return response;
        })
        .catch((response) => {
            if (response instanceof Response) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    response.json().then((json) => {
                        if (json.code) {
                            showMessage(i18n.gettext(json.code), true);
                        } else {
                            showMessage(json.description || json.title || `${response.status} ${response.statusText}`, true);
                        }
                    });
                } else {
                    showMessage(`${response.status} ${response.statusText}`, true);
                }
            } else if (response instanceof Error) {
                showMessage(response.message, true);
            } else {
                showMessage(`${response.status} ${response.statusText}`, true);
            }
            throw response;
        });
};

export default fetchWrap;
