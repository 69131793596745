import React, { useState } from 'react';
import { LoadingBar } from '../common';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ImportPrizeCodesDialog from './ImportPrizeCodesDialog';

const ImportPrizeCodes = () => {
    const [importDialogOpen, setImportDialogOpen ] = useState(false);

    return(
        <>
            <LoadingBar in={false} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tuo sähköisten lahjakorttien koodeja:
                    </Typography>
                </Toolbar>
                <Toolbar disableGutters>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setImportDialogOpen(true)}
                        style={{ marginRight: '1em' }}>
                        Valitse CSV tiedosto
                    </Button>
                </Toolbar>
            </Container>
            <ImportPrizeCodesDialog
                onSave={() => setImportDialogOpen(false)}
                onClose={() => {
                    setImportDialogOpen(false);
                }}
                open={importDialogOpen}
                title={'Tuo sähköisten lahjakorttien koodeja'}/>
        </>
    );
};

export default ImportPrizeCodes;
