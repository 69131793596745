import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NewPasswordDialog from './NewPasswordDialog';
import AddShopCurrencyDialog from './AddShopCurrencyDialog';
import AddGameCurrencyDialog from './AddGameCurrencyDialog';
import RemoveGameCurrencyDialog from './RemoveGameCurrencyDialog';
import RemoveShopCurrencyDialog from './RemoveShopCurrencyDialog';
import TransferAccountsDialog from './TransferAccountsDialog';
import LaunchIcon from '@material-ui/icons/Launch';
import GiftDialog from './GiftDialog';
import Invalidate from './Invalidate';
import Redeem from './Redeem';
import config from '../config';
import i18n from '../i18n';
import { ConfirmDialog, Form, Table, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';

const accountVarietyMap = {
    bonus: 'Ostobonus',
    game_currency: 'Pelibonus',
    wins: 'Voitto',
    extra_wins: 'Lisävoitto',
    money: 'Raha',
    claims: 'Lunastus',
    payments: 'Maksu',
    invalidation: 'Peruutus',
    order_deposit: 'Tilausmaksu',
};

const accountEventMap = {
    win: 'Voitto',
    cancel: 'Peruutus',
    withdraw: 'Nosto',
    deposit: 'Pano',
    win_invalidation: 'Voiton erääntyminen',
    bonus_deposit: 'Bonus pano',
    swap_account: 'Saldon siirto',
    correction: 'Korjaus',
    refund: 'Palautus',
};

const tiliFilter = (item) => ['bonus', 'game_currency', 'wins', 'extra_wins', 'money', 'order_deposit'].indexOf(item.variety) != -1;

const User = ({ user, onReturn }) => {
    const [{ data: accountsData, loading: accountsLoading, refresh: refreshAccounts }] = useFetch(user.id && `${config.backendAddress}/api/v1/users/${user.id}/accounts`);
    const [{ data: ticketSeriesData, loading: ticketSeriesLoading }] = useFetch(`${config.backendAddress}/api/v1/ticket-series`);
    const [{ data: eventsData, loading: eventsLoading, refresh: refreshEvents }, setEventUrl] = useFetch(null);
    const [{ data: claimsData, loading: claimsLoading, refresh: refreshClaims }] = useFetch(user.id && `${config.backendAddress}/api/v1/claims?user_id=${user.id}`);
    const [{ data: playableTicketStatesData, loading: playableTicketStatesLoading }] = useFetch(user.id && `${config.backendAddress}/api/v1/playable-tickets/states?user_id=${user.id}`);
    const [{ data: invalidatedTicketData, loading: invalidatedTicketDataLoading, refresh: refreshInvalidations }] = useFetch(user.id && `${config.backendAddress}/api/v1/invalidations?user_id=${user.id}`);
    const [{ data: ordersData, loading: ordersLoading, refresh: refreshOrders }] = useFetch(user.id && `${config.backendAddress}/api/v1/orders?user_id=${user.id}`);
    const [{ data: limitsData, loading: limitsLoading, refresh: refreshLimits }] = useFetch(user.id && `${config.backendAddress}/api/v1/users/limits?user_id=${user.id}`);
    const [accountId, setAccountId] = useState('');
    useEffect(() => {
        if (accountsData) {
            if (accountId) {
                setEventUrl(`${config.backendAddress}/api/v1/accounts/${accountId}/events`);
            } else {
                const accounts = accountsData.items.filter(tiliFilter);
                if (accounts.length > 0) {
                    setAccountId(accounts[0].id);
                }
            }
        }
    }, [accountId, accountsData, setEventUrl]);
    useEffect(() => {
        if(limitsData && limitsData.length >= 1) {
            setPlayByDay(limitsData[0].value / 100);
            setPlayByWeek(limitsData[1].value / 100);
            setPlayByMonth(limitsData[2].value / 100);
            setDepositByMonth(limitsData[3].value / 100);
            setPlayTimeStart((limitsData[4].value).substring(0, 5));
            setPlayTimeEnd((limitsData[5].value).substring(0, 5));
            setPlayPauseDatetime(limitsData[6].value ? limitsData[6].value : '');
        }
    }, [limitsData]);
    const [uua, setUua] = useState(user.external_ids && user.external_ids.uua ? user.external_ids.uua : '');
    const [firstname, setFirstname] = useState(user.firstname);
    const [lastname, setLastname] = useState(user.lastname);
    const [email, setEmail] = useState(user.email || '');
    const [secondaryEmail, setSecondaryEmail] = useState(user.unofficial_emails && user.unofficial_emails.length > 0 ? user.unofficial_emails[0] : '');
    const [phone, setPhone] = useState(user.phone || '');
    const [secondaryPhone, setSecondaryPhone] = useState(user.unofficial_phones && user.unofficial_phones.length > 0 ? user.unofficial_phones[0] : '');
    const [address, setAddress] = useState(user.address || '');
    const [postalCode, setPostalCode] = useState(user.postal_code || '');
    const [city, setCity] = useState(user.locality || user.city || '');
    const [marketingPermission, setMarketingPermission] = useState(user.marketing_permission || false);
    const [newPassword, setNewPassword] = useState(null);
    const [triggerViskanWriteDialogOpen, setTriggerViskanWriteDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState(false);
    const [addShopCurrencyDialogOpen, setAddShopCurrencyDialogOpen] = useState(false);
    const [removeShopCurrencyDialogOpen, setRemoveShopCurrencyDialogOpen] = useState(false);
    const [transferAccountsDialogOpen, setTransferAccountsDialogOpen] = useState(false);
    const [addGameCurrencyDialogOpen, setAddGameCurrencyDialogOpen] = useState(false);
    const [removeGameCurrencyDialogOpen, setRemoveGameCurrencyDialogOpen] = useState(false);
    const [giftDialogOpen, setGiftDialogOpen] = useState(false);
    const [redeemOpen, setRedeemOpen] = useState(false);
    const [invalidateOpen, setInvalidateOpen] = useState(false);
    const [account, setAccount] = useState(null);
    const [playByDay, setPlayByDay] = useState('');
    const [playByWeek, setPlayByWeek] = useState('');
    const [playByMonth, setPlayByMonth] = useState('');
    const [depositByMonth, setDepositByMonth] = useState('');
    const [playTimeStart, setPlayTimeStart] = useState('');
    const [playTimeEnd, setPlayTimeEnd] = useState('');
    const [playPause, setPlayPause] = useState('');
    const [playPauseDatetime, setPlayPauseDatetime] = useState('');

    const refresh = () => {
        refreshAccounts();
        refreshEvents();
        refreshClaims();
        refreshOrders();
        refreshLimits();
        refreshInvalidations();
    };

    if (redeemOpen) {
        return (
            <Redeem
                user={user}
                onReturn={() => setRedeemOpen(false)}
                refresh={() => {
                    refreshAccounts();
                    refreshEvents();
                }}
            />
        );
    }

    if (invalidateOpen) {
        return (
            <Invalidate
                user={user}
                onReturn={() => setInvalidateOpen(false)}
                refresh={() => {
                    refreshAccounts();
                    refreshEvents();
                }}
            />
        );
    }

    return (
        <>
            <LoadingBar in={accountsLoading || eventsLoading || ticketSeriesLoading || claimsLoading || ordersLoading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        {user.firstname} {user.lastname}
                    </Typography>
                    <Button style={{ marginRight: '10px' }} variant='contained' color='primary' onClick={refresh}>Päivitä</Button>
                    <Button variant='contained' color='primary' onClick={onReturn}>Takaisin</Button>
                </Toolbar>

                <div style={{ marginBottom: '10px' }}>
                    {
                        user.strongly_authenticated ?
                            `Vahvasti tunnistautunut nimellä ${user.strongly_authenticated.first_names} ${user.strongly_authenticated.family_name}`:
                            'Ei vahvasti tunnistautunut'
                    }
                </div>

                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6} xl={5}>
                        <Form id='updateAccount' action={(body) => {
                            const payload = user.subject ? {
                                account: {
                                    first_name: body.firstname,
                                    last_name: body.lastname,
                                    email: body.email,
                                    phone: body.phone,
                                    address: {
                                        street_address: body.address,
                                        city: body.city,
                                        postal_code: body.postal_code
                                    },
                                    unofficial_emails: [
                                        body.unofficial_email
                                    ],
                                    unofficial_phones: [
                                        body.unofficial_phone
                                    ],
                                    marketing_permission: body.marketing_permission
                                }
                            } : {
                                id: user.id,
                                firstname: body.firstname,
                                lastname: body.lastname,
                                email: body.email,
                                phone: body.phone,
                                address: body.address,
                                locality: body.city,
                                postal_code: body.postal_code
                            };

                            const limits = [{
                                variety: 'play_by_day',
                                value: playByDay * 100,
                            }, {
                                variety: 'play_by_week',
                                value: playByWeek * 100,
                            }, {
                                variety: 'play_by_month',
                                value: playByMonth * 100,
                            }, {
                                variety: 'deposit_by_month',
                                value: depositByMonth * 100,
                            }, {
                                variety: 'play_time_start',
                                value: playTimeStart + ':00',
                            }, {
                                variety: 'play_time_end',
                                value: playTimeEnd + ':00',
                            }, {
                                variety: 'play_pause',
                                value: playPause ? playPause : null,
                            }];

                            // Disabled fields are not available in body
                            if(user.subject && body.uua) {
                                payload.account.external_ids = {
                                    uua: body.uua
                                };
                            }

                            fetch(user.subject ? `${config.backendAddress}/api/v1/users/by-subject/${user.subject}` : `${config.backendAddress}/api/v1/users/${user.id}`, {
                                method: 'PATCH',
                                body: JSON.stringify(payload)
                            })
                                .then((response) => {
                                    showMessage('Käyttäjä päivitetty');
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                            fetch(`${config.backendAddress}/api/v1/users/limits?user_id=${user.id}`, {
                                method: 'PATCH',
                                body: JSON.stringify(limits)
                            }).then((response) => {
                                showMessage(('Käyttäjä päivitetty'));
                            }).catch((error) => {
                                console.error(error);
                            });
                        }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Eventio-tilin ID (subject)'
                                        id='subject'
                                        name='subject'
                                        value={user.subject}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Arpamaailman ID'
                                        id='id'
                                        name='id'
                                        value={user.id}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {user.subject && (
                                        <TextField
                                            fullWidth
                                            label='UUA asiakasnumero'
                                            id='uua'
                                            name='uua'
                                            value={uua}
                                            onChange={(event) => setUua(event.target.value)}
                                            disabled={user.external_ids && user.external_ids.uua ? true : false}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Etunimi'
                                        id='firstname'
                                        name='firstname'
                                        value={firstname}
                                        required
                                        onChange={(event) => setFirstname(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Sukunimi'
                                        id='lastname'
                                        name='lastname'
                                        value={lastname}
                                        required
                                        onChange={(event) => setLastname(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Sähköposti'
                                        id='email'
                                        name='email'
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {user.subject && (
                                        <TextField
                                            fullWidth
                                            label='Toissijainen sähköposti'
                                            helperText='Ei voi käyttää sisäänkirjautumiseen'
                                            id='unofficial_email'
                                            name='unofficial_email'
                                            value={secondaryEmail}
                                            onChange={(event) => setSecondaryEmail(event.target.value)}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Puhelinnumero'
                                        id='phone'
                                        name='phone'
                                        value={phone}
                                        type='tel'
                                        onChange={(event) => setPhone(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {user.subject && (
                                        <TextField
                                            fullWidth
                                            label='Toissijainen puhelinnumero'
                                            id='unofficial_phone'
                                            name='unofficial_phone'
                                            value={secondaryPhone}
                                            onChange={(event) => setSecondaryPhone(event.target.value)}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Lähiosoite'
                                        id='address'
                                        name='address'
                                        value={address}
                                        onChange={(event) => setAddress(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Postinumero'
                                        id='postal_code'
                                        name='postal_code'
                                        value={postalCode}
                                        type='tel'
                                        onChange={(event) => setPostalCode(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Postitoimipaikka'
                                        id='city'
                                        name='city'
                                        value={city}
                                        onChange={(event) => setCity(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Luotu'
                                        id='created_at'
                                        name='created_at'
                                        value={user.created_at ? moment.utc(user.created_at).local().format('DD.MM.YYYY HH:mm') : ' '}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Viimeksi muutettu'
                                        id='modified_at'
                                        name='modified_at'
                                        value={user.modified_at ? moment.utc(user.modified_at).local().format('DD.MM.YYYY HH:mm') : ' '}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Viimeisin kirjautuminen'
                                        id='last_login_at'
                                        name='last_login_at'
                                        value={user.last_login_at ? moment.utc(user.last_login_at).local().format('DD.MM.YYYY HH:mm') : ' '}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='marketing_permission'
                                                name='marketing_permission'
                                                value={marketingPermission}
                                                checked={marketingPermission}
                                                onChange={(event) => setMarketingPermission(event.target.checked)}
                                            />
                                        }
                                        label='Markkinointi sallittu'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}/>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                    >
                                        Pelirajat
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Pelien ostoraja vuorokaudessa (€)'
                                        id='play_by_day'
                                        name='play_by_day'
                                        value={playByDay}
                                        onChange={(event) => setPlayByDay(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Pelien ostoraja viikossa (€)'
                                        id='play_by_week'
                                        name='play_by_week'
                                        value={playByWeek}
                                        onChange={(event) => setPlayByWeek(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Pelien ostoraja kuukaudessa (€)'
                                        id='play_by_month'
                                        name='play_by_month'
                                        value={playByMonth}
                                        onChange={(event) => setPlayByMonth(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Kuukausittainen talletusraja (€)'
                                        id='deposit_by_month'
                                        name='deposit_by_month'
                                        value={depositByMonth}
                                        onChange={(event) => setDepositByMonth(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant='h5'
                                        noWrap
                                    >
                                        Sallittu peliaika vuorokaudessa
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}/>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Alku'
                                        id='play_time_start'
                                        name='play_time_start'
                                        type='time'
                                        value={playTimeStart}
                                        onChange={(event) => setPlayTimeStart(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='Loppu'
                                        id='play_time_end'
                                        name='play_time_end'
                                        type='time'
                                        value={playTimeEnd}
                                        onChange={(event) => setPlayTimeEnd(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label='Pelitauko'
                                        id='play_pause'
                                        name='play_pause'
                                        value={playPause}
                                        onChange={(event) => setPlayPause(event.target.value)}
                                    >
                                        <MenuItem value={null}>-</MenuItem>
                                        <MenuItem value={24*60}>Vuorokausi</MenuItem>
                                        <MenuItem value={7*24*60}>Viikko</MenuItem>
                                        <MenuItem value={30*24*60}>Kuukausi</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant='body1'
                                        noWrap>
                                        {playPauseDatetime ?`Pelitauko on voimassa ${moment(playPauseDatetime).format('YYYY-MM-DD')} kello ${moment(playPauseDatetime).format('HH:mm')} asti` : 'Pelitaukoa ei ole asetettu'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Form>
                        <Grid item container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    form='updateAccount'
                                >
                                    Tallenna tiedot
                                </Button>
                            </Grid>
                            {user.subject && user.id && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setRedeemOpen(true)}
                                        >
                                            Rekisteröi arpa asiakkaalle
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setInvalidateOpen(true)}
                                        >
                                            Mitätöi arpa asiakkaalle
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setAddShopCurrencyDialogOpen(true)}
                                        >
                                            Anna ostobonusta
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setAddGameCurrencyDialogOpen(true)}
                                        >
                                            Anna pelibonusta
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setRemoveShopCurrencyDialogOpen(true)}
                                        >
                                        Poista ostobonusta
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setRemoveGameCurrencyDialogOpen(true)}
                                        >
                                            Poista pelibonusta
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setGiftDialogOpen(true)}
                                        >
                                            Anna tuotelahja
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setResetPasswordDialogOpen(true)}
                                        >
                                            Aseta uusi salasana
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setTransferAccountsDialogOpen(true)}
                                        >
                                            Siirrä saldot
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setTriggerViskanWriteDialogOpen(true)}
                                        >
                                            Lähetä tiedot Viskaniin
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {user.subject && user.id && (
                        <>
                            <Grid item xs={12} lg={6} xl={7}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                    >
                                        Tilit
                                    </Typography>
                                </Toolbar>
                                <Table
                                    idKey='id'
                                    data={accountsData && accountsData.items ?
                                        [{
                                            __variant: 'head',
                                            id: 'total',
                                            lottery: {
                                                names: [
                                                    { lang: 'fi', value: 'YHTEENSÄ' }
                                                ]
                                            },
                                            ticket_series: {
                                                alias: ''
                                            },
                                            saldo: accountsData.items
                                                .filter(tiliFilter)
                                                .reduce((total, item) => total + item.saldo, 0)
                                        },
                                        ...accountsData.items.filter(tiliFilter)] : []}
                                    rowsPerPage={11}
                                    columns={[
                                        { label: 'Arpa', key: 'lottery', callback: (value) => value ? getLangValue(value.names, 'fi') : '' },
                                        { label: 'Tyyppi', key: 'variety', callback: (value) => accountVarietyMap[value] },
                                        { label: 'Arpakausi', key: 'ticket_series', callback: (value) => value ? value.alias : '' },
                                        { label: 'Saldo', key: 'saldo', callback: (value) => `${formatCurrency(value)} €` }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Toolbar disableGutters style={{ height: 64 }}>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        Tilitapahtumat
                                    </Typography>
                                    {accountsData && accountsData.items && (
                                        <TextField
                                            select
                                            label='Tili'
                                            value={accountId}
                                            margin='normal'
                                            onChange={(event) => setAccountId(event.target.value)}
                                            style={{ minWidth: 300 }}
                                        >
                                            {accountsData.items.filter(tiliFilter).map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {
                                                      `${option.lottery ? getLangValue(option.lottery.names, 'fi') : ''}
                                                       ${option.ticket_series ? option.ticket_series.alias : ''}
                                                       (${accountVarietyMap[option.variety]})`
                                                    }
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Toolbar>
                                <Table
                                    idKey='id'
                                    data={eventsData && eventsData.items ? eventsData.items.map((item) => {
                                        if (item.from_account_id === accountId) {
                                            return {
                                                id: item.id,
                                                variety: item.variety,
                                                value: item.value,
                                                saldo_after: item.from_account_saldo_after,
                                                state: item.state,
                                                timestamp: item.timestamp
                                            };
                                        } else {
                                            return {
                                                id: item.id,
                                                variety: item.variety,
                                                value: item.value,
                                                saldo_after: item.to_account_saldo_after,
                                                state: item.state,
                                                timestamp: item.timestamp
                                            };
                                        }
                                    }) : []}
                                    rowsPerPage={10}
                                    columns={[
                                        { label: 'Tyyppi', key: 'variety', callback: (value) => accountEventMap[value] },
                                        { label: 'Arvo', key: 'value', callback: (value) => `${formatCurrency(value)} €` },
                                        { label: 'Saldo jälkeen', key: 'saldo_after', callback: (value) => `${formatCurrency(value)} €` },
                                        { label: 'Tila', key: 'state', callback: (value) => i18n.gettext(value) },
                                        { label: 'Aika', key: 'timestamp', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        Tilaukset
                                    </Typography>
                                </Toolbar>
                                <Table
                                    idKey='id'
                                    data={ordersData ? ordersData.items : []}
                                    rowsPerPage={10}
                                    columns={[
                                        { label: 'Tilausnumero', key: 'id' },
                                        { label: 'Tuotteet', key: 'products', callback: (value) => value.map((product) => product.product_id ? `${getLangValue(product.product_names, 'fi')}${product.product_option_id ? `, ${getLangValue(product.product_option_names, 'fi')}` : ''} (${product.count})` : '').join(', ') },
                                        { label: 'Arvo', key: 'value', callback: (value) => `${formatCurrency(value)} €` },
                                        { label: 'Tila', key: 'state', callback: (value) => i18n.gettext(value) },
                                        { label: 'Aika', key: 'created_at', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' },
                                        { label: 'Ext',  key: 'external_order_id', callback: (value) => {
                                            if(!value) return null;
                                            let href = `${config.mycashflowUri}/flow/orders/${value}`;
                                            return (
                                                <div>
                                                    <span style={{ marginRight: '1em' }}>{value}</span>
                                                    <a
                                                        target="_blank"
                                                        style={{ verticalAlign: 'middle' }}
                                                        href={ href }
                                                    >
                                                        <LaunchIcon />
                                                    </a>
                                                </div>
                                            );
                                        }}
                                    ]}
                                />
                            </Grid>
                        </>
                    )}
                    {user.id && (
                        <>
                            <Grid item xs={12} lg={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        Lunastettavat arvat
                                    </Typography>
                                </Toolbar>
                                <Table
                                    idKey='id'
                                    data={claimsData && claimsData.items ? claimsData.items.map((claim) => {
                                        let product = '';
                                        if (claim.product_id) {
                                            if (claim.account_event) {
                                                product = `${formatCurrency(claim.account_event.value)} €`;
                                            } else {
                                                product = `${getLangValue(claim.product_names, 'fi')}${claim.product_option_names ? ` (${getLangValue(claim.product_option_names, 'fi')})` : ''}`;
                                            }
                                        } else {
                                            product = 'Lisäarvonta';
                                        }
                                        return {
                                            lottery: getLangValue(claim.lottery_names, 'fi'),
                                            alias: claim.ticket_series_alias,
                                            ticket_code_a: claim.ticket_code_a,
                                            product: product,
                                            state: claim.claimed ? 'lunastettu': 'odottaa',
                                            timestamp: claim.commited_at || claim.created_at
                                        };
                                    }) : []}
                                    rowsPerPage={10}
                                    columns={[
                                        { label: 'Arpa', key: 'lottery' },
                                        { label: 'Arpakausi', key: 'alias' },
                                        { label: 'Arpanumero', key: 'ticket_code_a' },
                                        { label: 'Voitto', key: 'product' },
                                        { label: 'Tila', key: 'state' },
                                        { label: 'Aika', key: 'timestamp', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' }
                                    ]}
                                />
                            </Grid>
                        </>
                    )}
                    {user.subject && user.id && (
                        <>
                            <Grid item xs={12} lg={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        Pelattavat arvat
                                    </Typography>
                                </Toolbar>
                                <Table
                                    idKey='id'

                                    data={playableTicketStatesData
                                          && playableTicketStatesData.items
                                        ? playableTicketStatesData.items.map((state) => {
                                            let product = '';
                                            if(state.prize) {
                                                product = `${formatCurrency(state.prize)} €`;
                                            } else if(state.product_id) {
                                                product = `${getLangValue(state.product_names, 'fi')} ` +
                                                      `(${formatCurrency(state.product_value)} €) ` +
                                                      `${state.product_option_names ? ` (${getLangValue(state.product_option_names, 'fi')})` : ''}`;
                                            } else {
                                                product = 'Lisäarvonta';
                                            }
                                            return {
                                                lottery: getLangValue(state.lottery_names, 'fi'),
                                                alias: state.ticket_series_alias,
                                                ticket_code_a: state.playable_ticket_code_a,
                                                product: product,
                                                state: state.played ? 'pelattu' : 'ostettu',
                                                timestamp: state.played_at,
                                            };
                                        })
                                        : []}

                                    rowsPerPage={10}
                                    columns={[
                                        { label: 'Arpa', key: 'lottery' },
                                        { label: 'Arpakausi', key: 'alias' },
                                        { label: 'Arpanumero', key: 'ticket_code_a' },
                                        { label: 'Voitto', key: 'product' },
                                        { label: 'Tila', key: 'state' },
                                        { label: 'Aika', key: 'timestamp', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' }
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h4'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        Mitätöidyt arvat
                                    </Typography>
                                </Toolbar>
                                <Table
                                    idKey='id'

                                    data={invalidatedTicketData
                                          && invalidatedTicketData.items
                                        ? invalidatedTicketData.items.map((item) => {
                                            return {
                                                lottery: getLangValue(item.lottery_names, 'fi'),
                                                alias: item.ticket_series_alias,
                                                ticket_code_a: item.ticket.code_a,
                                                timestamp: item.created_at,
                                            };
                                        })
                                        : []}

                                    rowsPerPage={10}
                                    columns={[
                                        { label: 'Arpa', key: 'lottery' },
                                        { label: 'Arpakausi', key: 'alias' },
                                        { label: 'Arpanumero', key: 'ticket_code_a' },
                                        { label: 'Aika', key: 'timestamp', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' },
                                    ]}
                                />
                            </Grid>

                        </>
                    )}
                    {user.raw_contact_info && (
                        <Grid item xs={12} lg={user.subject ? 12 : 6} xl={user.subject ? 12 : 7}>
                            <Toolbar disableGutters>
                                <Typography
                                    variant='h4'
                                    noWrap
                                    style={{ flexGrow: 1 }}
                                >
                                    Raaka yhteystieto
                                </Typography>
                            </Toolbar>
                            <Paper style={{ whiteSpace: 'pre-line', padding: 16 }}>
                                {user.raw_contact_info}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <ConfirmDialog
                open={resetPasswordDialogOpen}
                title='Salasanan nollaus'
                text={'Haluatko varmasti generoida asiakkaalle uuden salasanan?'}
                confirmText='Nollaa salasana'
                onCancel={() => setResetPasswordDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/users/by-subject/${user.subject}/password`, {
                        method: 'PUT'
                    })
                        .then((response) => response.json())
                        .then((json) => {
                            setResetPasswordDialogOpen(false);
                            setNewPasswordDialogOpen(true);
                            setNewPassword(json.new_password);
                            showMessage('Salasana nollattu');
                        })
                        .catch((error) => {
                            setResetPasswordDialogOpen(false);
                        });
                }}
            />
            <ConfirmDialog
                open={triggerViskanWriteDialogOpen}
                title='Lähetä tiedot Viskaniin'
                text={'Haluatko varmasti lähettää asiakkaan tiedot Viskaniin?'}
                confirmText='Lähetä'
                onCancel={() => setTriggerViskanWriteDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/viskan/trigger-write/${user.id}`, {
                        method: 'POST'
                    })
                        .then((response) => response.json())
                        .then((json) => {
                            setTriggerViskanWriteDialogOpen(false);
                            showMessage('Lähetetty');
                        })
                        .catch((error) => {
                            setTriggerViskanWriteDialogOpen(false);
                        });
                }}
            />
            <NewPasswordDialog
                open={newPasswordDialogOpen}
                user={user}
                newPassword={newPassword}
                onClose={() => setNewPasswordDialogOpen(false)}
            />
            <AddShopCurrencyDialog
                open={addShopCurrencyDialogOpen}
                user={user}
                ticketSerieses={ticketSeriesData ? ticketSeriesData.items : []}
                onClose={() => setAddShopCurrencyDialogOpen(false)}
                onSave={() => {
                    setAddShopCurrencyDialogOpen(false);
                    refreshAccounts();
                    refreshEvents();
                }}
            />
            <AddGameCurrencyDialog
                open={addGameCurrencyDialogOpen}
                user={user}
                onClose={() => setAddGameCurrencyDialogOpen(false)}
                onSave={() => {
                    setAddGameCurrencyDialogOpen(false);
                    refreshAccounts();
                    refreshEvents();
                }}
            />
            <RemoveGameCurrencyDialog
                open={removeGameCurrencyDialogOpen}
                user={user}
                onClose={() => setRemoveGameCurrencyDialogOpen(false)}
                onSave={() => {
                    setRemoveGameCurrencyDialogOpen(false);
                    refreshAccounts();
                    refreshEvents();
                }}
            />
            <RemoveShopCurrencyDialog
                open={removeShopCurrencyDialogOpen}
                user={user}
                ticketSerieses={ticketSeriesData ? ticketSeriesData.items : []}
                onClose={() => setRemoveShopCurrencyDialogOpen(false)}
                onSave={() => {
                    setRemoveShopCurrencyDialogOpen(false);
                    refreshAccounts();
                    refreshEvents();
                }}
            />
            <TransferAccountsDialog
                open={transferAccountsDialogOpen}
                user={user}
                ticketSerieses={ticketSeriesData ? ticketSeriesData.items : []}
                onClose={() => setTransferAccountsDialogOpen(false)}
                onSave={() => {
                    setTransferAccountsDialogOpen(false);
                    refreshAccounts();
                    refreshEvents();
                }}
            />
            <GiftDialog
                open={giftDialogOpen}
                user={user}
                ticketSerieses={ticketSeriesData ? ticketSeriesData.items : []}
                onClose={() => setGiftDialogOpen(false)}
                onSave={() => {
                    setGiftDialogOpen(false);
                    refreshOrders();
                }}
            />
        </>
    );
};

export default User;
