import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const countOptions = [];
for (let index = 0; index < 10; index++) {
    countOptions[index] = { label: index+1, value: index+1 };
}

const GiftDialog = ({ open, user, ticketSerieses, onClose, onSave }) => {
    const [{ data }] = useFetch(`${config.backendAddress}/api/v1/products`);
    const [{ data: optionsData }, setUrl] = useFetch(null);
    const [series, setSeries] = useState('');
    const [option, setOption] = useState('');
    const [product, setProduct] = useState('');
    const [count, setCount] = useState(countOptions[0]);
    useEffect(() => {
        if (product) {
            setUrl(`${config.backendAddress}/api/v1/products/${product.value}/options`);
        }
    }, [product, setUrl]);
    useEffect(() => {
        if (open) {
            setProduct('');
            setSeries('');
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>Anna tuotelahja</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    const products = [{
                        product_id: product.value,
                        count: count.value
                    }];
                    if (option) {
                        products[0].product_option_id = option.value;
                    }
                    fetch(`${config.backendAddress}/api/v1/ticket-series/${series.value}/gift`, {
                        body: JSON.stringify({
                            products,
                            user_id: user.id
                        }),
                        method: 'POST'
                    })
                        .then(() => {
                            showMessage('Lahja annettu');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='giveGift'>
                    <Select
                        required
                        value={series}
                        onChange={(item) => setSeries(item)}
                        options={stableSort(ticketSerieses, 'validity_end', 'desc').filter((item) => moment().diff(moment(item.validity_end)) < 0).map((option) => {
                            return { label: `${getLangValue(option.lottery_names, 'fi')} ${option.alias}`, value: option.id };
                        })}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei arpasarjoja'}
                        textFieldProps={{
                            label: 'Arpasarja',
                            InputLabelProps: {
                                shrink: true
                            }
                        }}
                    />
                    <Select
                        required
                        value={product}
                        onChange={(item) => setProduct(item)}
                        options={data ? data.filter((option) => option.tags.find((item) => getLangValue(item.names, 'fi') === 'internal.product.gift') != null).map((option) => {
                            return { label: getLangValue(option.names, 'fi'), value: option.id };
                        }): []}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei tuotteita'}
                        textFieldProps={{
                            label: 'Tuote',
                            InputLabelProps: {
                                shrink: true
                            }
                        }}
                    />
                    {optionsData && optionsData.length > 0 && (
                        <Select
                            required
                            value={option}
                            onChange={(item) => setOption(item)}
                            options={optionsData.map((item) => {
                                return { label: getLangValue(item.names, 'fi'), value: item.id };
                            })}
                            placeholder='Valitse'
                            textFieldProps={{
                                label: 'Optio',
                                InputLabelProps: {
                                    shrink: true
                                }
                            }}
                        />
                    )}

                    <Select
                        required
                        value={count}
                        onChange={(item) => setCount(item)}
                        options={countOptions}
                        placeholder='Valitse'
                        textFieldProps={{
                            label: 'Määrä',
                            InputLabelProps: {
                                shrink: true
                            }
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='giveGift'>
                    Anna
                </Button>
            </DialogActions>
        </Dialog>
    );
};

GiftDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default GiftDialog;
