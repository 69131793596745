import React, { useState } from 'react';
import queryString from 'query-string';
import EnterNumber from './EnterNumber';
import Redeem from './Redeem';

const Record = ({ location: { search } }) => {
    const [status, setStatus] = useState('enter');
    const [prize, setPrize] = useState(null);
    const values = queryString.parse(search);
    if (status === 'enter') {
        return (
            <EnterNumber
                lottery={values.lottery}
                onRedeem={(prize) => {
                    setPrize(prize);
                    setStatus('redeem');
                }}
            />
        );
    } else {
        return (
            <Redeem
                prize={prize}
                lottery={values.lottery}
                onClaim={() => setStatus('enter')}
                onCancel={() => {
                    setStatus('enter');
                    setPrize(null);
                }}
            />
        );
    }
};

export default Record;
