import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { stableSort } from './functions';

const styles = (theme) => ({});

const HiddenWrapper = ({ breakpoint, ...props }) => (
    <Hidden
        xsDown={breakpoint === 'xs'}
        smDown={breakpoint === 'sm'}
        mdDown={breakpoint === 'md'}
        lgDown={breakpoint === 'lg'}
        xlDown={breakpoint === 'xl'}
        {...props}
    />
);

const PaginatedTable = ({
    data,
    idKey,
    columns,
    rowActions,
    onRowClick,
    rowsPerPage,
    defaultOrder,
    defaultOrderBy,
    externalSort,
    total,
    onChangePage,
    onSort
}) => {
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderby] = useState(defaultOrderBy);
    const [page, setPage] = useState(0);

    const onRequestSort = (event, key) => {
        setOrder(orderBy === key && order === 'desc' ? 'asc' : 'desc');
        setOrderby(key);
        if (onSort) {
            onSort(key, orderBy === key && order === 'desc' ? 'asc' : 'desc');
        }
    };

    useEffect(() => {
        if (onChangePage) {
            onChangePage(page, rowsPerPage);
        }
    }, [onChangePage, page, rowsPerPage]);

    const rows = !externalSort ? stableSort(data, orderBy, order) : data;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
        <Paper elevation={1} style={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => {
                            return (
                                <HiddenWrapper key={column.key} breakpoint={column.breakpoint}>
                                    <TableCell component='th' scope='row' style={{ width: column.width }}>
                                        {column.disableSort ? column.label : (
                                            <TableSortLabel
                                                active={orderBy === column.key}
                                                direction={order}
                                                onClick={(event) => onRequestSort(event, column.key)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                </HiddenWrapper>
                            );
                        })}
                        {rowActions && <TableCell component='th' scope='row' />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item, index) => {
                        return (
                            <TableRow
                                key={idKey !== true ? item[idKey] : index}
                                hover={onRowClick != null}
                                onClick={onRowClick ? () => {
                                    onRowClick(item);
                                } : null}
                            >
                                {columns.map((column) => {
                                    return (
                                        <HiddenWrapper key={column.key} breakpoint={column.breakpoint}>
                                            <TableCell variant={item.__variant || 'body'} style={{ width: column.width }}>
                                                {column.callback ? column.callback(item[column.key]) : item[column.key]}
                                            </TableCell>
                                        </HiddenWrapper>
                                    );
                                })}
                                {rowActions &&
                                    <TableCell align='right' style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}>
                                        {rowActions(item)}
                                    </TableCell>
                                }
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 52.8 * emptyRows }}>
                            <TableCell colSpan={columns.length + (rowActions ? 1 : 0)} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={columns.length + (rowActions ? 1 : 0)}
                            count={total || rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event, newPage) => setPage(newPage)}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
};

PaginatedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    idKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowActions: PropTypes.func,
    defaultOrderBy: PropTypes.string,
    defaultOrder: PropTypes.string,
    externalSort: PropTypes.bool,
    onSort: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string.isRequired,
        callback: PropTypes.func,
        width: PropTypes.number,
        breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
    })).isRequired
};

PaginatedTable.defaultProps = {
    idKey: 'id',
    defaultOrder: 'asc',
    defaultOrderBy: '',
    rowsPerPage: Math.floor((window.innerHeight - 317) / 48)
};

export default withStyles(styles)(PaginatedTable);
