import React, { useState, useEffect } from 'react';
import { useFetch } from '../common/hooks';
import config from '../config';

import Search from './Search';
import ClaimableResults from './ClaimableResults';
import PlayableResults from './PlayableResults';

const Users = () => {
    const [{ data, loading, refresh }, setUrl] = useFetch(null);
    const [waitData, setWaitData] = useState(true);
    const [ticketSeriesVariety, setTicketSeriesVariety] = useState(null);

    useEffect(() => {
        setWaitData(false);
    }, [data]);
    const [status, setStatus] = useState('search');
    if (status === 'search') {
        return (
            <Search
                onSearch={(body, variety) => {
                    setTicketSeriesVariety(variety);
                    if (body.ticketId) {
                        setWaitData(true);
                        setStatus('results');
                        const url = `${config.backendAddress}/api/v1/ticket-series/${body.ticketId}/${variety}-tickets`;
                        setUrl(`${url}?${Object.keys(body).map((key) => {
                            return `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`;
                        }).join('&')}&time=${new Date().getTime()}`);
                    }
                }}
            />
        );
    } else {
        if(ticketSeriesVariety == 'claimable') {
            return <ClaimableResults data={!waitData ? data : null} loading={loading} refresh={refresh} onReturn={() => setStatus('search')} />;
        } else {
            return <PlayableResults data={!waitData ? data : null} loading={loading} refresh={refresh} onReturn={() => setStatus('search')} />;
        }
    }
};

export default Users;
