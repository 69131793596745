import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const TransferAccountDialog = ({ open, user, ticketSerieses, onClose, onSave }) => {
    const [{ data, loading }, setUrl] = useFetch(null);
    const [series, setSeries] = useState('');
    const [targetUserNumber, setTargetUserNumber] = useState('');
    useEffect(() => {
        if (open) {
            setTargetUserNumber('');
            setSeries('');
        }
    }, [open]);
    useEffect(() => {
        if (targetUserNumber) {
            setUrl(`${config.backendAddress}/api/v1/users?external_id=uua:${targetUserNumber}`);
        }
    }, [setUrl, targetUserNumber]);

    const targetUser = data && data.count > 0 ? data.items[0] : null;

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>Siirrä saldot</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    fetch(`${config.backendAddress}/api/v1/accounts/swap`, {
                        body: JSON.stringify({
                            ticket_series_id: series.value,
                            from_user_id: user.id,
                            to_user_id: targetUser.id,
                            external_reference: 'Saldon siirto'
                        }),
                        method: 'POST'
                    })
                        .then(() => {
                            showMessage('Saldot siirretty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='transferAccounts'>
                    <Select
                        required
                        value={series}
                        onChange={(item) => setSeries(item)}
                        options={ticketSerieses.map((option) => {
                            return { label: `${getLangValue(option.lottery_names, 'fi')} ${option.alias}`, value: option.id };
                        })}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei arpasarjoja'}
                        textFieldProps={{
                            label: 'Arpasarja',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <TextField
                        required
                        fullWidth
                        value={targetUserNumber}
                        onChange={(event) => setTargetUserNumber(event.target.value)}
                        label='Kohde käyttäjän UUA asiakasnumero'
                        id='id'
                        name='id'
                        margin='normal'
                        autoComplete='off'
                    />
                    <div style={{ height: 96 }}>
                        {loading && <CircularProgress />}
                        {targetUser && targetUser.external_ids && targetUser.external_ids.uua === targetUserNumber && (
                            <div style={{ height: 96 }}>
                                <Typography variant='body1'>{targetUser.firstname} {targetUser.lastname}</Typography>
                                <Typography variant='body1'>{targetUser.email}</Typography>
                                <Typography variant='body1'>{targetUser.phone}</Typography>
                                <Typography variant='body1'>{targetUser.address} {targetUser.postal_code} {targetUser.locality}</Typography>
                            </div>
                        )}
                    </div>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='transferAccounts' disabled={!targetUser}>
                    Siirrä
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TransferAccountDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default TransferAccountDialog;
