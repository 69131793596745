import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const AddUser = ({ onReturn }) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const renderTextField = (label, name, value, changeFunc, required = false, type = 'text', helperText) => {
        return (
            <div>
                <TextField
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    required={required}
                    margin='normal'
                    helperText={helperText}
                    onChange={(event) => changeFunc(event.target.value)}
                />
            </div>
        );
    };

    return (
        <>
            <LoadingBar in={false} />
            <Container maxWidth='sm'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Luo käyttäjä
                    </Typography>
                    <Button variant='contained' color='primary' onClick={onReturn}>Takaisin</Button>
                </Toolbar>
                <Form action={(body) => {
                    fetch(`${config.backendAddress}/api/v1/users/by-subject`, {
                        method: 'POST',
                        body: JSON.stringify({
                            account: {
                                first_name: body.firstname,
                                last_name: body.lastname,
                                email: body.email,
                                phone: body.phone,
                                address: {
                                    street_address: body.address,
                                    city: body.city,
                                    postal_code: body.postal_code
                                }
                            },
                            password: body.password
                        })
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw response.json();
                            }
                            return response;
                        })
                        .then((response) => {
                            showMessage('Käyttäjä lisätty');
                            onReturn();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}>
                    {renderTextField('Etunimi', 'firstname', firstname, setFirstname, true)}
                    {renderTextField('Sukunimi', 'lastname', lastname, setLastname, true)}
                    {renderTextField('Sähköposti', 'email', email, setEmail, false, 'text', 'Sähköpostiosoite on pakollinen, mutta jos jätät kentän tyhjäksi, generoidaan asiakkaalle satunnainen osoite.')}
                    {renderTextField('Puhelinnumero', 'phone', phone, setPhone, false, 'tel')}
                    {renderTextField('Katuosoite', 'address', address, setAddress)}
                    {renderTextField('Postinumero', 'postal_code', zip, setZip, false, 'tel')}
                    {renderTextField('Postitoimipaikka', 'city', city, setCity)}
                    {renderTextField('Salasana', 'password', password, setPassword, true, 'password')}
                    <Button variant='contained' color='primary' style={{ marginTop: '1em' }} fullWidth type='submit'>Luo käyttäjä</Button>
                </Form>
            </Container>
        </>
    );
};

export default AddUser;
