import React, { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const Redeem = ({ open, user, onReturn, refresh }) => {
    const [{ data }] = useFetch(`${config.backendAddress}/api/v1/lotteries`);
    const [{ data: products }] = useFetch(`${config.backendAddress}/api/v1/products`);
    const [lottery, setLottery] = useState('');
    const [status, setStatus] = useState('enter');
    const [prize, setPrize] = useState(null);

    const [{ data: optionsData }, setOptionsUrl] = useFetch(null);
    useEffect(() => {
        if (prize && prize.id) {
            setOptionsUrl(`${config.backendAddress}/api/v1/products/${prize.id}/options`);
        }
    }, [prize, setOptionsUrl]);
    const [firstname, setFirstname] = useState(user.firstname);
    const [lastname, setLastname] = useState(user.lastname);
    const [phone, setPhone] = useState(user.phone || '');
    const [email, setEmail] = useState(user.email || '');
    const [address, setAddress] = useState(user.address || '');
    const [zip, setZip] = useState(user.postal_code || '');
    const [city, setCity] = useState(user.locality || user.city || '');
    const [option, setOption] = useState('');
    const [loading, setLoading] = useState(false);

    const checkClaim = (body) => {
        setLoading(true);
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.value.id}/claims?admin_claim=true&subject=${user.subject}`, {
            method: 'POST',
            body: JSON.stringify({
                code: body.code
            })
        })
            .then((response) => response.json())
            .then((json) => {
                showMessage('Arpa rekisteröity');
                if (json.product_id) {
                    const product = products.find((item) => json.product_id === item.id);
                    setPrize(product);
                    if (json.product_value_transferrable) {
                        setStatus('win');
                    } else {
                        setStatus('winNotTransferrable');
                    }
                } else {
                    setStatus('noWin');
                }
                refresh();
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                setLoading(false);
            });
    };

    const updateClaim = (body) => {
        if (body.phone === '' && (body.address === '' || body.postal_code === '' || body.city === '')) {
            return;
        }
        setLoading(true);
        body.product_option_id = body.product_option_id !== undefined ? parseInt(body.product_option_id) : body.product_option_id;

        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.value.id}/claims?admin_claim=true&subject=${user.subject}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Arpamaailma-Claim-Token': Cookies.get('arpamaailma-claim-token')
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                commitClaim(body);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const commitClaim = (body) => {
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.value.id}/claims/commit?admin_claim=true&subject=${user.subject}`, {
            method: 'PUT',
            headers: {
                'Arpamaailma-Claim-Token': Cookies.get('arpamaailma-claim-token')
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                showMessage('Tuote lunastettu');
                setStatus('enter');
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                setLoading(false);
            });
    };

    const renderTextField = (label, name, value, changeFunc, required = true, type = 'text') => {
        return (
            <div>
                <TextField
                    fullWidth
                    value={value}
                    label={label}
                    id={name}
                    name={name}
                    type={type}
                    required={required}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                />
            </div>
        );
    };

    const renderSelectField = (label, name, value, changeFunc, options, required = true, type = 'text') => {
        return (
            <div>
                <TextField
                    select
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    required={required}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    };

    if (loading) {
        return <CircularProgress style={{ display: 'block', margin: '5em auto', width: 120, height: 120 }} />;
    }

    return (
        <Container maxWidth='sm'>
            <Toolbar disableGutters>
                <Typography
                    variant='h4'
                    noWrap
                    style={{ flexGrow: 1 }}
                >
                    Rekisteröi arpa
                </Typography>
            </Toolbar>
            {status === 'enter' && (
                <Form action={checkClaim}>
                    <Select
                        required
                        value={lottery}
                        onChange={(item) => setLottery(item)}
                        options={data ? data.map((option) => {
                            return { label: getLangValue(option.names, 'fi'), value: option };
                        }) : []}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei arpoja'}
                        textFieldProps={{
                            label: 'Arpa',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <TextField
                        autoFocus
                        required
                        fullWidth
                        label='Tarkistusnumero (1)'
                        id='code'
                        name='code'
                        type='tel'
                        margin='normal'
                        autoComplete='off'
                    />
                    <Button variant='contained' color='primary' fullWidth type='submit' style={{ marginTop: '1em' }}>Rekisteröi</Button>
                </Form>
            )}
            {status === 'noWin' && (
                <>
                    <Typography variant='body1'>
                        Ei voittoa. Käyttäjä on osallistunut lisäarvontaan.
                    </Typography>
                    <Form action={checkClaim}>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            label='Tarkistusnumero (1)'
                            id='code'
                            name='code'
                            type='tel'
                            margin='normal'
                            autoComplete='off'
                        />
                        <Button variant='contained' color='primary' fullWidth type='submit' style={{ marginTop: '1em' }}>Rekisteröi</Button>
                    </Form>
                </>
            )}
            {status === 'win' && (
                <>
                    <Typography variant='body1'>
                        Voitit {formatCurrency(prize.value)} € arvoisen voiton. Voitto lisätty käyttäjän saldoon.
                    </Typography>
                    <Form action={checkClaim}>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            label='Tarkistusnumero (1)'
                            id='code'
                            name='code'
                            type='tel'
                            margin='normal'
                            autoComplete='off'
                        />
                        <Button variant='contained' color='primary' fullWidth type='submit' style={{ marginTop: '1em' }}>Rekisteröi</Button>
                    </Form>
                </>
            )}
            {status === 'winNotTransferrable' && (
                <>
                    <Typography variant='body1'>
                        Voitit {getLangValue(prize.names, 'fi')}. Täytä alle voittajan yhteystiedot.
                    </Typography>
                    <Form action={updateClaim}>
                        {optionsData && optionsData.length > 0 && renderSelectField('Valinta', 'product_option_id', option, setOption, optionsData.map((item) => {
                            return { label: getLangValue(item.names, 'fi'), value: item.id };
                        }))}
                        {renderTextField('Etunimi', 'firstname', firstname, setFirstname, true)}
                        {renderTextField('Sukunimi', 'lastname', lastname, setLastname, true)}
                        {renderTextField('Sähköposti', 'email', email, setEmail, false, 'email')}
                        {renderTextField('Puhelin', 'phone', phone, setPhone, false, 'tel')}
                        {renderTextField('Osoite', 'address', address, setAddress)}
                        {renderTextField('Postinumero', 'postal_code', zip, setZip, false, 'tel')}
                        {renderTextField('Paikkakunta', 'city', city, setCity)}
                        <div>
                            <Button variant='contained' color='primary' fullWidth style={{ marginTop: '1em' }} type='submit'>Lunasta</Button>
                        </div>
                    </Form>
                </>
            )}
            {status === 'winNotClaimable' && (
                <>
                    <Typography variant='body1'>
                        Voitit {getLangValue(prize.names, 'fi')} (Arvo {formatCurrency(prize.value)} €)
                        <br />
                        Lähetä voittoarpasi osoitteeseen:
                        <br /><br />
                        Eventio Group Oy
                        <br />
                        Tierankatu 4 B
                        <br />
                        20520 Turku
                        <br />
                        Puh. 0403 110 555
                        <br />
                        info@eventio.fi
                        <br /><br />
                        Suosittelemme lähettämään kirjattuna kirjeenä.
                        <br />
                        Muista täyttää arpaan yhteystietosi.
                        <br /><br />
                    </Typography>
                    <Form action={checkClaim}>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            label='Tarkistusnumero (1)'
                            id='code'
                            name='code'
                            type='tel'
                            margin='normal'
                            autoComplete='off'
                        />
                        <Button variant='contained' color='primary' fullWidth type='submit' style={{ marginTop: '1em' }}>Rekisteröi</Button>
                    </Form>
                </>
            )}
            <Button variant='contained' color='primary' fullWidth style={{ marginTop: '1em' }} onClick={onReturn}>Takaisin profiiliin</Button>
        </Container>
    );
};

export default Redeem;
