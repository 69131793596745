import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { DatePicker } from '@material-ui/pickers';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Form } from '../common';
import { Table, LoadingBar } from '../common';
import { includesValue, getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const filterDuplicates = (list) => {
    const newList = [];
    for (const item of list) {
        if (newList.find((existing) => existing.product_id === item.product_id) == null) {
            newList.push(item);
        }
    }
    return newList;
};

const Statistics = ({ location }) => {
    const values = queryString.parse(location.search);
    const [{ data, loading, refresh }, setUrl] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/products/stats`);
    const [search, setSearch] = useState('');

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Form action={(body) => {
                    let url = `${config.backendAddress}/api/v1/lotteries/${values.lottery}/products/stats`;

                    let params = new URLSearchParams();

                    if (startTime) {
                        params.append(
                            'start_time',
                            moment(startTime).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
                        );
                    }
                    if (endTime) {
                        params.append(
                            'end_time',
                            moment(endTime).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
                        );
                    }

                    if (params.size > 0) {
                        url += `?${params.toString()}`;
                    }

                    setUrl(url);
                }}>
                    <Toolbar disableGutters>
                        <Typography
                            variant='h4'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                            Statistiikka
                        </Typography>
                        <DatePicker
                            margin='normal'
                            name='start_time'
                            label='Ensimmäinen päivä'
                            autoOk
                            format='DD.MM.YYYY'
                            value={startTime}
                            onChange={(moment) => setStartTime(moment)}
                            style={{ marginLeft: '1em', marginRight: '1em' }}
                        />
                        <DatePicker
                            margin='normal'
                            name='end_time'
                            label='Viimeinen päivä'
                            autoOk
                            format='DD.MM.YYYY'
                            value={endTime}
                            onChange={(moment) => setEndTime(moment)}
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginTop: '1em', marginRight: '3em' }}
                            type='submit'
                        >
                            Päivitä
                        </Button>

                        <TextField label='Hae' value={search} onChange={(event) => setSearch(event.target.value)} style={{ marginRight: '1em' }} />
                    </Toolbar>
                    <Table
                        idKey='product_id'
                        data={data ? filterDuplicates(data.filter((item) => includesValue(item, search)))
                              .sort((left, right) => {
                                  if(left.product_id < right.product_id) return -1;
                                  if(left.product_id > right.product_id) return 1;
                                  return 0;
                              }) : []}
                        columns={[
                            { label: 'Nimi', key: 'product_names', callback: (value) => getLangValue(value, 'fi') || 'Lisäarvonta' },
                            { label: 'Valmiita', key: 'finished' },
                            { label: 'Keskeytetty', key: 'unfinished' },
                            { label: 'Lähetetty', key: 'printed' },
                            { label: 'Tulostamatta', key: 'unprinted' }
                        ]}
                    />
                </Form>
            </Container>
        </>
    );
};

export default withRouter(Statistics);
