import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Notifier } from './common';
import 'moment/locale/fi';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';
import Callback from './Callback';
import theme from './theme';
import config from './config';

ReactDOM.render(
    <BrowserRouter basename={config.adminPathPrefix}>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='fi'>
                <CssBaseline />
                <Notifier />
                <Switch>
                    <Route path='/callback' component={Callback} />
                    <Route component={App} />
                </Switch>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
