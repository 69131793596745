import React, { useState, useEffect } from 'react';
import { useFetch } from '../common/hooks';
import config from '../config';

import Search from './Search';
import AddUser from './AddUser';
import Results from './Results';

const Users = () => {
    const [{ data, loading, refresh }, setUrl] = useFetch(null);
    const [waitData, setWaitData] = useState(true);
    useEffect(() => {
        setWaitData(false);
    }, [data]);
    const [status, setStatus] = useState('search');
    if (status === 'search') {
        return (
            <Search
                onSearch={(body) => {
                    setWaitData(true);
                    setStatus('results');
                    const url = body.partial ? `${config.backendAddress}/api/v1/users/partial` : `${config.backendAddress}/api/v1/users`;
                    setUrl(`${url}?${Object.keys(body).map((key) => {
                        if (key === 'uua') {
                            return `${encodeURIComponent('external_id')}=${encodeURIComponent(`uua:${body[key]}`)}`;
                        } else {
                            return `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`;
                        }
                    }).join('&')}&search_type=wildcard&time=${new Date().getTime()}`);
                }}
                onAddUser={() => setStatus('new')}
            />
        );
    } else if (status === 'new') {
        return <AddUser onReturn={() => setStatus('search')} />;
    } else {
        return <Results data={!waitData ? data : null} loading={loading} refresh={refresh} onReturn={() => setStatus('search')} />;
    }
};

export default Users;
