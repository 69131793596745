import { useState, useEffect, useCallback } from 'react';
import { fetchWrap as fetch } from '../functions';

const useFetch = (initialUrl, initialOptions) => {
    const [url, setUrl] = useState(initialUrl);
    const [options, setOptions] = useState(initialOptions);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refetch, setRefech] = useState(false);
    const fetchData = useCallback(() => {
        if (!url || url.length === 0) {
            return;
        }
        setLoading(true);
        fetch(url, {
            method: 'GET',
            ...options
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
                console.error(error);
            });
    }, [url, options]);
    const refresh = () => {
        setRefech(true);
    };
    useEffect(() => {
        fetchData();
    }, [url, options, fetchData]);
    useEffect(() => {
        if (refetch) {
            setRefech(false);
            fetchData();
        }
    }, [refetch, fetchData]);
    return [{ url, options, data, error, loading, refresh }, setUrl, setOptions];
};

export { useFetch };
