import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import config from '../config';

const PrizeDialog = ({ open, prize, seriesId, products, onClose, onSave }) => {
    const [product, setProduct] = useState(null);
    const [prizeId, setPrizeId] = useState('');
    const [claimable, setClaimable] = useState(true);
    const [valueTransferable, setValueTransferable] = useState(true);
    const [playableExtraWin, setPlayableExtraWin] = useState(true);
    useEffect(() => {
        if (open) {
            if (prize) {
                setPrizeId(prize.prize_id);
                setClaimable(prize.claimable);
                setProduct(prize.id ? { label: getLangValue(prize.names, 'fi'), value: prize.id } : null);
                setValueTransferable(prize.value_transferrable);
                setPlayableExtraWin(prize.playable_extra_win);
            } else {
                setPrizeId('');
                setClaimable(true);
                setProduct(null);
                setValueTransferable(true);
                setPlayableExtraWin(false);
            }
        }
    }, [open, prize]);

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>{'Voitto ' + prizeId}</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Select
                    value={product}
                    onChange={(item) => setProduct(item)}
                    options={products.map((option) => {
                        return { label: getLangValue(option.names, 'fi'), suffix: `${formatCurrency(option.value)} €`, value: option.id };
                    })}
                    placeholder='Valitse'
                    noOptionsMessage={() => 'Ei tuotteita'}
                    textFieldProps={{
                        label: 'Tuote',
                        InputLabelProps: {
                            shrink: true,
                        }
                    }}
                />
                <FormGroup style={{ margin: '1em 0' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={claimable}
                                onChange={(event) => setClaimable(event.target.checked)}
                            />}
                        label='Lunastettava'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={valueTransferable}
                                onChange={(event) => setValueTransferable(event.target.checked)}
                            />}
                        label='Arvo saldoon'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={playableExtraWin}
                                onChange={(event) => setPlayableExtraWin(event.target.checked)}
                            />}
                        label='Pelattavan arvan lisävoitto'
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Sulje
                </Button>
                <Button color='primary' disabled={!product} onClick={() => {
                    fetch(`${config.backendAddress}/api/v1/ticket-series/${seriesId}/products/${product.value}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            prize_id: prizeId,
                            claimable: claimable,
                            value_transferrable: valueTransferable,
                            playable_extra_win: playableExtraWin,
                        })
                    })
                        .then((response) => {
                            showMessage('Voitto päivitetty');
                            onSave();
                        });
                }}>
                    Tallenna
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PrizeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    prize: PropTypes.object,
    products: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default PrizeDialog;
