import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import { Form } from '../common';
import GlobalReport from './GlobalReport';
import BonusReport from './BonusReport';

const GlobalReports = () => {
    const [accountWinningCodesReport, setAccountWinningCodesReport] = useState({
        start: null,
        end: null,
        show: false,
    });

    const [bonusReport, setBonusReport] = useState({
        start: null,
        end: null,
        show: false,
    });

    useEffect(() => {
        setAccountWinningCodesReport({
            ...accountWinningCodesReport,
            start: '2000-01-01T00:00:00+00:00'.split('T')[0] + 'T23:59:59',
            end: new Date().toISOString().split('T')[0] + 'T23:59:59'
        });
        setBonusReport({
            ...bonusReport,
            start: '2000-01-01T00:00:00+00:00'.split('T')[0] + 'T23:59:59',
            end: new Date().toISOString().split('T')[0] + 'T23:59:59'
        });
    }, []);

    if (accountWinningCodesReport.show) {
        return (
            <GlobalReport
                startTime={accountWinningCodesReport.start}
                endTime={accountWinningCodesReport.end}
                onReturn={
                    () => setAccountWinningCodesReport({
                        ...accountWinningCodesReport,
                        show: false
                    })
                }
            />
        );
    }

    if (bonusReport.show) {
        return (
            <BonusReport
                startTime={bonusReport.start}
                endTime={bonusReport.end}
                onReturn={
                    () => setBonusReport({
                        ...bonusReport,
                        show: false
                    })
                }
            />
        );
    }

    return (
        <>
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Raportit
                    </Typography>
                </Toolbar>

                <Typography variant='h4'>Tilien sisältö ja voittokoodit</Typography>

                <Form action={(body) => {
                    if (accountWinningCodesReport.start && accountWinningCodesReport.end) {
                        setAccountWinningCodesReport({
                            ...accountWinningCodesReport,
                            show: true
                        });
                    }
                }}>
                    <DatePicker
                        required
                        margin='normal'
                        name='first_date'
                        label='Ensimmäinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={accountWinningCodesReport.start}
                        onChange={(moment) => setAccountWinningCodesReport({
                            ...accountWinningCodesReport,
                            'start': moment
                        })}
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                    />
                    <DatePicker
                        required
                        margin='normal'
                        name='last_date'
                        label='Viimeinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={accountWinningCodesReport.end}
                        onChange={(moment) => setAccountWinningCodesReport({
                            ...accountWinningCodesReport,
                            'end': moment
                        })}
                        style={{ marginRight: '1em', marginLeft: '1em' }}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '1em' }}
                        type='submit'
                    >
                        Hae
                    </Button>
                </Form>

                <Typography variant='h4'>Ensiosto- ja rekisteröintibonus</Typography>

                <Form action={(body) => {
                    if (bonusReport.start && bonusReport.end) {
                        setBonusReport({
                            ...bonusReport,
                            show: true
                        });
                    }
                }}>
                    <DatePicker
                        required
                        margin='normal'
                        name='first_date'
                        label='Ensimmäinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={bonusReport.start}
                        onChange={(moment) => setBonusReport({
                            ...bonusReport,
                            start: moment,
                        })}
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                    />
                    <DatePicker
                        required
                        margin='normal'
                        name='last_date'
                        label='Viimeinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={bonusReport.end}
                        onChange={(moment) => setBonusReport({
                            ...bonusReport,
                            end: moment,
                        })}
                        style={{ marginRight: '1em', marginLeft: '1em' }}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '1em' }}
                        type='submit'
                    >
                        Hae
                    </Button>
                </Form>
            </Container>
        </>
    );
};

export default GlobalReports;
