import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import config from '../config';

const ProductDialog = ({ open, title, product, tags, onClose, onSave }) => {
    const [nameFi, setNameFi] = useState('');
    const [nameSv, setNameSv] = useState('');
    const [descriptionFi, setDescriptionFi] = useState('');
    const [descriptionSv, setDescriptionSv] = useState('');
    const [productTags, setProductTags] = useState([]);
    const [value, setValue] = useState(0);
    const [smallImage, setSmallImage] = useState('');
    const [largeImage, setLargeImage] = useState('');
    const [customId, setCustomId] = useState('');
    const [deliveryMethod, setDeliveryMethod] = useState('');
    const [productCode, setProductCode] = useState('');
    const [hidden, setHidden] = useState(false);
    const [prizeCode, setPrizeCode] = useState(false);
    useEffect(() => {
        if (open) {
            if (product) {
                setNameFi(product.nameFi);
                setNameSv(product.nameSv);
                setCustomId(product.custom_id);
                setDescriptionFi(product.descriptionFi);
                setDescriptionSv(product.descriptionSv);
                setValue(product.value / 100); // convert to euros
                setSmallImage(product.smallImage);
                setLargeImage(product.largeImage);
                setProductTags(product.tags.map((tag) => {
                    return { label: getLangValue(tag.names, 'fi'), value: tag.id };
                }));
                setDeliveryMethod(product.deliveryMethod);
                setProductCode(product.productCode);
                setHidden(product.hidden);
                setPrizeCode(product.prizeCode);
            } else {
                setNameFi('');
                setNameSv('');
                setCustomId('');
                setDescriptionFi('');
                setDescriptionSv('');
                setValue(0);
                setSmallImage('');
                setLargeImage('');
                setProductTags([]);
                setDeliveryMethod('');
                setProductCode('');
                setHidden(false);
                setPrizeCode(false);
            }
        }
    }, [open, product]);

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form action={() => {
                    fetch(product ? `${config.backendAddress}/api/v1/products/${product.id}` : `${config.backendAddress}/api/v1/products`, {
                        body: JSON.stringify({
                            names: [
                                { lang: 'fi', value: nameFi },
                                { lang: 'sv', value: nameSv },
                            ],
                            descriptions: [
                                { lang: 'fi', value: descriptionFi },
                                { lang: 'sv', value: descriptionSv }
                            ],
                            images: [
                                { variety: 'small', source: smallImage },
                                { variety: 'large', source: largeImage }
                            ],
                            custom_id: customId,
                            tags: productTags.map((tag) => tag.value),
                            delivery_method: parseInt(deliveryMethod),
                            product_code: productCode,
                            hidden: hidden,
                            prize_code: prizeCode,
                            value: parseInt(value, 10) * 100 // convert to cents
                        }),
                        method: product ? 'PUT' : 'POST'
                    })
                        .then((response) => {
                            showMessage(product ? 'Tuote päivitetty' : 'Tuote lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='saveProduct'>
                    <TextField
                        required
                        autoFocus
                        margin='dense'
                        name='nameFi'
                        id='nameFi'
                        label='Suomenkielinen nimi'
                        fullWidth
                        value={nameFi}
                        onChange={(event) => setNameFi(event.target.value)}
                    />
                    <TextField
                        required
                        margin='dense'
                        name='nameSv'
                        id='nameSv'
                        label='Ruotsinkielinen nimi'
                        fullWidth
                        value={nameSv}
                        onChange={(event) => setNameSv(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='descriptionFi'
                        id='descriptionFi'
                        label='Suomenkielinen kuvaus'
                        fullWidth
                        multiline
                        rows={4}
                        value={descriptionFi}
                        onChange={(event) => setDescriptionFi(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='descriptionSv'
                        id='descriptionSv'
                        label='Ruotsinkielinen kuvaus'
                        fullWidth
                        multiline
                        rows={4}
                        value={descriptionSv}
                        onChange={(event) => setDescriptionSv(event.target.value)}
                    />
                    <Select
                        value={productTags}
                        isMulti
                        onChange={(item) => setProductTags(item)}
                        options={tags.map((option) => {
                            return { label: getLangValue(option.names, 'fi'), value: option.id };
                        })}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei kategorioita'}
                        textFieldProps={{
                            label: 'Kategoriat',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <TextField
                        required
                        margin='dense'
                        name='value'
                        id='value'
                        label='Arvo (€)'
                        type='number'
                        fullWidth
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='custom_id'
                        id='custom_id'
                        label='Custom ID'
                        fullWidth
                        value={customId}
                        onChange={(event) => setCustomId(event.target.value)}
                    />
                    <TextField
                        required
                        type='url'
                        margin='dense'
                        name='smallImage'
                        id='smallImage'
                        label='Pieni kuva (URL)'
                        fullWidth
                        value={smallImage}
                        onChange={(event) => setSmallImage(event.target.value)}
                    />
                    <TextField
                        required
                        type='url'
                        margin='dense'
                        name='largeImage'
                        id='largeImage'
                        label='Suuri kuva (URL)'
                        fullWidth
                        value={largeImage}
                        onChange={(event) => setLargeImage(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='productCode'
                        id='productCode'
                        label='Tuotekoodi'
                        fullWidth
                        value={productCode}
                        onChange={(event) => setProductCode(event.target.value)}
                    />
                    <TextField
                        required
                        margin='dense'
                        name='deliveryMethod'
                        id='deliveryMethod'
                        label='Toimitustapa'
                        type='number'
                        fullWidth
                        value={deliveryMethod}
                        onChange={(event) => setDeliveryMethod(event.target.value)}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='hidden'
                                    id='hidden'
                                    checked={hidden}
                                    onChange={(event) => setHidden(event.target.checked)}
                                />}
                            label='Piilotettu' />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='prizeCode'
                                    id='prizeCode'
                                    checked={prizeCode}
                                    onChange={(event) => setPrizeCode(event.target.checked)}
                                />}
                            label='Sähköinen lahjakortti' />
                    </FormGroup>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='saveProduct'>
                    {product ? 'Päivitä' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ProductDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ProductDialog;
