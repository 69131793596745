import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4999dd'
        },
        secondary: {
            main: '#8e5adf'
        }
    },
    props: {
        MuiContainer: {
            maxWidth: false
        }
    },
    overrides: {
        MuiContainer: {
            root: {
                paddingTop: defaultTheme.spacing(3),
                paddingBottom: defaultTheme.spacing(3)
            }
        }
    }
});

export default theme;
