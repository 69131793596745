import React, { useState } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker } from '@material-ui/pickers';
import { Form, LoadingBar } from '../common';

const cleanObject = (obj) => {
    for (const key in obj) {
        if (!obj[key]) {
            delete obj[key];
        }
    }
    return obj;
};

const Search = ({ onSearch, onAddUser }) => {
    const [service, setService] = useState('elamys');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [uua, setUUA] = useState('');
    const [subject, setSubject] = useState('');
    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [partial, setPartial] = useState(false);
    /*const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');*/

    const renderTextField = (label, name, value, changeFunc, type = 'text') => {
        return (
            <div>
                <TextField
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                />
            </div>
        );
    };

    const renderSelectField = (label, name, value, changeFunc, options, type = 'text') => {
        return (
            <div>
                <TextField
                    select
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    onChange={(event) => changeFunc(event.target.value)}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    };

    const renderDateField = (label, name, value, changeFunc, options, type = 'text') => {
        return (
            <div>
                <DatePicker
                    fullWidth
                    label={label}
                    id={name}
                    name={name}
                    value={value}
                    type={type}
                    margin='normal'
                    autoOk
                    clearable
                    clearLabel='Tyhjennä'
                    cancelLabel='Peruuta'
                    format='DD.MM.YYYY'
                    onChange={(moment) => changeFunc(moment)}
                />
            </div>
        );
    };

    return (
        <>
            <LoadingBar in={false} />
            <Container maxWidth='sm'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Hae käyttäjiä
                    </Typography>
                </Toolbar>
                <Form action={(body) => onSearch(cleanObject({
                    service,
                    firstname,
                    lastname,
                    email,
                    uua,
                    subject,
                    created_at_min: firstDate && moment(firstDate).hour(0).minute(0).seconds(0).toISOString(),
                    created_at_max: lastDate && moment(lastDate).hour(23).minute(59).seconds(59).toISOString(),
                    limit: 250,
                    partial
                }))}>
                    <Typography variant='body1'>
                        Jätä kentät, joiden perusteella et halua etsiä, tyhjiksi.
                    </Typography>
                    {renderSelectField('Palvelu', 'service', service, setService, [
                        { label: 'Elämys', value: 'elamys' },
                        { label: 'Arpamaailma', value: 'arpamaailma' }
                    ])}
                    {renderTextField('Etunimi', 'firstname', firstname, setFirstname)}
                    {renderTextField('Sukunimi', 'lastname', lastname, setLastname)}
                    {renderTextField('Sähköposti', 'email', email, setEmail, 'email')}
                    {renderTextField('UUA asiakasnumero', 'uua', uua, setUUA, 'tel')}
                    {renderTextField('Eventio-tilin ID (subject)', 'subject', subject, setSubject, 'tel')}
                    {renderDateField('Ensimmäinen luontipäivä', 'created_at_min', firstDate, setFirstDate)}
                    {renderDateField('Viimeinen luontipäivä', 'created_at_max', lastDate, setLastDate)}
                    <FormControlLabel
                        control={
                            <Checkbox
                                id='partial'
                                name='partial'
                                value={partial}
                                checked={partial}
                                onChange={(event) => setPartial(event.target.checked)}
                            />
                        }
                        label='Näytä osittaiset käyttäjät'
                    />
                    <Button variant='contained' color='primary' style={{ marginTop: '1em' }} fullWidth type='submit'>Etsi</Button>
                    <Button variant='contained' color='primary' style={{ marginTop: '1em' }} fullWidth onClick={onAddUser}>Luo uusi käyttäjä</Button>
                </Form>
            </Container>
        </>
    );
};

export default Search;
