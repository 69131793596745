import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import fetch from '../common/functions/fetchWrap';
import config from '../config';
import { Form, Select } from '../common';

let TAGS = [
    {label: 'Nuorelle Siivet', value: 'internal.nuorelle-siivet.lottery'},
    {label: 'Unelma-Arpa', value: 'internal.unelma-arpa.lottery'},
];

const LotteryWithdraw = () => {
    const [targetId, setTargetId] = useState('');
    const [value, setValue] = useState(0);
    const [externalReference, setExternalReference] = useState('');
    const [tag, setTag] = useState(TAGS[0]);

    console.log(tag);

    return (
        <>
            <Container
                maxWidth='sm'
            >
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Poista arvan saldoa
                    </Typography>
                </Toolbar>

                <Form
                    action={
                        () => {
                            fetch(
                                `${config.backendAddress}/api/v1/lottery-withdraw`,
                                {
                                    method: 'PUT',
                                    body: JSON.stringify({
                                        target_id: targetId,
                                        value: parseInt(value, 10),
                                        external_reference: externalReference,
                                        tag: tag.value
                                    }),

                                }
                            )
                                .then((response) => {
                                    alert(`Nostettu onnistuneesti ${value} eurosenttiä`);
                                    setTargetId('');
                                    setValue(0);
                                    setExternalReference('');
                                    setTag(TAGS[0]);
                                });

                        }
                    }
                >
                    <div>
                        <TextField
                            fullWidth
                            label='Käyttäjän ID'
                            type='number'
                            required
                            value={targetId}
                            onChange={(event) => setTargetId(event.target.value)}
                        />
                    </div>

                    <div>
                        <TextField
                            fullWidth
                            label='Summa (eurosenttiä)'
                            type='number'
                            required
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        />
                    </div>
                    <div>
                        <Select
                            required
                            value={tag}
                            onChange={(item) => {
                                console.log(item); setTag(item); }}
                            options={TAGS}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            label='Viite'
                            value={externalReference}
                            onChange={(event) => setExternalReference(event.target.value)}
                        />
                    </div>
                    <div>
                        <Button
                            color='primary'
                            fullWidth
                            style={{ marginTop: '1em' }}
                            type='submit'
                            variant='contained'
                        >
                            Poista
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    );
};

export default LotteryWithdraw;
