import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const CategoryDialog = ({ open, title, category, onClose, onSave }) => {
    const [nameFi, setNameFi] = useState('');
    const [nameSv, setNameSv] = useState('');
    const [hidden, setHidden] = useState(false);
    useEffect(() => {
        if (open) {
            if (category) {
                setNameFi(category.nameFi);
                setNameSv(category.nameSv);
                setHidden(category.hidden);
            } else {
                setNameFi('');
                setNameSv('');
                setHidden(false);
            }
        }
    }, [open, category]);

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form action={() => {
                    fetch(category ? `${config.backendAddress}/api/v1/tags/${category.id}` : `${config.backendAddress}/api/v1/tags`, {
                        body: JSON.stringify({
                            names: [
                                { lang: 'fi', value: nameFi },
                                { lang: 'sv', value: nameSv },
                            ],
                            hidden: hidden
                        }),
                        method: category ? 'PUT' : 'POST'
                    })
                        .then((response) => {
                            showMessage(category ? 'Kategoria päivitetty' : 'Kategoria lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='saveCategory'>
                    <TextField
                        required
                        autoFocus
                        margin='dense'
                        name='nameFi'
                        id='nameFi'
                        label='Suomenkielinen nimi'
                        fullWidth
                        value={nameFi}
                        onChange={(event) => setNameFi(event.target.value)}
                    />
                    <TextField
                        required
                        margin='dense'
                        name='nameSv'
                        id='nameSv'
                        label='Ruotsinkielinen nimi'
                        fullWidth
                        value={nameSv}
                        onChange={(event) => setNameSv(event.target.value)}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='hidden'
                                    id='hidden'
                                    checked={hidden}
                                    onChange={(event) => setHidden(event.target.checked)}
                                />}
                            label='Piilotettu' />
                    </FormGroup>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='saveCategory'>
                    {category ? 'Päivitä' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CategoryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CategoryDialog;
