import React, { useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Table, LoadingBar } from '../common';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import i18n from '../i18n';
import config from '../config';
import User from '../users/User';

const Results = ({ data, loading, onReturn, refresh, url, setUrl }) => {

    const [user, setUser] = useState(null);

    if (user) {
        return (
            <User
                user={user}
                onReturn={() => setUser(null)}
            />
        );
    }

    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tilaukset
                    </Typography>
                    {data && data.total > 100 && (
                        <Typography
                            variant='body1'
                            noWrap
                            style={{ flexGrow: 1 }}
                        >
                            Tuloksia löytyi yli 100. Kokeile tarkempia hakuehtoja.
                        </Typography>
                    )}
                    <Button variant='contained' color='primary' onClick={onReturn}>Haku</Button>
                </Toolbar>
                <Table
                    idKey='id'
                    data={data ? data.items : []}
                    externalSort
                    onSort={(orderBy, order) => {
                        const [baseUrl, params] = url.split('?');
                        const values = queryString.parse(params);
                        values.sort_by = order === 'asc' ? `-${orderBy}` : orderBy;
                        setUrl(`${baseUrl}?${queryString.stringify(values)}`);
                    }}
                    columns={[
                        { label: 'Tilausnumero', key: 'id' },
                        { label: 'Tuotteet', key: 'products', disableSort: true, callback: (value) => value.map((product) => product.product_id ? `${getLangValue(product.product_names, 'fi')}${product.product_option_id ? `, ${getLangValue(product.product_option_names, 'fi')}` : ''} (${product.count})` : '').join(', ') },
                        { label: 'Etunimi', key: 'firstname' },
                        { label: 'Sukunimi', key: 'lastname' },
                        { label: 'Tila', key: 'state', disableSort: true, callback: (value) => i18n.gettext(value) },
                        { label: 'Aika', key: 'created_at', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' }
                    ]}
                    rowActions={(item) => (
                        <Button variant='contained' color='primary' onClick={() => {
                            fetch(`${config.backendAddress}/api/v1/users/${item.user_id}?service=all`, {
                                method: 'GET'
                            })
                                .then((response) => response.json())
                                .then((json) => {
                                    setUser(json);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }}>Käyttäjä</Button>
                    )}
                />
            </Container>
        </>
    );
};

export default Results;
