import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingBar } from '../common';
import { fetchWrap as fetch, formatCurrency, getLangValue, openBlob, stableSort } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const download = (name, url) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            openBlob(blob, 'application/pdf', name);
        })
        .catch((error) => {
            console.error(error);
        });
};

const Report = ({ startTime, endTime, ticketSeries, onReturn }) => {
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/reports/transactions?ticket_series_id=${ticketSeries.id}&start_time=${moment(startTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&end_time=${moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss')}`);
    const [bonusReferences, setBonusReferences] = useState([]);
    const [bonuses, setBonuses] = useState([]);
    const [userStats, setUserStats] = useState([]);
    const [playableStats, setPlayableStats] = useState([]);
    useEffect(() => {
        if (data) {
            const newBonuses = [];
            for (const bonus of data.bonus_deposit_stats) {
                const existingBonus = newBonuses.find((item) => item.value === bonus.bonus_value);
                if (existingBonus) {
                    existingBonus.total_count += bonus.count;
                    existingBonus.total_value += bonus.bonus_total_value;
                    existingBonus.external_references[bonus.external_reference] = bonus.count;
                } else {
                    const newBonus = {
                        value: bonus.bonus_value,
                        total_count: bonus.count,
                        total_value: bonus.bonus_total_value,
                        external_references: {}
                    };
                    newBonus.external_references[bonus.external_reference] = bonus.count;
                    newBonuses.push(newBonus);
                }
            }
            setBonuses(newBonuses);
            setBonusReferences(data.bonus_deposit_stats.reduce((references, bonus) =>
                !references.includes(bonus.external_reference) ? references.concat([bonus.external_reference]) : references , []));

            const newUserStats = [];
            for (const user of data.claims_by_user_stats) {
                newUserStats.push({
                    id: user.user_id,
                    name: user.user_name,
                    winning_claims: user.winning_count,
                    unwinning_claims: user.unwinning_count,
                    bonuses: 0,
                    bonuses_value: 0,
                    gifts: 0
                });
            }
            for (const user of data.bonus_by_user_stats) {
                if (user.user_id) {
                    const userStats = newUserStats.find((item) => item.id === user.user_id);
                    if (userStats) {
                        userStats.bonuses = user.count;
                        userStats.bonuses_value = user.total_value;
                    } else {
                        newUserStats.push({
                            id: user.user_id,
                            name: user.user_name,
                            winning_claims: 0,
                            unwinning_claims: 0,
                            bonuses: user.count,
                            bonuses_value: user.total_value,
                            gifts: 0
                        });
                    }
                }
            }
            for (const user of data.gifts_by_user_stats) {
                if (user.user_id) {
                    const userStats = newUserStats.find((item) => item.id === user.user_id);
                    if (userStats) {
                        userStats.gifts = user.count;
                    } else {
                        newUserStats.push({
                            id: user.user_id,
                            name: user.user_name,
                            winning_claims: 0,
                            unwinning_claims: 0,
                            bonuses: 0,
                            bonuses_value: 0,
                            gifts: user.count
                        });
                    }
                }
            }
            setUserStats(newUserStats);
            if (data.playable_stats) {
                const temp = data.playable_stats[0].purchases;
                const stats = {
                    totalValue: temp.total_value,
                    totalNumber: temp.total_number,
                    money: temp.money,
                    bonus: temp.bonus,
                    extraWin: temp.extra_win,
                    extraWinForeign: temp.extra_win_foreign,
                };
                setPlayableStats(stats);
            }
        }
    }, [data]);
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                {data ? (
                    <>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h4'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Arpasarja {data.ticket_series_alias} ({moment(data.start_time).format('DD.MM.YYYY')} - {moment(data.end_time).format('DD.MM.YYYY')})
                            </Typography>
                            <Button variant='contained' color='primary' onClick={onReturn}>
                                Takaisin
                            </Button>
                            <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => download(
                                `Arpasarja ${data.ticket_series_alias} (${moment(data.start_time).format('DD.MM.YYYY')} - ${moment(data.end_time).format('DD.MM.YYYY')}).pdf`,
                                `${config.backendAddress}/api/v1/reports/transactions?ticket_series_id=${ticketSeries.id}&start_time=${moment(startTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&end_time=${moment(endTime).utc().format('YYYY-MM-DDTHH:mm:ss')}&format=pdf`
                            )}>Lataa PDF</Button>
                        </Toolbar>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Transaktioiden summat
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>Voittotilit</TableCell>
                                        <TableCell align='right'>Bonustilit</TableCell>
                                        <TableCell align='right'>Yhteensä</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell variant='head'>Alkusaldo</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.claims_account.start_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.bonus_account.start_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.claims_account.start_balance + data.bonus_account.start_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Voitot</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.win_transfer_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.bonus_transfer_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.win_transfer_balance + data.bonus_transfer_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Lisävoitot</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.extra_win_transfer_balance)} €</TableCell>
                                        <TableCell align='right'>0 €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.extra_win_transfer_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Lunastukset (voitot, lisävoitot)</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_withdrawal_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.bonus_withdrawal_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_withdrawal_balance + data.bonus_withdrawal_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Siirrot</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_account_swap_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.bonus_account_swap_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_account_swap_balance + data.bonus_account_swap_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Siirrot</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.wins_account_swap_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.bonus_account_swap_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.wins_account_swap_balance + data.bonus_account_swap_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Erääntyneet</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_invalidations_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.bonus_invalidations_balance)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.wins_invalidations_balance + data.bonus_invalidations_balance)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Hyvitykset</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.refunds)} €</TableCell>
                                        <TableCell align='right'>{0} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.refunds)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Korjaukset</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.corrections)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.demo_corrections)} €</TableCell>
                                        <TableCell align='right'>-{formatCurrency(data.corrections + data.demo_corrections)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant='head'>Loppusaldo</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.claims_account.end_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.bonus_account.end_balance)} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.claims_account.end_balance + data.bonus_account.end_balance)} €</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Rekisteröinnit voittoluokittain
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Voiton nimi</TableCell>
                                        <TableCell align='right'>Arvo</TableCell>
                                        <TableCell align='right'>Määrä</TableCell>
                                        <TableCell align='right'>Arvo yht.</TableCell>
                                        <TableCell align='right'>Siirretty voittotileille</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(data.product_stats, 'prize_id', 'asc').map((product, index) => (
                                        <TableRow key={product.prize_id}>
                                            <TableCell>{product.prize_id}.</TableCell>
                                            <TableCell>{getLangValue(product.product_names, 'fi')}</TableCell>
                                            <TableCell align='right'>{formatCurrency(product.product_value)} €</TableCell>
                                            <TableCell align='right'>{product.claims_created}</TableCell>
                                            <TableCell align='right'>{formatCurrency(product.product_total_value)} €</TableCell>
                                            <TableCell align='right'>{formatCurrency(product.value_transfers)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell />
                                        <TableCell align='right'>{data.product_stats.reduce((total, product) => total + product.claims_created, 0)}</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.product_stats.reduce((total, product) => total + product.product_total_value, 0))} €</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.product_stats.reduce((total, product) => total + product.value_transfers, 0))} €</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Annetut bonukset
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Arvo</TableCell>
                                        <TableCell align='right'>Määrä</TableCell>
                                        <TableCell align='right'>Arvo yht.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bonuses.map((bonus, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{formatCurrency(bonus.value)} €</TableCell>
                                            <TableCell align='right'>{bonus.total_count}</TableCell>
                                            <TableCell align='right'>{formatCurrency(bonus.total_value)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell align='right'>{bonuses.reduce((total, bonus) => total + bonus.total_count, 0)}</TableCell>
                                        <TableCell align='right'>{formatCurrency(bonuses.reduce((total, bonus) => total + bonus.total_value, 0))} €</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Annetut bonukset syykoodeittain
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Arvo</TableCell>
                                        {bonusReferences.map((reference) => (
                                            <TableCell key={reference} align='right'>{reference}</TableCell>
                                        ))}
                                        <TableCell align='right'>YHTEENSÄ</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bonuses.map((bonus, index) => (
                                        <TableRow key={bonus.value}>
                                            <TableCell>{formatCurrency(bonus.value)} €</TableCell>
                                            {bonusReferences.map((reference) => (
                                                <TableCell key={reference} align='right'>{bonus.external_references[reference] || 0}</TableCell>
                                            ))}
                                            <TableCell align='right'>{bonus.total_count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        {bonusReferences.map((reference) => (
                                            <TableCell key={reference} align='right'>{bonuses.reduce((total, bonus) => total + (bonus.external_references[reference] || 0), 0)}</TableCell>
                                        ))}
                                        <TableCell align='right'>{bonuses.reduce((total, bonus) => total + bonus.total_count, 0)}</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Lahjat
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lahjan nimi</TableCell>
                                        <TableCell align='right'>Arvo</TableCell>
                                        <TableCell align='right'>Määrä</TableCell>
                                        <TableCell align='right'>Arvo yht.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.gift_stats.map((gift) => (
                                        <TableRow key={gift.id}>
                                            <TableCell>{getLangValue(gift.product_names, 'fi')}</TableCell>
                                            <TableCell align='right'>{formatCurrency(gift.product_value)} €</TableCell>
                                            <TableCell align='right'>{gift.count}</TableCell>
                                            <TableCell align='right'>{formatCurrency(gift.total_value)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell />
                                        <TableCell align='right'>{data.gift_stats.reduce((total, gift) => total + gift.count, 0)}</TableCell>
                                        <TableCell align='right'>{formatCurrency(data.gift_stats.reduce((total, gift) => total + gift.total_value, 0))} €</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Pelattavat arvat
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell align='right'>Myydyt arvat (kpl)</TableCell>
                                        <TableCell align='right'>Kokonaismyynti</TableCell>
                                        <TableCell align='right'>Ostot rahalla</TableCell>
                                        <TableCell align='right'>Ostot pelibonuksella</TableCell>
                                        <TableCell align='right'>Ostot lisävoitoilla</TableCell>
                                        <TableCell align='right'>Ostot toisen arvan lisävoitoilla</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell align='right'> {playableStats.totalNumber} kpl</TableCell>
                                        <TableCell align='right'> {formatCurrency(playableStats.totalValue)} €</TableCell>
                                        <TableCell align='right'> {formatCurrency(playableStats.money)} €</TableCell>
                                        <TableCell align='right'> {formatCurrency(playableStats.bonus)} €</TableCell>
                                        <TableCell align='right'> {formatCurrency(playableStats.extraWin)} €</TableCell>
                                        <TableCell align='right'> {formatCurrency(playableStats.extraWinForeign)} €</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Arpojen rekisteröinnit ja bonukset
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Käyttäjä</TableCell>
                                        <TableCell align='right'>Voittavat arvat (kpl)</TableCell>
                                        <TableCell align='right'>Ei voittavat arvat (kpl)</TableCell>
                                        <TableCell align='right'>Lahjat (kpl)</TableCell>
                                        <TableCell align='right'>Bonukset (kpl)</TableCell>
                                        <TableCell align='right'>Bonukset (€)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userStats.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell align='right'>{user.winning_claims}</TableCell>
                                            <TableCell align='right'>{user.unwinning_claims}</TableCell>
                                            <TableCell align='right'>{user.gifts}</TableCell>
                                            <TableCell align='right'>{user.bonuses}</TableCell>
                                            <TableCell align='right'>{formatCurrency(user.bonuses_value)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell align='right'>{userStats.reduce((total, user) => total + user.winning_claims, 0)}</TableCell>
                                        <TableCell align='right'>{userStats.reduce((total, user) => total + user.unwinning_claims, 0)}</TableCell>
                                        <TableCell align='right'>{userStats.reduce((total, user) => total + user.gifts, 0)}</TableCell>
                                        <TableCell align='right'>{userStats.reduce((total, user) => total + user.bonuses, 0)}</TableCell>
                                        <TableCell align='right'>{formatCurrency(userStats.reduce((total, user) => total + user.bonuses_value, 0))} €</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                        <Toolbar disableGutters>
                            <Typography
                                variant='h6'
                                noWrap
                                style={{ flexGrow: 1 }}
                            >
                                Lehtivoitot
                            </Typography>
                        </Toolbar>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lehti</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell align='right'>Määrä</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.ua_magazine_stats.map((magazine) => (
                                        <TableRow key={magazine.product_option_id}>
                                            <TableCell>{getLangValue(magazine.product_option_names, 'fi')}</TableCell>
                                            <TableCell>{magazine.product_option_id}</TableCell>
                                            <TableCell align='right'>{magazine.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead component='tfoot'>
                                    <TableRow>
                                        <TableCell>YHTEENSÄ</TableCell>
                                        <TableCell />
                                        <TableCell align='right'>{data.ua_magazine_stats.reduce((total, magazine) => total + magazine.count, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>KESKEYTETYT</TableCell>
                                        <TableCell />
                                        <TableCell align='right'>{data.ua_magazine_unclaimed_count}</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper>
                    </>
                ) : <div />}
            </Container>
        </>
    );
};

export default Report;
