import React,{ useState } from 'react';
import { LoadingBar } from '../common';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ImportGameCurrencyDialog from './ImportGameCurrencyDialog';

const ImportGameCurrency = () => {
    const [importDialogOpen, setImportDialogOpen] = useState(false);

    return(
        <>
            <LoadingBar in={false} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Anna pelibonusta:
                    </Typography>
                </Toolbar>
                <Toolbar disableGutters>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setImportDialogOpen(true)}
                        style={{ marginRight: '1em' }}>
                            CSV tiedostosta usealle käyttäjälle
                    </Button>
                </Toolbar>
            </Container>
            <ImportGameCurrencyDialog
                onSave={() => setImportDialogOpen(false)}
                onClose={() => {
                    setImportDialogOpen(false);
                }}
                open={importDialogOpen}
                title={'Anna pelibonusta usealle käyttäjälle CSV tiedostosta'}/>
        </>
    );
};

export default ImportGameCurrency;
