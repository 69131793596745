import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/HelpOutline';

const styles = (theme) => ({
});

const HelpPanel = ({ themes, ...props }) => {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary>
                <HelpIcon/>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography
                    component={'span'}
                    variant='body1'
                >
                    {props.children}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default withStyles(styles, { withTheme: true })(HelpPanel);
