import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DateTimePicker } from '@material-ui/pickers';
import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const SeriesDialog = ({ open, series, onClose, onSave }) => {
    const [seriesId, setSeriesId] = useState();
    const [alias, setAlias] = useState('');
    const [validityStart, setValidityStart] = useState('');
    const [validityEnd, setValidityEnd] = useState('');
    const [userAccountValidityEnd, setUserAccountValidityEnd] = useState(null);
    const [extraLotteryValidityEnd, setExtraLotteryValidityEnd] = useState(null);
    const [playableTicketPrice, setPlayableTicketPrice] = useState(null);
    useEffect(() => {
        if (open) {
            if (series) {
                setSeriesId(series.id);
                setAlias(series.alias || '');
                setValidityStart(series.validity_start || new Date().toISOString());
                setValidityEnd(series.validity_end || new Date().toISOString());
                setUserAccountValidityEnd(series.user_account_validity_end || null);
                setExtraLotteryValidityEnd(series.extra_lottery_validity_end || null);
                setPlayableTicketPrice(series.playable_ticket_price ? series.playable_ticket_price / 100 : null);
            } else {
                setAlias('');
                setValidityStart(new Date().toISOString());
                setValidityEnd(new Date().toISOString());
                setUserAccountValidityEnd(null);
                setExtraLotteryValidityEnd(null);
                setPlayableTicketPrice(null);
            }
        }
    }, [open, series]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Arpasarja (ID:{seriesId})</DialogTitle>
            <DialogContent>
                <Form action={() => {
                    const body = {
                        alias: alias,
                        validity_start: validityStart,
                        validity_end: validityEnd,
                    };
                    if (userAccountValidityEnd) {
                        body.user_account_validity_end = userAccountValidityEnd;
                    }
                    if (extraLotteryValidityEnd) {
                        body.extra_lottery_validity_end = extraLotteryValidityEnd;
                    }
                    if (series.playable) {
                        body.playable_ticket_price = playableTicketPrice * 100;
                    }
                    fetch(`${config.backendAddress}/api/v1/ticket-series/${series.id}`, {
                        body: JSON.stringify(body),
                        method: 'PATCH'
                    })
                        .then(() => {
                            showMessage('Arpasarja päivitetty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='saveSeries'>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        name='alias'
                        id='alias'
                        label='Arpakausi'
                        fullWidth
                        value={alias}
                        onChange={(event) => setAlias(event.target.value)}
                    />
                    <DateTimePicker
                        required
                        margin='dense'
                        name='validity_start'
                        id='validity_start'
                        label='Voimassaolo alkaa (GMT)'
                        fullWidth
                        autoOk
                        ampm={false}
                        format='DD.MM.YYYY HH:mm'
                        value={validityStart}
                        onChange={(moment) => setValidityStart(moment._d.toISOString())}
                    />
                    <DateTimePicker
                        required
                        margin='dense'
                        name='validity_end'
                        id='validity_end'
                        label='Viimeinen voimassaolo (GMT)'
                        fullWidth
                        autoOk
                        ampm={false}
                        format='DD.MM.YYYY HH:mm'
                        value={validityEnd}
                        onChange={(moment) => setValidityEnd(moment._d.toISOString())}
                    />
                    <DateTimePicker
                        margin='dense'
                        name='user_account_validity_end'
                        id='user_account_validity_end'
                        label='Viimeinen voimassaolo tilillä (GMT)'
                        fullWidth
                        autoOk
                        ampm={false}
                        format='DD.MM.YYYY HH:mm'
                        value={userAccountValidityEnd}
                        onChange={(moment) => setUserAccountValidityEnd(moment._d.toISOString())}
                    />
                    <DateTimePicker
                        margin='dense'
                        name='extra_lottery_validity_end'
                        id='extra_lottery_validity_end'
                        label='Viimeinen lisäarvontaan osallistumispäivä (GMT)'
                        fullWidth
                        autoOk
                        ampm={false}
                        format='DD.MM.YYYY HH:mm'
                        value={extraLotteryValidityEnd}
                        onChange={(moment) => setExtraLotteryValidityEnd(moment._d.toISOString())}
                    />
                    <TextField
                        required={series && (series.playable ? true : false)}
                        margin='dense'
                        name='playable_ticket_price'
                        id='playableTicketPrice'
                        label='Pelattavan arvan hinta (€)'
                        fullWidth
                        value={playableTicketPrice}
                        onChange={(event) => setPlayableTicketPrice(event.target.value)}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='saveSeries'>
                    Päivitä
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SeriesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default SeriesDialog;
