import React from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, LoadingBar } from '../common';
import config from '../config';

const Record = ({ lottery, onRedeem }) => {
    return (
        <>
            <LoadingBar in={false} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tee pikalunastus
                    </Typography>
                </Toolbar>
                <Typography variant='body1'>
                    Kirjoita tähän tarkistusnumero (1) ja varmista tämän jälkeen että voitto on oikea.
                </Typography>
                <Form action={`${config.backendAddress}/api/v1/lotteries/${lottery}/claims?admin_claim=true`} onResponse={onRedeem}>
                    <div>
                        <TextField
                            autoFocus
                            required
                            label='Tarkistusnumero'
                            id='code'
                            name='code'
                            type='tel'
                            margin='normal'
                            autoComplete='off'
                            style={{ width: 250 }}
                        />
                    </div>
                    <Button variant='contained' color='primary' type='submit' style={{ marginTop: '1em' }}>Jatka</Button>
                </Form>
            </Container>
        </>
    );
};

export default Record;
