import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Table, LoadingBar, ConfirmDialog, showMessage } from '../common';
import { fetchWrap as fetch, includesValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import SeriesDialog from './SeriesDialog';
import SeriesUploadDialog from './SeriesUploadDialog';
import config from '../config';

import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';

let interval;

const Series = ({ location }) => {
    const values = queryString.parse(location.search);
    const [{ data, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/ticket-series`);
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [seriesDialogOpen, setSeriesDialogOpen] = useState(false);
    const [seriesUploadDialogOpen, setSeriesUploadDialogOpen] = useState(false);
    const [invalidateSeriesDialogOpen, setInvalidateSeriesDialogOpen] = useState(false);
    const [search, setSearch] = useState('');
    useEffect(() => {
        interval = setInterval(() => refresh(), 10000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Arpasarjat
                    </Typography>
                    <TextField label='Hae' value={search} onChange={(event) => setSearch(event.target.value)} style={{ marginRight: '1em' }} />
                    <Button variant='contained' color='primary' onClick={() => setSeriesUploadDialogOpen(true)}>
                        Lisää arpasarja
                    </Button>
                </Toolbar>
                <Table
                    idKey='id'
                    data={data ? data.filter((item) => item.count).filter((item) => includesValue(item, search)) : []}
                    columns={[
                        { label: 'Arpakausi', key: 'alias' },
                        { label: 'Voimassaolo alkaa', key: 'validity_start', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' },
                        { label: 'Viimeinen voimassaolo', key: 'validity_end', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' },
                        { label: 'Viimeinen voimassaolo tilillä', key: 'user_account_validity_end', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm'): '' },
                        { label: 'Viimeinen lisäarvontaan osallistumispäivä', key: 'extra_lottery_validity_end', callback: (value) => value ? moment.utc(value).local().format('DD.MM.YYYY HH:mm') : '' },
                        { label: 'Arpojen määrä', key: 'count' },
                        { label: 'Symbolien määrä', key: 'symbol_count' },
                        {
                            label: 'Pelattava arpa',
                            key: 'playable',
                            callback: (value) => value ? <DoneIcon /> : null
                        },
                    ]}
                    rowActions={(item) => {
                        return (
                            <>
                                <Tooltip title='Muokkaa'>
                                    <IconButton onClick={() => {
                                        setSelectedSeries(item);
                                        setSeriesDialogOpen(true);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Nollaa'>
                                    <IconButton onClick={() => {
                                        setSelectedSeries(item);
                                        setInvalidateSeriesDialogOpen(true);
                                    }}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }}
                />
            </Container>
            <SeriesUploadDialog
                open={seriesUploadDialogOpen}
                lottery={values.lottery}
                onSave={() => {
                    setSeriesUploadDialogOpen(false);
                    refresh();
                }}
                onClose={() => setSeriesUploadDialogOpen(false)}
            />
            <SeriesDialog
                series={selectedSeries}
                open={seriesDialogOpen}
                onSave={() => {
                    setSeriesDialogOpen(false);
                    refresh();
                }}
                onClose={() => setSeriesDialogOpen(false)}
            />
            <ConfirmDialog
                open={invalidateSeriesDialogOpen}
                title='Nollaa arpasarja'
                text={`Haluatko varmasti aloittaa arpasarjan "${selectedSeries ? selectedSeries.alias : ''}" nollauksen?`}
                confirmText='Nollaa'
                onCancel={() => setInvalidateSeriesDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/ticket-series/${selectedSeries.id}/accounts/invalidate-wins`, {
                        method: 'POST'
                    })
                        .then((response) => {
                            showMessage('Nollaus aloitettu');
                            setInvalidateSeriesDialogOpen(false);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
            />
        </>
    );
};

export default withRouter(Series);
