import React, { useState, useEffect } from 'react';
import { useFetch } from '../common/hooks';
import config from '../config';

import Search from './Search';
import Results from './Results';

const Users = () => {
    const [{ data, loading, refresh, url }, setUrl] = useFetch(null);
    const [waitData, setWaitData] = useState(true);
    useEffect(() => {
        setWaitData(false);
    }, [data]);
    const [status, setStatus] = useState('search');
    if (status === 'search') {
        return (
            <Search
                onSearch={(body) => {
                    setWaitData(true);
                    setStatus('results');
                    setUrl(`${config.backendAddress}/api/v1/orders?${Object.keys(body).map((key) => {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`;
                    }).join('&')}&search_type=wildcard`);
                }}
            />
        );
    } else {
        return (
            <Results
                data={!waitData ? data : null}
                loading={loading}
                refresh={refresh}
                onReturn={() => setStatus('search')}
                url={url}
                setUrl={setUrl}
            />
        );
    }
};

export default Users;
