import React from 'react';
import PropTypes from 'prop-types';
import { fetchWrap as fetch } from './functions';

const Form = ({ action, method, onResponse, onError, children, ...props }) => {
    const submit = (event) => {
        const body = {};
        for (const pair of new FormData(event.target)) {
            body[pair[0]] = pair[1];
        }
        if (typeof action == 'function') {
            action(body);
        } else {
            fetch(action, {
                body: JSON.stringify(body),
                method: method || 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    if (onResponse) {
                        onResponse(json);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    if (onError) {
                        onError(error);
                    }
                });
        }
        event.preventDefault();
    };

    return (
        <form {...props} onSubmit={submit}>
            {children}
        </form>
    );
};

Form.propTypes = {
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    method: PropTypes.string,
    onResponse: PropTypes.func,
    onError: PropTypes.func
};

export default Form;
