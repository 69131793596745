import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const AddProductDialog = ({ open, title, lottery, lotteryProducts, onClose, onSave }) => {
    const [{ data }] = useFetch(`${config.backendAddress}/api/v1/products`);
    const [product, setProduct] = useState(null);
    useEffect(() => {
        if (open) {
            setProduct(null);
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    fetch(`${config.backendAddress}/api/v1/lotteries/${lottery}/products/${product.value}`, {
                        method: 'PUT'
                    })
                        .then((response) => {
                            showMessage('Tuote lisätty');
                            onSave();
                        });
                }} id='addLotteryProduct'>
                    <Select
                        value={product}
                        onChange={(item) => setProduct(item)}
                        options={data ? data.filter((option) => lotteryProducts.findIndex((item) => item.id === option.id) === -1).map((option) => {
                            return { label: getLangValue(option.names, 'fi'), suffix: `${formatCurrency(option.value)} €`, value: option.id };
                        }) : []}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei tuotteita'}
                        textFieldProps={{
                            label: 'Tuote',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='addLotteryProduct'>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddProductDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddProductDialog;
