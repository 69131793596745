import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

const ImportPrizeCodesDialog = ({ open, onClose, onSave }) => {
    const [ file, setFile ] = useState();

    useEffect(() => {
        console.log(file);
    },[file]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Tuo sähköisten lahjakorttien koodeja</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    const body = new FormData();
                    body.append('code_file', file);

                    fetch(`${config.backendAddress}/api/v1/prize-code/import`, {
                        method: 'POST',
                        body: body
                    })
                        .then((response) => {
                            showMessage('Lahjakorttikoodien lisäys aloitettu');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                id='import-prize-codes'>
                    <Toolbar disableGutters>
                        <TextField
                            fullWidth
                            required
                            label='Tiedosto'
                            value={file ? file.name : ''}
                            style={{ marginRight: '1em' }}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <input
                            type='file'
                            accept='.csv'
                            style={{ display: 'none' }}
                            id='prize-codes-upload'
                            onChange={(event) => {
                                if (event.target.files.length > 0) {
                                    setFile(event.target.files[0]);
                                }
                            }}
                        />
                        <label htmlFor='prize-codes-upload'>
                            <Button variant='contained' color='primary' component='span'>
                                Valitse
                            </Button>
                        </label>
                    </Toolbar>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='import-prize-codes'>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ImportPrizeCodesDialog;
