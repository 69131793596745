import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { showMessage } from '../common';
import config from '../config';
import { fetchWrap as fetch, getImageValue, getLangValue } from '../common/functions';

const ProductOptionDialog = ({ open, option, product, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [customId, setCustomId] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [defaultOption, setDefaultOption] = useState(false);
    useEffect(() => {
        if (open) {
            setName(option ? getLangValue(option.names, 'fi') : '');
            setCustomId(option ? option.custom_id : '');
            setImageUrl(option ? getImageValue(option.images, 'small'): '');
            setDefaultOption(option ? option.default : false);
        }
    }, [open, option]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{option ? 'Muokkaa optiota' : 'Lisää optio'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    name='name'
                    id='name'
                    label='Nimi'
                    fullWidth
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
                <TextField
                    margin='dense'
                    name='customId'
                    id='customId'
                    label='Custom ID'
                    fullWidth
                    value={customId}
                    onChange={(event) => setCustomId(event.target.value)}
                />
                <TextField
                    margin='dense'
                    name='imageUrl'
                    id='imageUrl'
                    label='Kuvan URL'
                    fullWidth
                    value={imageUrl}
                    onChange={(event) => setImageUrl(event.target.value)}
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='quick_claim_enabled'
                                id='quick_claim_enabled'
                                checked={defaultOption}
                                onChange={(event) => setDefaultOption(event.target.checked)}
                            />}
                        label='Oletus' />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' disabled={!name || name.length === 0} onClick={() => {
                    fetch(option ? `${config.backendAddress}/api/v1/product-options/${option.id}` : `${config.backendAddress}/api/v1/products/${product.id}/options`, {
                        body: JSON.stringify({
                            names: [
                                { lang: 'fi', value: name }
                            ],
                            custom_id: customId,
                            images: [{
                                variety: 'small',
                                source: imageUrl
                            }],
                            default: defaultOption
                        }),
                        method: option ? 'PUT' : 'POST'
                    })
                        .then((response) => {
                            showMessage(option ? 'Optio päivitetty' : 'Optio lisätty');
                            onSave();
                        });
                }}>
                    {option ? 'Tallenna' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ProductOptionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    option: PropTypes.object,
    product: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default ProductOptionDialog;
