import React from 'react';

import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const Misc = () => {
    return (
        <button
            onClick={() => {
                fetch(
                    `${config.backendAddress}/api/v1/shop/products`,
                    {method: 'POST'}
                );
            }}
        >Laske MyCashflow-tuotejärjestys</button>
    );
};

export default Misc;
