import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, showMessage } from '../common';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const MultiDialog = ({ open, title, onClose, onSave }) => {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    return(
        <Dialog open={open} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <DialogContentText>CSV tiedoston fomaatti:</DialogContentText>
                <DialogContentText style={{ fontFamily: 'Courier' }}>UUA_asnro;subjectID;annettava_summa</DialogContentText>
                <Form action={() => {
                    const body = new FormData();
                    body.append('bonuses', file);
                    body.append('variety', 'game_currency');
                    body.append('external_reference', description);

                    fetch(`${config.backendAddress}/api/v1/accounts/bonus`, {
                        method: 'POST',
                        body: body
                    })
                        .then((response) => {
                            showMessage('Pelirahojen lisäys käynnistetty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                id='import-game-currency'>
                    <Toolbar disableGutters>
                        <TextField
                            fullWidth
                            required
                            label='Syy'
                            value={description}
                            style={{ marginRight: '1em' }}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </Toolbar>
                    <Toolbar disableGutters>
                        <TextField
                            fullWidth
                            required
                            label='Tiedosto'
                            value={file ? file.name : ''}
                            style={{ marginRight: '1em' }}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <input
                            type='file'
                            accept='.csv'
                            style={{ display: 'none' }}
                            id='game-currency-upload'
                            onChange={(event) => {
                                if (event.target.files.length > 0) {
                                    setFile(event.target.files[0]);
                                }
                            }}
                        />
                        <label htmlFor='game-currency-upload'>
                            <Button variant='contained' color='primary' component='span'>
                            Valitse
                            </Button>
                        </label>
                    </Toolbar>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                Peruuta
                </Button>
                <Button color='primary' type='submit' form='import-game-currency'>
                Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ImportGameCurrencyDialog = ({ open, title, onClose, onSave }) => {
    // Done this way to allow single user functions in the future
    return(
        <>
            <MultiDialog open={open} title={title} onClose={onClose} onSave={onSave}/>
        </>
    );
};

ImportGameCurrencyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ImportGameCurrencyDialog;
