import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

const VARIETY_DEPOSIT_BONUS = 'deposit_bonus_one_off';
const VARIETY_REGISTRATION_BONUS = 'registration_bonus';

const BonusSettings = () => {
    const [changed, setChanged] = useState(false);

    const [depositBonusEnabled, setDepositBonusEnabled] = useState(false);
    const [depositBonusValue, setDepositBonusValue] = useState(0);

    const [registrationBonusEnabled, setRegistrationBonusEnabled] = useState(false);
    const [registrationBonusValue, setRegistrationBonusValue] = useState(0);
    const [registrationBonusName, setRegistrationBonusName] = useState('');

    const [{ data: bonusSettings, loading: settingsLoading, refresh }] =
          useFetch(`${config.backendAddress}/api/v1/bonus/settings`);

    const isChanged = () => {
        if(bonusSettings) {
            let depositBonusMap = bonusSettings['bonus_settings']
                .find((item) => item.variety == VARIETY_DEPOSIT_BONUS);
            let registrationBonusMap = bonusSettings['bonus_settings']
                .find((item) => item.variety == VARIETY_REGISTRATION_BONUS);

            if(depositBonusEnabled != (depositBonusMap?.enabled || false) ||
               (depositBonusValue * 100) != (depositBonusMap?.value || 0) ||
               registrationBonusEnabled != (registrationBonusMap?.enabled || false) ||
               (registrationBonusValue * 100) != (registrationBonusMap?.value || 0) ||
               registrationBonusName != (registrationBonusMap?.name || '')
              ) {
                setChanged(true);
            } else {
                setChanged(false);
            }
        }
    };

    useEffect(() => {
        if(bonusSettings) {
            let depositBonusMap = bonusSettings['bonus_settings']
                .find((item) => item.variety == VARIETY_DEPOSIT_BONUS);
            let registrationBonusMap = bonusSettings['bonus_settings']
                .find((item) => item.variety == VARIETY_REGISTRATION_BONUS);

            setDepositBonusEnabled(depositBonusMap?.enabled || false);
            setDepositBonusValue((depositBonusMap?.value || 0) / 100);

            setRegistrationBonusEnabled(registrationBonusMap?.enabled || false);
            setRegistrationBonusValue((registrationBonusMap?.value || 0) / 100);
            setRegistrationBonusName(registrationBonusMap?.name || '');
        }
    }, [
        bonusSettings,

        setDepositBonusEnabled,
        setDepositBonusValue,

        setRegistrationBonusEnabled,
        setRegistrationBonusValue,
        setRegistrationBonusName,
    ]);

    useEffect(() => {
        isChanged();
    }, [
        depositBonusEnabled,
        depositBonusValue,
        registrationBonusEnabled,
        registrationBonusValue,
        registrationBonusName,
    ]);

    return (
        <Container>
            <Typography
                component='h2'
                variant='h4'
            >
                Bonusasetukset
            </Typography>

            <Form
                action={() => {
                    let _bonusSettings = [];

                    _bonusSettings.push({
                        variety: VARIETY_DEPOSIT_BONUS,
                        enabled: depositBonusEnabled,
                        value: depositBonusValue * 100,
                    });

                    _bonusSettings.push({
                        variety: VARIETY_REGISTRATION_BONUS,
                        enabled: registrationBonusEnabled,
                        value: registrationBonusValue * 100,
                        name: registrationBonusName,
                    });

                    fetch(
                        `${config.backendAddress}/api/v1/bonus/settings`,
                        {
                            body: JSON.stringify({
                                'bonus_settings': _bonusSettings,
                            }),
                            method: 'PUT',
                        },
                    )
                        .then((response) => {
                            showMessage('Tallennettu');
                            setChanged(false);
                            refresh();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Ensitalletusbonus</Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => setDepositBonusEnabled(event.target.checked)}
                                    checked={depositBonusEnabled}
                                />
                            }
                            label='Käytössä'
                            labelPlacement='bottom'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            onChange={(event) => setDepositBonusValue(event.target.value)}
                            value={depositBonusValue}
                            label='Euroa'
                            type='number'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Rekisteröintibonus</Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => setRegistrationBonusEnabled(event.target.checked)}
                                    checked={registrationBonusEnabled}
                                />
                            }
                            label='Käytössä'
                            labelPlacement='bottom'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label='Euroa'
                            onChange={(event) => setRegistrationBonusValue(event.target.value)}
                            value={registrationBonusValue}
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label='Kampanjan nimi'
                            onChange={(event) => setRegistrationBonusName(event.target.value)}
                            value={registrationBonusName}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={!changed}
                            type='submit'
                        >
                            Tallenna
                        </Button>
                    </Grid>
                </Grid>

            </Form>
        </Container>
    );
};

export default BonusSettings;
