import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Table, ConfirmDialog, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, getImageValue, includesValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import LotteryDialog from './LotteryDialog';
import config from '../config';

const Lotteries = ({ onSelectLottery }) => {
    const [{ data, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/lotteries`);
    const [selectedLottery, setSelectedLottery] = useState(null);
    const [lotteryDialogOpen, setLotteryDialogOpen] = useState(false);
    const [deleteLotteryDialogOpen, setDeleteLotteryDialogOpen] = useState(false);
    const [search, setSearch] = useState('');
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Arvat
                    </Typography>
                    <TextField label='Hae' value={search} onChange={(event) => setSearch(event.target.value)} style={{ marginRight: '1em' }} />
                    <Button variant='contained' color='primary' onClick={() => {
                        setSelectedLottery(null);
                        setLotteryDialogOpen(true);
                    }}>Lisää arpa</Button>
                </Toolbar>
                <Table
                    idKey='id'
                    data={data ? data.map((item) => {
                        return {
                            id: item.id,
                            slug: item.slug,
                            hidden: item.hidden,
                            showBalance: item.show_balance,
                            nameFi: getLangValue(item.names, 'fi'),
                            nameSv: getLangValue(item.names, 'sv'),
                            descriptionFi: getLangValue(item.descriptions, 'fi'),
                            descriptionSv: getLangValue(item.descriptions, 'sv'),
                            smallImage: getImageValue(item.images, 'small'),
                            largeImage: getImageValue(item.images, 'large'),
                            quickClaimEnabled: item.quick_claim_enabled,
                            playableTicketPurchaseVariety: item.playable_ticket_purchase_variety,
                            playableTicketBundleSize: item.playable_ticket_bundle_size,
                            playableTicketDiscount: item.playable_ticket_discount,
                            extraLotteryEnabled: item.extra_lottery_enabled,
                            index: item.index,
                        };
                    }).filter((item) => includesValue(item, search)) : []}
                    columns={[
                        { label: 'Nimi', key: 'nameFi' },
                        { label: 'Slug', key: 'slug' },
                        { label: 'Pikalunastus', key: 'quickClaimEnabled', callback: (value) => value ? 'Käytössä' : 'Ei käytössä' },
                        { label: 'Lisäarvonta', key: 'extraLotteryEnabled', callback: (value) => value ? 'Käytössä' : 'Ei käytössä' },
                        { label: 'Piilotettu', key: 'hidden', callback: (value) => value ? 'Kyllä' : 'Ei' },
                        { label: 'Näytä voittosaldo arpajaisen sivulla ', key: 'showBalance', callback: (value) => value ? 'Kyllä' : 'Ei' }
                    ]}
                    rowActions={(item) => {
                        return (
                            <>
                                <Button variant='contained' color='primary' onClick={() => onSelectLottery(item)}>Hallinta</Button>
                                <Button variant='contained' color='primary' onClick={() => {
                                    setSelectedLottery(item);
                                    setLotteryDialogOpen(true);
                                }} style={{ margin: '0 1em' }}>Muokkaa</Button>
                                <Button variant='contained' color='primary' onClick={() => {
                                    setSelectedLottery(item);
                                    setDeleteLotteryDialogOpen(true);
                                }}>Poista</Button>
                            </>
                        );
                    }}
                />
            </Container>
            <LotteryDialog
                title={selectedLottery ? 'Muokkaa arpaa' : 'Lisää arpa'}
                lottery={selectedLottery}
                open={lotteryDialogOpen}
                onSave={() => {
                    setLotteryDialogOpen(false);
                    refresh();
                }}
                onClose={() => setLotteryDialogOpen(false)}
            />
            <ConfirmDialog
                open={deleteLotteryDialogOpen}
                title='Poista arpa'
                text={`Haluatko varmasti poistaa arvan "${selectedLottery ? selectedLottery.nameFi : ''}"?`}
                confirmText='Poista'
                onCancel={() => setDeleteLotteryDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/lotteries/${selectedLottery.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            setDeleteLotteryDialogOpen(false);
                            showMessage('Arpa poistettu');
                            refresh();
                        })
                        .catch((error) => {
                            setDeleteLotteryDialogOpen(false);
                        });
                }}
            />
        </>
    );
};

export default Lotteries;
