import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import { Form } from '../common';
import { LoadingBar } from '../common';
import { useFetch } from '../common/hooks';
import config from '../config';
import Report from './Report';

const Reports = ({ location }) => {
    const values = queryString.parse(location.search);
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/ticket-series`);
    const [showReport, setShowReport] = useState(false);
    const [ticketSeries, setTicketSeries] = useState('');
    useEffect(() => {
        if (ticketSeries) {
            setStartTime(ticketSeries.validity_start.split('T')[0] + 'T00:00:00');
            setEndTime(new Date().toISOString().split('T')[0] + 'T23:59:59');
        }
    }, [ticketSeries]);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    if (showReport) {
        return (
            <Report
                ticketSeries={ticketSeries}
                startTime={startTime}
                endTime={endTime}
                onReturn={() => setShowReport(false)}
            />
        );
    }
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Raportit
                    </Typography>
                </Toolbar>
                <Form action={(body) => {
                    if (ticketSeries && startTime && endTime) {
                        setShowReport(true);
                    }
                }}>
                    <TextField
                        required
                        select
                        label='Arpakausi'
                        value={ticketSeries}
                        margin='normal'
                        onChange={(event) => setTicketSeries(event.target.value)}
                        style={{ minWidth: 200 }}
                    >
                        {data ? data.map((option) => (
                            <MenuItem key={option.id} value={option}>
                                {option.alias}
                            </MenuItem>
                        )) : []}
                    </TextField>
                    <DatePicker
                        required
                        margin='normal'
                        name='first_date'
                        label='Ensimmäinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={startTime}
                        onChange={(moment) => setStartTime(moment)}
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                    />
                    <DatePicker
                        required
                        margin='normal'
                        name='last_date'
                        label='Viimeinen päivä'
                        autoOk
                        format='DD.MM.YYYY'
                        value={endTime}
                        onChange={(moment) => setEndTime(moment)}
                        style={{ marginRight: '1em', marginLeft: '1em' }}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '1em' }}
                        type='submit'
                    >
                        Luo raportti
                    </Button>
                </Form>
            </Container>
        </>
    );
};

export default Reports;
