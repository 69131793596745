import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, stableSort } from '../common/functions';
import config from '../config';

const RemoveShopCurrencyDialog = ({ open, user, ticketSerieses, onClose, onSave }) => {
    const [series, setSeries] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState(0);
    useEffect(() => {
        if (open) {
            setDescription('');
            setAmount(0);
            setSeries('');
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
            <DialogTitle>Poista ostobonusta käyttäjältä</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form action={() => {
                    fetch(`${config.backendAddress}/api/v1/users/${user.id}/accounts/correction/bonus`, {
                        body: JSON.stringify({
                            variety: 'shop_currency',
                            ticket_series_id: series.value,
                            value: parseInt(amount) * 100,
                            external_reference: description
                        }),
                        method: 'POST'
                    })
                        .then(() => {
                            showMessage('Ostobonusta poistettu');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='removeShopCurrency'>
                    <Select
                        required
                        value={series}
                        onChange={(item) => setSeries(item)}
                        options={stableSort(ticketSerieses, 'validity_end', 'desc').map((option) => {
                            return { label: `${getLangValue(option.lottery_names, 'fi')} ${option.alias}`, value: option.id };
                        })}
                        placeholder='Valitse'
                        noOptionsMessage={() => 'Ei arpasarjoja'}
                        textFieldProps={{
                            label: 'Arpasarja',
                            InputLabelProps: {
                                shrink: true,
                            }
                        }}
                    />
                    <TextField
                        required
                        fullWidth
                        type='number'
                        label='Määrä (€)'
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label='Syy'
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='removeShopCurrency'>
                    Poista
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RemoveShopCurrencyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default RemoveShopCurrencyDialog;
