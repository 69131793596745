import React, { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Select, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';
import { ConfirmDialog } from '../common';
import config from '../config';

const Invalidate = ({ open, user, onReturn, refresh }) => {
    const [{ data }] = useFetch(`${config.backendAddress}/api/v1/lotteries`);
    const [lottery, setLottery] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [code, setCode] = useState('');

    const invalidateTicket = () => {
        setLoading(true);
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.value.id}/ticket/invalidate?subject=${user.subject}`, {
            method: 'POST',
            body: JSON.stringify({
                code: code
            })
        })
            .then(() => {
                showMessage('Arpa mitätöity');
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setCode('');
                setConfirmDialogOpen(false);
            });
    };

    if (loading) {
        return <CircularProgress style={{ display: 'block', margin: '5em auto', width: 120, height: 120 }} />;
    }

    return (
        <Container maxWidth='sm'>
            <Toolbar disableGutters>
                <Typography
                    variant='h4'
                    noWrap
                    style={{ flexGrow: 1 }}
                >
                    Mitätöi arpa
                </Typography>
            </Toolbar>

            <Select
                required
                value={lottery}
                onChange={(item) => setLottery(item)}
                options={data ? data.map((option) => {
                    return { label: getLangValue(option.names, 'fi'), value: option };
                }) : []}
                placeholder='Valitse'
                noOptionsMessage={() => 'Ei arpoja'}
                textFieldProps={{
                    label: 'Arpa',
                    InputLabelProps: {
                        shrink: true,
                    }
                }}
            />
            <TextField
                autoFocus
                required
                fullWidth
                label='Tarkistusnumero (1)'
                id='code'
                value={code}
                onChange={(event) => setCode(event.target.value)}
                type='tel'
                margin='normal'
                autoComplete='off'
            />

            <Button
                variant='contained'
                color='primary'
                fullWidth
                type='submit'
                style={{ marginTop: '1em' }}
                disabled={lottery === '' || code === ''}
                onClick={() => setConfirmDialogOpen(true)}
            >
                Mitätöi
            </Button>

            <Button
                variant='contained'
                color='primary'
                fullWidth
                style={{ marginTop: '1em' }}
                onClick={onReturn}
            >
                Takaisin profiiliin
            </Button>

            <ConfirmDialog
                open={confirmDialogOpen}
                title='Mitätöi arpa'
                text={`Haluatko varmasti mitätöidä arvan tarkastusnumerolla ${code}?`}
                confirmText='Poista'
                onCancel={() => setConfirmDialogOpen(false)}
                onConfirm={() => invalidateTicket()}
            />
        </Container>
    );
};

export default Invalidate;
