import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Table, ConfirmDialog, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, getImageValue, formatCurrency, includesValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import ProductDialog from './ProductDialog';
import ProductOptionsDialog from './ProductOptionsDialog';
import config from '../config';

import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const Products = () => {
    const [{ data, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/products`);
    const [{ data: tags, loading: tagsLoading }] = useFetch(`${config.backendAddress}/api/v1/tags`);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productDialogOpen, setProductDialogOpen] = useState(false);
    const [productOptionsDialogOpen, setProductOptionsDialogOpen] = useState(false);
    const [deleteProductDialogOpen, setDeleteProductDialogOpen] = useState(false);
    const [search, setSearch] = useState('');
    const mappedData = data ? data.map((item) => {
        return {
            id: item.id,
            custom_id: item.custom_id,
            nameFi: getLangValue(item.names, 'fi'),
            nameSv: getLangValue(item.names, 'sv'),
            descriptionFi: getLangValue(item.descriptions, 'fi'),
            descriptionSv: getLangValue(item.descriptions, 'sv'),
            smallImage: getImageValue(item.images, 'small'),
            largeImage: getImageValue(item.images, 'large'),
            value: item.value,
            tags: item.tags,
            deliveryMethod: item.delivery_method,
            productCode: item.product_code,
            hidden: item.hidden,
            prizeCode: item.prize_code
        };
    }).filter((item) => includesValue(item, search)) : [];
    return (
        <>
            <LoadingBar in={loading || tagsLoading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tuotteet
                    </Typography>
                    <TextField label='Hae' value={search} onChange={(event) => setSearch(event.target.value)} style={{ marginRight: '1em' }} />
                    <Button variant='contained' color='primary' onClick={() => {
                        setSelectedProduct(null);
                        setProductDialogOpen(true);
                    }}>Lisää tuote</Button>
                </Toolbar>
                <Table
                    idKey='id'
                    data={mappedData}
                    columns={[
                        { label: 'Nimi', key: 'nameFi' },
                        { label: 'Arvo', key: 'value', callback: (value) => `${formatCurrency(value)} €` },
                        { label: 'Kategoriat', key: 'tags', callback: (value) => value.map((tag) => getLangValue(tag.names, 'fi')).join(', ') },
                        { label: 'Custom ID', key: 'custom_id' },
                        { label: 'Toimitustapa', key: 'deliveryMethod' },
                        { label: 'Tuotekoodi', key: 'productCode' },
                        { label: 'Piilotettu', key: 'hidden', callback: (value) => value ? 'Kyllä' : 'Ei' },
                        { label: 'Sähköinen lahjakortti', key: 'prizeCode', callback: (value) => value ? 'Kyllä' : 'Ei' }
                    ]}
                    rowActions={(item) => {
                        return (
                            <>
                                <Tooltip title='Optiot'>
                                    <IconButton onClick={() => {
                                        setSelectedProduct(item);
                                        setProductOptionsDialogOpen(true);
                                    }}>
                                        <ListIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Muokkaa'>
                                    <IconButton onClick={() => {
                                        setSelectedProduct(item);
                                        setProductDialogOpen(true);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Poista'>
                                    <IconButton onClick={() => {
                                        setSelectedProduct(item);
                                        setDeleteProductDialogOpen(true);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }}
                />
            </Container>
            <ProductDialog
                title={selectedProduct ? 'Muokkaa tuotetta' : 'Lisää tuote'}
                product={selectedProduct}
                tags={tags ? tags.items : []}
                open={productDialogOpen}
                onSave={() => {
                    setProductDialogOpen(false);
                    refresh();
                }}
                onClose={() => setProductDialogOpen(false)}
            />
            <ProductOptionsDialog
                title={selectedProduct ? `${selectedProduct.nameFi} optiot` : ''}
                product={selectedProduct}
                open={productOptionsDialogOpen}
                onClose={() => setProductOptionsDialogOpen(false)}
            />
            <ConfirmDialog
                open={deleteProductDialogOpen}
                title='Poista tuote'
                text={`Haluatko varmasti poistaa tuotteen "${selectedProduct ? selectedProduct.nameFi : ''}"?`}
                confirmText='Poista'
                onCancel={() => setDeleteProductDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/products/${selectedProduct.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            setDeleteProductDialogOpen(false);
                            showMessage('Tuote poistettu');
                            refresh();
                        })
                        .catch((error) => {
                            setDeleteProductDialogOpen(false);
                        });
                }}
            />
        </>
    );
};

export default Products;
