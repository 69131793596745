const config = {
    backendAddress: process.env.REACT_APP_ARPAMAAILMA_BACKEND_ADDR || window.location.origin,

    adminPathPrefix: '',

    mycashflowUri: window.location.origin === 'https://admin.arpamaailma.fi' ?
        'https://arpamaailma.mycashflow.fi' : 'https://voittokauppa.mycashflow.fi',

    oauth2_settings: {
        authority: process.env.REACT_APP_AUTH_ADDR ||
            (window.location.origin === 'https://admin.arpamaailma.fi' ?
                'https://auth.eventio.fi/' : 'https://auth.dev.eventio.fi/'),

        client_id: process.env.REACT_APP_AUTH_CLIENT_ID || 'admin.arpamaailma.fi',

        redirect_uri: `${window.location.origin}/callback`,
        post_logout_redirect_uri: `${window.location.origin}/`,
        response_type: 'code',
        scope: 'openid profile email phone address offline',
        loadUserInfo: true
    }
};

export default config;
