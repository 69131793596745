import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const NewPasswordDialog = ({ open, user, newPassword, onClose }) => {
    const [phone, setPhone] = useState('');
    useEffect(() => {
        if (open) {
            setPhone(user.phone);
        }
    }, [open, user]);
    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Uusi salasana</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Asiakkaan {user && user.name} salasana on vaihdettu.
                        <br />
                        Uusi salasana on <span style={{ fontWeight: 'bold' }}>{newPassword}</span>
                        <br /><br />
                        Tarvittaessa voit lähettää salasanan asiakkaalle tekstiviestinä.
                    </DialogContentText>
                    <Form action={() => {
                        fetch(`${config.backendAddress}/api/v1/sms`, {
                            method: 'POST',
                            body: JSON.stringify({
                                phone: phone,
                                message: `Elämys salasanasi on muutettu. Uusi salasanasi on ${newPassword}`
                            })
                        })
                            .then((response) => {
                                showMessage('Tekstiviesti lähetetty');
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }}>
                        <Toolbar disableGutters>
                            <TextField
                                autoFocus
                                type='tel'
                                margin='dense'
                                name='phone'
                                id='phone'
                                label='Puhelinnumero'
                                value={phone}
                                required
                                onChange={(event) => setPhone(event.target.value)}
                                style={{ flexGrow: 1, marginRight: '1em' }}
                            />
                            <Button variant='contained' color='primary' type='submit'>Lähetä salasana</Button>
                        </Toolbar>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={onClose}>
                        Sulje
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

NewPasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default NewPasswordDialog;
