import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';

import { Form, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const PlayableTicketPurchaseVariety = {
    0: 'Vain yksittäisostoja',
    1: 'Nippuosto kerran kuukaudessa ensin',
    2: 'Suosi nippuostoa ensin',
};

const LotteryDialog = ({ open, title, lottery, onClose, onSave }) => {
    const [nameFi, setNameFi] = useState('');
    const [nameSv, setNameSv] = useState('');
    const [descriptionFi, setDescriptionFi] = useState('');
    const [descriptionSv, setDescriptionSv] = useState('');
    const [smallImage, setSmallImage] = useState('');
    const [largeImage, setLargeImage] = useState('');
    const [quickClaimEnabled, setQuickClaimEnabled] = useState(false);
    const [extraLotteryEnabled, setExtraLotteryEnabled] = useState(false);
    const [playableTicketPurchaseVariety, setPlayableTicketPurchaseVariety] = useState(0);
    const [playableTicketBundleSize, setPlayableTicketBundleSize] = useState(10);
    const [playableTicketDiscount, setPlayableTicketDiscount] = useState(0);
    const [hidden, setHidden] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    const [sortIndex, setSortIndex] = useState('');

    useEffect(() => {
        if (open) {
            if (lottery) {
                setNameFi(lottery.nameFi);
                setNameSv(lottery.nameSv);
                setDescriptionFi(lottery.descriptionFi);
                setDescriptionSv(lottery.descriptionSv);
                setSmallImage(lottery.smallImage);
                setLargeImage(lottery.largeImage);
                setQuickClaimEnabled(lottery.quickClaimEnabled);
                setExtraLotteryEnabled(lottery.extraLotteryEnabled);
                setPlayableTicketPurchaseVariety(lottery.playableTicketPurchaseVariety);
                setPlayableTicketBundleSize(lottery.playableTicketBundleSize);
                setPlayableTicketDiscount(lottery.playableTicketDiscount);
                setHidden(lottery.hidden);
                setShowBalance(lottery.showBalance);
                setSortIndex(lottery.index);
            } else {
                setNameFi('');
                setNameSv('');
                setDescriptionFi('');
                setDescriptionSv('');
                setSmallImage('');
                setLargeImage('');
                setQuickClaimEnabled(false);
                setExtraLotteryEnabled(false);
                setPlayableTicketPurchaseVariety(0);
                setPlayableTicketBundleSize(10);
                setPlayableTicketDiscount(0);
                setHidden(false);
                setShowBalance(false);
                setSortIndex(null);
            }
        }
    }, [open, lottery]);

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form action={() => {
                    fetch(lottery ? `${config.backendAddress}/api/v1/lotteries/${lottery.id}` : `${config.backendAddress}/api/v1/lotteries`, {
                        body: JSON.stringify({
                            names: [
                                { lang: 'fi', value: nameFi },
                                { lang: 'sv', value: nameSv }
                            ],
                            descriptions: [
                                { lang: 'fi', value: descriptionFi },
                                { lang: 'sv', value: descriptionSv }
                            ],
                            images: [
                                { variety: 'small', source: smallImage },
                                { variety: 'large', source: largeImage }
                            ],
                            quick_claim_enabled: quickClaimEnabled,
                            extra_lottery_enabled: extraLotteryEnabled,
                            playable_ticket_purchase_variety: parseInt(playableTicketPurchaseVariety, 10),
                            playable_ticket_bundle_size: parseInt(playableTicketBundleSize, 10),
                            playable_ticket_discount: parseInt(playableTicketDiscount, 10),
                            hidden: hidden,
                            show_balance: showBalance,
                            index: sortIndex ? parseInt(sortIndex) : null,
                        }),
                        method: lottery ? 'PATCH' : 'POST'
                    })
                        .then((response) => {
                            showMessage(lottery ? 'Arpa päivitetty' : 'Arpa lisätty');
                            onSave();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }} id='saveLottery'>
                    <TextField
                        required
                        autoFocus
                        margin='dense'
                        name='nameFi'
                        id='nameFi'
                        label='Suomenkielinen nimi'
                        fullWidth
                        value={nameFi}
                        onChange={(event) => setNameFi(event.target.value)}
                    />
                    <TextField
                        required
                        margin='dense'
                        name='nameSv'
                        id='nameSv'
                        label='Ruotsinkielinen nimi'
                        fullWidth
                        value={nameSv}
                        onChange={(event) => setNameSv(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='descriptionFi'
                        id='descriptionFi'
                        label='Suomenkielinen kuvaus'
                        fullWidth
                        multiline
                        rows={4}
                        value={descriptionFi}
                        onChange={(event) => setDescriptionFi(event.target.value)}
                    />
                    <TextField
                        margin='dense'
                        name='description'
                        id='description'
                        label='Ruotsinkielinen kuvaus'
                        fullWidth
                        multiline
                        rows={4}
                        value={descriptionSv}
                        onChange={(event) => setDescriptionSv(event.target.value)}
                    />

                    <TextField
                        name='sortIndex'
                        id='sortIndex'
                        label='Indeksi'
                        value={sortIndex}
                        onChange={(event) => setSortIndex(event.target.value)}
                    />


                    <TextField
                        required
                        type='url'
                        margin='dense'
                        name='smallImage'
                        id='smallImage'
                        label='Pieni kuva (URL)'
                        fullWidth
                        value={smallImage}
                        onChange={(event) => setSmallImage(event.target.value)}
                    />
                    <TextField
                        required
                        type='url'
                        margin='dense'
                        name='largeImage'
                        id='largeImage'
                        label='Suuri kuva (URL)'
                        fullWidth
                        value={largeImage}
                        onChange={(event) => setLargeImage(event.target.value)}
                    />

                    <TextField
                        select
                        fullWidth
                        required
                        value={playableTicketPurchaseVariety}
                        label='Pelattavan arvan ostotyyppi'
                        onChange={
                            (event) => {
                                setPlayableTicketPurchaseVariety(event.target.value);
                                if(event.target.value == 0) {
                                    setPlayableTicketBundleSize(1);
                                } else if (event.target.value == 1 || event.target.value == 2) {
                                    setPlayableTicketBundleSize(
                                        lottery.playableTicketBundleSize
                                    );
                                }
                            }}
                    >
                        {
                            Object.keys(PlayableTicketPurchaseVariety).map((key) => {
                                return <MenuItem
                                           key={key}
                                           value={key}
                                       >
                                           {PlayableTicketPurchaseVariety[key]}
                                       </MenuItem>;
                            })
                        }
                    </TextField>

                    {(playableTicketPurchaseVariety == 1 || playableTicketPurchaseVariety == 2) && (
                        <TextField
                            name='playableTicketBundleSize'
                            id='playableTicketBundleSize'
                            value={playableTicketBundleSize}
                            label='Nipun koko'
                            fullWidth
                            required
                            onChange={
                                (event) =>
                                    setPlayableTicketBundleSize(event.target.value)
                            }
                        >
                        </TextField>
                    )}

                    <TextField
                        name='playableTicketDiscount'
                        id='playableTicketDiscount'
                        value={playableTicketDiscount}
                        label='Alennus ensiostosta (eurosenteissä)'
                        fullWidth
                        required
                        onChange={
                            (event) =>
                            setPlayableTicketDiscount(event.target.value)
                        }
                    >
                    </TextField>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='quick_claim_enabled'
                                    id='quick_claim_enabled'
                                    checked={quickClaimEnabled}
                                    onChange={(event) => setQuickClaimEnabled(event.target.checked)}
                                />}
                            label='Pikalunastus' />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='extra_lottery_enabled'
                                    id='extra_lottery_enabled'
                                    checked={extraLotteryEnabled}
                                    onChange={(event) => setExtraLotteryEnabled(event.target.checked)}
                                />}
                            label='Lisäarvonta' />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='hidden'
                                    id='hidden'
                                    checked={hidden}
                                    onChange={(event) => setHidden(event.target.checked)}
                                />}
                            label='Piilotettu' />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='show_balance'
                                    id='show_balance'
                                    checked={showBalance}
                                    onChange={(event) => setShowBalance(event.target.checked)}
                                />}
                            label='Näytä voittosaldo arpajaisen sivulla' />
                    </FormGroup>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' type='submit' form='saveLottery'>
                    {lottery ? 'Päivitä' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

LotteryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default LotteryDialog;
