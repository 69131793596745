import { useEffect } from 'react';
import * as Cookies from 'js-cookie';
import { userManager } from './App';

const Callback = ({ history, children }) => {
    useEffect(() => {
        userManager.signinRedirectCallback()
            .then((user) => {
                Cookies.set('profile', user.profile);
                Cookies.set('id_token', user.id_token);
                Cookies.set('access_token', user.access_token);
                history.push('/');
            })
            .catch((error) => {
                console.error(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children || null;
};

export default Callback;
