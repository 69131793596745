import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Table, LoadingBar } from '../common';
import { getLangValue, includesValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import CategoryDialog from './CategoryDialog';
import config from '../config';

import EditIcon from '@material-ui/icons/Edit';

const Categories = () => {
    const [{ data, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/tags`);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [search, setSearch] = useState('');
    const mappedData = data ? data.items.map((item) => {
        return {
            id: item.id,
            nameFi: getLangValue(item.names, 'fi'),
            nameSv: getLangValue(item.names, 'sv'),
            hidden: item.hidden
        };
    }).filter((item) => includesValue(item, search)) : [];
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Kategoriat
                    </Typography>
                    <TextField label='Hae' value={search} onChange={(event) => setSearch(event.target.value)} style={{ marginRight: '1em' }} />
                    <Button variant='contained' color='primary' onClick={() => {
                        setSelectedCategory(null);
                        setCategoryDialogOpen(true);
                    }}>Lisää kategoria</Button>
                </Toolbar>
                <Table
                    idKey='id'
                    data={mappedData}
                    columns={[
                        { label: 'Suomenkielinen nimi', key: 'nameFi' },
                        { label: 'Ruotsinkielinen nimi', key: 'nameSv' },
                        { label: 'Piilotettu', key: 'hidden', callback: (value) => value ? 'Kyllä' : 'Ei' }
                    ]}
                    rowActions={(item) => {
                        return (
                            <>
                                <Tooltip title='Muokkaa'>
                                    <IconButton onClick={() => {
                                        setSelectedCategory(item);
                                        setCategoryDialogOpen(true);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }}
                />
            </Container>
            <CategoryDialog
                title={selectedCategory ? 'Muokkaa kategoriaa' : 'Lisää kategoria'}
                category={selectedCategory}
                open={categoryDialogOpen}
                onSave={() => {
                    setCategoryDialogOpen(false);
                    refresh();
                }}
                onClose={() => setCategoryDialogOpen(false)}
            />
        </>
    );
};

export default Categories;
