import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PrizeDialog from './PrizeDialog';
import { ConfirmDialog, LoadingBar, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import HelpPanel from '../common/HelpPanel';

const Prizes = ({ location: { search } }) => {
    const values = queryString.parse(search);
    const [isLoading, setIsLoading] = useState(true);
    const [{ data: products }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/products`);
    const [serieses, setSerieses] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedPrize, setSelectedPrize] = useState(null);
    const [prizeDialogOpen, setPrizeDialogOpen] = useState(false);
    const [deletePrizeDialogOpen, setDeletePrizeDialogOpen] = useState(false);
    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = () => {
        setIsLoading(true);
        fetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/ticket-series`)
            .then((response) => {
                return response ? response.json() : [];
            })
            .then((tickerSerieses) => {
                const ticketSeriesRequests = [];
                for (const ticketSeries of tickerSerieses) {
                    // Still uploading
                    if (!ticketSeries.id) {
                        continue;
                    }
                    const seriesProductsRequest = fetch(`${config.backendAddress}/api/v1/ticket-series/${ticketSeries.id}/products`)
                        .then((response) => {
                            return response ? response.json() : {};
                        });
                    const seriesPrizesRequest = fetch(`${config.backendAddress}/api/v1/ticket-series/${ticketSeries.id}/prizes`)
                        .then((response) => {
                            return response ? response.json() : {};
                        });
                    ticketSeriesRequests.push(
                        Promise.all([seriesProductsRequest, seriesPrizesRequest])
                            .then(([seriesProducts, seriesPrizes]) => {
                                return {
                                    ...ticketSeries,
                                    prizes: seriesPrizes.map((prizeId) => {
                                        const product = seriesProducts.find((product) => product.prize_id === prizeId);
                                        if (product) {
                                            return product;
                                        } else {
                                            return {
                                                id: null,
                                                names: [],
                                                descriptions: [],
                                                value: null,
                                                updated_at: null,
                                                prize_id: prizeId,
                                                claimable: true,
                                                value_transferrable: true
                                            };
                                        }
                                    })
                                };
                            })
                    );
                }
                Promise.all(ticketSeriesRequests)
                    .then((responses) => {
                        setSerieses(responses);
                        setIsLoading(false);
                    });
            });
    };

    return (
        <>
            <LoadingBar in={isLoading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Voittokategoriat
                    </Typography>
                </Toolbar>

                <HelpPanel>
                    <i>Lunastettava</i>- ja <i>Arvo saldoon</i> -kenttien käyttö:
                    <ul>
                        <li><b>Lunastettava ja arvo saldoon</b>: voitto, joka lisätään kirjautuneen asiakkaan tilille</li>
                        <li><b>Lunastettava ja ei arvoa saldoon</b>: voitto, josta tehdään tilaus asiakkaalle; käytännössä lehtivoitto tai vastaava</li>
                        <li><b>Ei lunastettava ja ei arvoa saldoon</b>: arpajaisen iso voitto</li>
                        <li><b>Ei lunastettava ja arvo saldoon</b>: ei käytettävissä</li>
                    </ul>
                    <i>Pelattavan arvan lisävoitto</i> -kenttä vaikuttaa vain pelattaviin arpoihin. Kun käytössä, voitto siirretään
                    asiakkaan lisävoittotilille, muutoin siirretään tavalliselle voittotilille; tällöin myös lunastettava ja arvo saldoon
                    oltava valittuina.
                </HelpPanel>

                {serieses && (serieses.length ? (
                    <>
                        {serieses.map((series) => (
                            <div key={series.id}>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant='h5'
                                        noWrap
                                        style={{ flexGrow: 1 }}
                                    >
                                        {series.alias}
                                    </Typography>
                                </Toolbar>
                                <Paper style={{ overflowX: 'auto' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Tuote</TableCell>
                                                <TableCell>Arvo</TableCell>
                                                <TableCell>Lunastettava</TableCell>
                                                <TableCell>Arvo saldoon</TableCell>
                                                <TableCell>Pelattavan arvan lisävoitto</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {series.prizes.map((prize) => (
                                                <TableRow key={prize.prize_id}>
                                                    <TableCell>{prize.prize_id}</TableCell>
                                                    <TableCell>{getLangValue(prize.names, 'fi')}</TableCell>
                                                    <TableCell>{prize.value ? formatCurrency(prize.value) + ' €' : ''}</TableCell>
                                                    <TableCell>{prize.id ? prize.claimable ? 'Kyllä' : 'Ei' : ''}</TableCell>
                                                    <TableCell>{prize.id ? prize.value_transferrable ? 'Kyllä' : 'Ei' : ''}</TableCell>
                                                    <TableCell>{prize.id ? prize.playable_extra_win ? 'Kyllä' : 'Ei' : ''}</TableCell>
                                                    <TableCell align='right' style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}>
                                                        <Tooltip title='Muokkaa'>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setSelectedSeries(series);
                                                                    setSelectedPrize(prize);
                                                                    setPrizeDialogOpen(true);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title='Poista'>
                                                            <div style={{ display: 'inline-block' }}>
                                                                <IconButton
                                                                    disabled={!prize.id}
                                                                    onClick={() => {
                                                                        setSelectedSeries(series);
                                                                        setSelectedPrize(prize);
                                                                        setDeletePrizeDialogOpen(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </div>
                        ))}
                    </>
                ) : (
                    <Typography variant='body1'>
                        Voittokategorioita ei löytynyt. Lisää ensin arpasarjoja.
                    </Typography>
                ))}
            </Container>
            <PrizeDialog
                open={prizeDialogOpen}
                seriesId={selectedSeries ? selectedSeries.id : null}
                prize={selectedPrize}
                products={products || []}
                onClose={() => setPrizeDialogOpen(false)}
                onSave={() => {
                    setPrizeDialogOpen(false);
                    refresh();
                }}
            />
            <ConfirmDialog
                open={deletePrizeDialogOpen}
                title='Poista voitto'
                text={`Haluatko varmasti poistaa voiton "${selectedPrize ? getLangValue(selectedPrize.names, 'fi') : ''}"?`}
                confirmText='Poista'
                onCancel={() => setDeletePrizeDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/ticket-series/${selectedSeries.id}/products/${selectedPrize.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            setDeletePrizeDialogOpen(false);
                            showMessage('Voitto poistettu');
                            refresh();
                        });
                }}
            />
        </>
    );
};

export default withRouter(Prizes);
