import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Cookies from 'js-cookie';
import { fetchWrap as fetch, getLangValue } from './common/functions';
import { useFetch } from './common/hooks';
import { UserManager } from 'oidc-client';
import config from './config';
import versions from './versions';

import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import EditIcon from '@material-ui/icons/Edit';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PrintIcon from '@material-ui/icons/Print';
import BackupIcon from '@material-ui/icons/Backup';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ClassIcon from '@material-ui/icons/Class';
import GradeIcon from '@material-ui/icons/Grade';
import TrophyIcon from './common/icons/Trophy';
import BarChartIcon from '@material-ui/icons/BarChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import PersonIcon from '@material-ui/icons/Person';

import Lotteries from './lotteries';
import Record from './record';
import Import from './import';
import Print from './print';
import Backup from './backup';
import Orders from './orders';
import Users from './users';
import Categories from './categories';
import Products from './products';
import LotteryProducts from './lotteryProducts';
import Series from './series';
import Prizes from './prizes';
import Redeems from './redeems';
import ExtraLotteries from './extralotteries';
import Reports from './reports';
import Statistics from './statistics';
import Tools from './tools';

const drawerWidth = 240;

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1 // Set above temporary and permanent drawer
    },
    drawer: {
        width: drawerWidth
    },
    main: {
        position: 'relative',
        flex: 1,
        overflow: 'auto',
        marginLeft: drawerWidth,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        }
    },
    version: {
        position: 'fixed',
        bottom: '0.1em',
        right: '0.5em'
    }
});

const lotteryRoutes = [
    { name: 'Rekisteröi arpa', path: '/rekisteroi/', icon: <EditIcon />, component: Record },
    { name: 'Tuo', path: '/tuo/', icon: <ImportExportIcon />, component: Import },
    { name: 'Tulosta', path: '/tulosta/', icon: <PrintIcon />, component: Print },
    { name: 'Tuotteet', path: '/tuotteet/', icon: <ListAltIcon />, component: LotteryProducts },
    { name: 'Arpasarjat', path: '/arpasarjat/', icon: <ClassIcon />, component: Series },
    { name: 'Voittokategoriat', path: '/voittokategoriat/', icon: <TrophyIcon />, component: Prizes },
    { name: 'Rekisteröidyt arvat', path: '/rekisteroidyt-arvat/', icon: <ViewListIcon />, component: Redeems },
    { name: 'Lisäarvonnat', path: '/lisaarvonnat/', icon: <GradeIcon />, component: ExtraLotteries },
    { name: 'Raportit', path: '/raportit/', icon: <ViewListIcon />, component: Reports },
    { name: 'Statistiikka', path: '/statistiikka/', icon: <BarChartIcon />, component: Statistics },
    { name: 'Varmuuskopio', path: '/varmuuskopio/', icon: <BackupIcon />, component: Backup }
];

const userManager = new UserManager(config.oauth2_settings);
userManager.events.addAccessTokenExpiring(() => {
    userManager.signinSilent()
        .then((user) => {
            Cookies.set('profile', user.profile);
            Cookies.set('id_token', user.id_token);
            Cookies.set('access_token', user.access_token);
        })
        .catch((error) => {
            console.error('Failed to refresh token. ' + error);
        });
});

const LotteryDrawerContent = ({ history, search, isActiveRoute }) => (
    <>
        <Toolbar />
        <List>
            <ListItem button onClick={() => {
                history.push('/arvat/');
            }}>
                <ListItemIcon>
                    <KeyboardArrowLeftIcon />
                </ListItemIcon>
                <ListItemText primary='Palaa arpoihin' />
            </ListItem>
            {lotteryRoutes.map((route) => (
                <div key={route.path}>
                    <Divider />
                    <ListItem button component={React.forwardRef((itemProps, ref) => <NavLink to={`${route.path}${search}`} {...itemProps} innerRef={ref} />)} selected={isActiveRoute(route.path)}>
                        <ListItemIcon>
                            {route.icon}
                        </ListItemIcon>
                        <ListItemText primary={route.name} />
                    </ListItem>
                </div>
            ))}
        </List>
    </>
);

const App = ({ classes, history, location: { search, pathname } }) => {
    const [ready, setReady] = useState(false);
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [title, setTitle] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const values = queryString.parse(search);
    const [{ data: lotteries }, setUrl] = useFetch(null);
    useEffect(() => {
        if (!Cookies.get('id_token')) {
            userManager.signinRedirect();
        } else {
            if (Cookies.get('profile')) {
                setUser(JSON.parse(Cookies.get('profile')));
            }
            fetch(`${config.backendAddress}/api/v1/users/self`)
                .then((response) => response.json())
                .then((json) => {
                    setRoles(json.roles);
                    if (json.roles.includes('admin')) {
                        setUrl(`${config.backendAddress}/api/v1/lotteries/`);
                    }
                    setReady(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (lotteries && values.lottery) {
            const lottery = lotteries.find((item) => item.id.toString() === values.lottery);
            if (lottery) {
                setTitle(`${getLangValue(lottery.names, 'fi')} (ID:${lottery.id})`);
            } else {
                setTitle('SBH arpahallinta');
            }
        } else {
            setTitle('SBH arpahallinta');
        }
    }, [values.lottery, lotteries]);

    const isActiveRoute = (route) => {
        return pathname === route;
    };

    if (!ready) {
        return null;
    }
    if (roles.length === 0) {
        return (
            <Container>
                <Typography component='h1' variant='h6'>
                    Sinulla ei ole oikeuksia käyttää tätä sivua.
                </Typography>
                <Button variant='contained' color='primary' onClick={() => {
                    Cookies.remove('profile', { path: window.location.origin });
                    Cookies.remove('id_token', { path: window.location.origin });
                    Cookies.remove('access_token', { path: window.location.origin });
                    userManager.signoutRedirect();
                }} style={{ marginTop: '1em' }}>Kirjaudu ulos</Button>
            </Container>
        );
    }
    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position='static'>
                <Toolbar>
                    {values.lottery && (
                        <Hidden lgUp>
                            <IconButton onClick={() => setDrawerOpen(!drawerOpen)} color='inherit'>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    )}
                    <Typography
                        component='h1'
                        variant='h6'
                        color='inherit'
                        noWrap
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    <Hidden mdDown>
                        {roles.includes('admin') && (
                            <>
                                <Button
                                    color='inherit'
                                    component={React.forwardRef((itemProps, ref) => <NavLink to='/arvat/' {...itemProps} innerRef={ref} />)}
                                    style={{ marginRight: '2em' }}
                                >
                                    Arvat
                                </Button>
                                <Button
                                    color='inherit'
                                    component={React.forwardRef((itemProps, ref) => <NavLink to='/tuotteet/' {...itemProps} innerRef={ref} />)}
                                    style={{ marginRight: '2em' }}
                                >
                                    Tuotteet
                                </Button>
                                <Button
                                    color='inherit'
                                    component={React.forwardRef((itemProps, ref) => <NavLink to='/kategoriat/' {...itemProps} innerRef={ref} />)}
                                    style={{ marginRight: '2em' }}
                                >
                                    Kategoriat
                                </Button>
                                <Button
                                    color='inherit'
                                    component={React.forwardRef((itemProps, ref) => <NavLink to='/tilaukset/' {...itemProps} innerRef={ref} />)}
                                    style={{ marginRight: '2em' }}
                                >
                                    Tilaukset
                                </Button>
                                <Button
                                    color='inherit'
                                    component={React.forwardRef((itemProps, ref) => <NavLink to='/tyokalut/' {...itemProps} innerRef={ref} />)}
                                    style={{ marginRight: '2em' }}
                                >
                                    Työkalut
                                </Button>
                            </>
                        )}
                        <Button
                            color='inherit'
                            component={React.forwardRef((itemProps, ref) => <NavLink to='/kayttajat/' {...itemProps} innerRef={ref} />)}
                            style={{ marginRight: '2em' }}
                        >
                            Käyttäjät
                        </Button>
                        <Button
                            color='inherit'
                            style={{ marginRight: '2em' }}
                            onClick={() => {
                                Cookies.remove('profile', { path: window.location.origin });
                                Cookies.remove('id_token', { path: window.location.origin });
                                Cookies.remove('access_token', { path: window.location.origin });
                                userManager.signoutRedirect();
                            }}
                        >
                            Kirjaudu ulos
                        </Button>
                    </Hidden>
                    <Hidden lgUp>
                        <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)} color='inherit'>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                            {roles.includes('admin') && (
                                <MenuItem component={Link} to='/arvat/' onClick={() => setMenuAnchor(null)}>
                                    Arvat
                                </MenuItem>
                            )}
                            {roles.includes('admin') && (
                                <MenuItem component={Link} to='/tuotteet/' onClick={() => setMenuAnchor(null)}>
                                    Tuotteet
                                </MenuItem>
                            )}
                            {roles.includes('admin') && (
                                <MenuItem component={Link} to='/kategoriat/' onClick={() => setMenuAnchor(null)}>
                                    Kategoriat
                                </MenuItem>
                            )}
                            {roles.includes('admin') && (
                                <MenuItem component={Link} to='/tilaukset/' onClick={() => setMenuAnchor(null)}>
                                    Tilaukset
                                </MenuItem>
                            )}
                            {roles.includes('admin') && (
                                <MenuItem component={Link} to='/tyokalut/' onClick={() => setMenuAnchor(null)}>
                                    Tyokalut
                                </MenuItem>
                            )}
                            <MenuItem component={Link} to='/kayttajat/' onClick={() => setMenuAnchor(null)}>
                                Käyttäjät
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setMenuAnchor(null);
                                Cookies.remove('profile', { path: window.location.origin });
                                Cookies.remove('id_token', { path: window.location.origin });
                                Cookies.remove('access_token', { path: window.location.origin });
                                userManager.signoutRedirect();
                            }}>
                                Kirjaudu ulos
                            </MenuItem>
                        </Menu>
                    </Hidden>
                    <Typography variant='body1' style={{ marginRight: '0.5em' }}>
                        {user.first_name} {user.last_name}
                    </Typography>
                    <PersonIcon />
                </Toolbar>
            </AppBar>
            {!values.lottery &&
                <main className={classes.main} style={{ marginLeft: 0 }}>
                    <Switch>
                        {roles.includes('admin') ? (
                            <>
                                <Route path={['/', '/arvat/']} exact render={(props) => (
                                    <Lotteries {...props} onSelectLottery={(lottery) => {
                                        history.push({ search: '?lottery=' + lottery.id });
                                    }} />
                                )} />
                                <Route path='/tuotteet' render={(props) => (
                                    <Products {...props} />
                                )} />
                                <Route path='/kategoriat' render={(props) => (
                                    <Categories {...props} />
                                )} />
                                <Route path='/tilaukset' render={(props) => (
                                    <Orders {...props} />
                                )} />
                                <Route path='/kayttajat' render={(props) => (
                                    <Users {...props} />
                                )} />
                                <Route path='/tyokalut' render={(props) => (
                                    <Tools {...props} classes={classes} history={history} />
                                )} />
                            </>
                        ) : (
                            <>
                                <Route path={['/', '/kayttajat']} render={(props) => (
                                    <Users {...props} />
                                )} />
                            </>
                        )}
                    </Switch>
                </main>
            }
            {values.lottery && (
                <>
                    {/* Desktop */}
                    <Hidden mdDown>
                        <Drawer
                            open
                            classes={{ paper: classes.drawer }}
                            variant='permanent'
                        >
                            <LotteryDrawerContent history={history} search={search} isActiveRoute={isActiveRoute} />
                        </Drawer>
                    </Hidden>
                    {/* Mobile */}
                    <Hidden lgUp>
                        <Drawer
                            open={drawerOpen}
                            classes={{ paper: classes.drawer }}
                            variant='temporary'
                            onClose={() => setDrawerOpen(false)}
                            ModalProps={{
                                keepMounted: true // Better open performance on mobile.
                            }}
                        >
                            <LotteryDrawerContent history={history} search={search} isActiveRoute={isActiveRoute} />
                        </Drawer>
                    </Hidden>
                    <main className={classes.main}>
                        <Switch>
                            {lotteryRoutes.map((route) => <Route key={route.path} path={route.path} component={route.component} />)}
                        </Switch>
                    </main>
                </>
            )}
            <div className={classes.version}>{versions.ui}</div>
        </div>
    );
};

export { userManager };

export default withRouter(withStyles(styles)(App));
