import React, { useState } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingBar } from '../common';
import { fetchWrap as fetch, getLangValue, formatCurrency } from '../common/functions';
import config from '../config';

import User from '../users/User';

const claimSources = {
    'secury_cast': 'SecuryCast',
    'admin': 'Admin',
    'user': 'Arpamaailma',
    'all': 'Kaikki'
};

const ClaimableResults = ({ data, loading, onReturn }) => {
    const [user, setUser] = useState(null);

    if (user) {
        return (
            <User
                user={user}
                onReturn={() => setUser(null)}
            />
        );
    }

    const redeems = data ? data.items.map((item, index) => {
        const claim = item.claim;
        let product = '';
        if (claim.product_id) {
            if (claim.account_event) {
                product = `${formatCurrency(claim.account_event.value)} €`;
            } else {
                product = `${item.prize_id}. ` +
                    `${getLangValue(claim.product_names, 'fi')} ` +
                    `(${formatCurrency(claim.product_value)} €) ` +
                    `${claim.product_option_names ? ` (${getLangValue(claim.product_option_names, 'fi')})` : ''}`;
            }
        } else {
            product = 'Lisäarvonta';
        }
        return {
            id: claim.id,
            code_a: item.code_a,
            user_id: claim.user_id,
            order_id: claim.order_id,
            product: product,
            source_type: claim.source_type,
            created_at: claim.created_at,
            commited_at: claim.commited_at,
            state: claim.claimed ? 'lunastettu': 'odottaa',
        };
    }) : [];

    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Lunastettavat arvat
                    </Typography>
                    <Button variant='contained' color='primary' onClick={onReturn}>Haku</Button>
                </Toolbar>
                <Paper style={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Arpanumero</TableCell>
                                <TableCell>Voitto</TableCell>
                                <TableCell>Tilausnumero</TableCell>
                                <TableCell>Lähde</TableCell>
                                <TableCell>Tila</TableCell>
                                <TableCell>Aika (luotu)</TableCell>
                                <TableCell>Aika (lunastettu)</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {redeems.map((redeem) => (
                                <TableRow key={redeem.id}>
                                    <TableCell>{redeem.code_a}</TableCell>
                                    <TableCell>{redeem.product}</TableCell>
                                    <TableCell>{redeem.order_id}</TableCell>
                                    <TableCell>{claimSources[redeem.source_type]}</TableCell>
                                    <TableCell>{redeem.state}</TableCell>
                                    <TableCell>{redeem.created_at ? moment.utc(redeem.created_at).local().format('DD.MM.YYYY HH:mm') : ''}</TableCell>
                                    <TableCell>{redeem.commited_at ? moment.utc(redeem.commited_at).local().format('DD.MM.YYYY HH:mm') : ''}</TableCell>
                                    <TableCell align='right'>
                                        <Button variant='contained' color='primary' onClick={() => {
                                            fetch(`${config.backendAddress}/api/v1/users/${redeem.user_id}?service=all`, {
                                                method: 'GET'
                                            })
                                                .then((response) => response.json())
                                                .then((json) => {
                                                    setUser(json);
                                                })
                                                .catch((error) => {
                                                    console.error(error);
                                                });
                                        }}>Lunastaja</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
        </>
    );
};

export default ClaimableResults;
