import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ProductOptionDialog from './ProductOptionDialog';
import { ConfirmDialog, showMessage } from '../common';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import { useFetch } from '../common/hooks';
import config from '../config';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const ProductOptionsDialog = ({ open, product, title, onClose }) => {
    const [{ data, refresh }, setUrl] = useFetch(null);
    const [optionDialogOpen, setOptionDialogOpen] = useState(false);
    const [deleteOptionDialogOpen, setDeleteOptionDialogOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        if (open) {
            if (product) {
                setUrl(`${config.backendAddress}/api/v1/products/${product.id}/options`);
            }
        }
    }, [open, product, setUrl]);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Toolbar disableGutters>
                        <span style={{ flex: 1 }} />
                        <Button variant='contained' color='primary' onClick={() =>  {
                            setSelectedOption(null);
                            setOptionDialogOpen(true);
                        }}>
                            Lisää optio
                        </Button>
                    </Toolbar>
                    <Paper style={{ height: 300 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nimi</TableCell>
                                    <TableCell>Custom ID</TableCell>
                                    <TableCell>Oletus</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{getLangValue(item.names, 'fi')}</TableCell>
                                        <TableCell>{item.custom_id}</TableCell>
                                        <TableCell>{item.default ? '*' : ''}</TableCell>
                                        <TableCell align='right' style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}>
                                            <Tooltip title='Muokkaa'>
                                                <IconButton onClick={() => {
                                                    setSelectedOption(item);
                                                    setOptionDialogOpen(true);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Poista'>
                                                <IconButton onClick={() => {
                                                    setSelectedOption(item);
                                                    setDeleteOptionDialogOpen(true);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={onClose}>
                        Sulje
                    </Button>
                </DialogActions>
            </Dialog>
            <ProductOptionDialog
                open={optionDialogOpen}
                product={product}
                option={selectedOption}
                onClose={() => setOptionDialogOpen(false)}
                onSave={() => {
                    setOptionDialogOpen(false);
                    refresh();
                }}
            />
            <ConfirmDialog
                open={deleteOptionDialogOpen}
                title='Poista valinta'
                text={`Haluatko varmasti poistaa valinnan "${selectedOption ? getLangValue(selectedOption.names, 'fi'): ''}"?`}
                confirmText='Poista'
                onCancel={() => setDeleteOptionDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/product-options/${selectedOption.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            showMessage('Valinta poistettu');
                            setDeleteOptionDialogOpen(false);
                            refresh();
                        });
                }}
            />
        </>
    );
};

ProductOptionsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    product: PropTypes.object,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ProductOptionsDialog;
