import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { spacing } from '@material-ui/system';
import { LoadingBar, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

let interval;

const Import = ({ location }) => {
    const [{ data: latestAccountExport, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/unelma-arpa/users/saldo?only_check=true`);

    useEffect(() => {
        interval = setInterval(() => refresh(), 10000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const importWinners = (event) => {
        if (event.target.files.length === 0) {
            return;
        }
        const body = new FormData();
        body.append('claims', event.target.files[0]);
        const values = queryString.parse(location.search);
        fetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/claims/import`, {
            method: 'POST',
            body: body
        })
            .then((response) => {
                showMessage('Voittajat lisätty');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const importClaims = (event) => {
        const values = queryString.parse(location.search);
        fetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/claims/import`, {
            method: 'POST'
        })
            .then((response) => {
                showMessage('Arpojen hakua aloitettu');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const triggerUAAccounts = (event) => {
        fetch(`${config.backendAddress}/api/v1/unelma-arpa/users/saldo`, {
            method: 'POST'
        })
            .then((response) => {
                showMessage('Asiakkaiden saldojen vienti aloitettu');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const downloadUAAccounts = (event) => {
        const values = queryString.parse(location.search);
        fetch(`${config.backendAddress}/api/v1/unelma-arpa/users/saldo`, {
            method: 'GET'
        })
            .then((response) => {
                return response.blob()
                    .then((blob) => {
                        return {
                            blob: blob,
                            content_disposition: response.headers.get('content-disposition')
                        };
                    });
            })
            .then(({ blob, content_disposition }) => {
                const filename = content_disposition ?
                    content_disposition.split(';')[1].split('=')[1]:
                    `uua_saldot_${moment().format()}.csv`;

                const newBlob = new Blob([blob], { type: 'text/csv' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                const data = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = filename;
                link.click();
                setTimeout(function(){
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <LoadingBar in={false} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        component='h2'
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tuo / Vie
                    </Typography>
                </Toolbar>
                <Toolbar disableGutters>
                    <input
                        type='file'
                        accept='.csv'
                        style={{ display: 'none' }}
                        id='winner-import'
                        onChange={importWinners}
                    />
                    <label htmlFor='winner-import'>
                        <Button variant='contained' color='primary' style={{ marginRight: '1em' }}>
                            Tuo voittajia CSV-tiedostosta
                        </Button>
                    </label>
                    <Button variant='contained' color='primary' onClick={importClaims} style={{ marginRight: '1em' }}>
                        Tuo sähköiset arvat
                    </Button>
                </Toolbar>
                <Toolbar disableGutters>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={triggerUAAccounts}
                        style={{ marginRight: '1em' }}
                    >
                        Vie asiakkaiden saldot
                    </Button>
                    &nbsp;
                    <Link
                        variant='body2'
                        href='#'
                        onClick={downloadUAAccounts}
                    >
                        {latestAccountExport && latestAccountExport['created_timestamp'] ?
                            `Lataa viimeisin: ${moment.utc(latestAccountExport['created_timestamp']).local().format('DD.MM.YYYY HH:mm')}` :
                            ''
                        }
                    </Link>
                </Toolbar>
            </Container>
        </>
    );
};

export default Import;
